import { Box, InputBase } from "@mui/material";
import { KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";

interface TextEditorProps {
  value: string;
  onSave?: () => void;
  onChange?: (value: string) => void;
}

export default function TextEditor(props: TextEditorProps) {
  const { t } = useTranslation("TextEditor");

  const handleKeyDown = (
    event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.ctrlKey && event.key === "s") {
      event.preventDefault();
      props.onSave?.();
    }
  };

  return (
    <Box height={1}>
      {/* <Box
        contentEditable
        p={1}
        bgcolor="background.paper"
        sx={{
          borderRadius: (theme) => theme.shape.borderRadius / 8,
        }}
      >
        {props.value}
      </Box> */}
      <InputBase
        autoComplete="off"
        value={props.value}
        inputMode="text"
        multiline
        placeholder={t("TypeHere")}
        fullWidth
        sx={{
          height: 1,
          overflow: "auto",
          alignItems: "flex-start",
          bgcolor: "background.paper",
          borderRadius: (theme) => theme.shape.borderRadius / 8,
          p: 1,
        }}
        onKeyDown={handleKeyDown}
        onChange={(e) => props.onChange?.(e.target.value)}
      />
    </Box>
  );
}
