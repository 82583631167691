import { Box, Button, Stack, TableCell } from "@mui/material";
import { useState } from "react";
import { Department, PaginatedListDepartment } from "../../../services/swagger";
import { DepartmentService } from "../../../services/api";
import useRefresh from "../../../hooks/useRefresh";
import useConfirm from "../../../hooks/useConfirm";
import { useNavigate } from "react-router";
import CreateDepartmentDialog from "../../organisms/CreateDepartmentDialog";
import { useTranslation } from "react-i18next";
import DataView, { QueryParameters } from "../../organisms/DataView";
import ItemCard from "../../organisms/ItemCard";

export default function DepartmentsPage() {
  const { t } = useTranslation("Department");
  const refresh = useRefresh();
  const navigate = useNavigate();
  const [createDialog, setCreateDialog] = useState(false);
  const [departments, setDepartments] = useState<PaginatedListDepartment>({
    count: 0,
    docs: [],
  });

  const loadDepartments = async (query: QueryParameters) => {
    const res = await DepartmentService.getDepartmentList({
      search: query.search,
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortDirection: query.sortDirection,
      sortField: query.sortField,
    });
    if (res?.data?.data) setDepartments(res?.data?.data);
  };

  const navigateToDepartment = (department: Department) => {
    navigate(`/m/department/${department._id}`);
  };

  const handleDelete = async (department: Department) => {
    await DepartmentService.deleteDepartment({
      id: department._id?.toString() || "",
    });
    refresh();
  };

  const handleDataTableChange = (query: QueryParameters) => {
    loadDepartments(query);
  };

  const { confirm: confirmDelete, ConfirmDialog } = useConfirm();

  return (
    <Box height={1}>
      <ConfirmDialog />
      <CreateDepartmentDialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
      />
      <DataView
        name={t("Departments")}
        headers={[
          { field: "name", name: t("Name"), type: "string" },
          {
            field: "administrative",
            name: t("Administrative"),
            type: "boolean",
          },
          {
            field: "delete",
            name: "",
            type: "other",
            renderHeaderCell: () => (
              <TableCell align="center" width={150}>
                {t("Action")}
              </TableCell>
            ),
            renderRowCell: (row: Department) => (
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => navigateToDepartment(row)}
                  >
                    {t("Edit")}
                  </Button>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => confirmDelete(() => handleDelete(row))}
                  >
                    {t("Delete")}
                  </Button>
                </Stack>
              </TableCell>
            ),
          },
        ]}
        onChange={handleDataTableChange}
        items={departments.docs}
        count={departments.count}
        renderItem={(department) => (
          <ItemCard
            department={department}
            onClick={() => navigateToDepartment(department)}
          />
        )}
        pagination
        queryFunction={DepartmentService.getDepartmentListQuery}
        button={{
          text: t("NewDepartment"),
          onClick: () => setCreateDialog(true),
        }}
      />
    </Box>
  );
}
