import { Button, ButtonProps, Tooltip } from "@mui/material";
import { useState } from "react";
import { FtpService } from "../../services/api";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadDialog from "./UploadDialog";
import { useTranslation } from "react-i18next";
import useBreakpoint from "../../hooks/useBreakpoint";

interface UploadDialogProps extends ButtonProps {
  path: string;
  onSuccess?: () => any;
}

export default function UploadButton(props: UploadDialogProps) {
  const { t } = useTranslation("UploadButton");
  const [open, setOpen] = useState(false);
  const breakpoint = useBreakpoint();

  const handleUpload = async (files: File[]) => {
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }
    const res = await FtpService.createFileUpload(
      {
        path: props.path,
      },
      {
        data: formData,
      }
    );
    if (res?.data?.success) {
      props.onSuccess?.();
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <UploadDialog
        open={open}
        onCancel={handleClose}
        onSubmit={handleUpload}
      />
      {breakpoint.md ? (
        <Button
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          onClick={handleOpen}
        >
          {t("Upload")}
        </Button>
      ) : (
        <Tooltip title={t("Upload")}>
          <Button variant="outlined" onClick={handleOpen}>
            <CloudUploadIcon />
          </Button>
        </Tooltip>
      )}
    </>
  );
}
