import { Box, MenuItem, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationInstanceNotification } from "../../services/swagger";

interface NotificationInfoProps {
  notification: NotificationInstanceNotification;
  date: number;
}

export default function NotificationInfo(props: NotificationInfoProps) {
  const { notification, date } = props;
  return (
    <MenuItem sx={{ boxSizing: "border-box" }}>
      <Box display="flex" width={1} sx={{ overflowX: "hidden" }}>
        <NotificationsIcon sx={{ pr: 1, pt: 1 }} />
        <Box flexGrow={1}>
          <Typography
            fontWeight="bold"
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflowWrap: "break-word",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
            }}
          >
            {notification.title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflowWrap: "break-word",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
            }}
            width={1}
          >
            {notification.body}
          </Typography>
          <Typography
            variant="subtitle2"
            align="right"
            mt={1}
            color="text.secondary"
            sx={{ textWrap: "pretty" }}
          >
            {new Date(date).toLocaleString("tr")}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
}
