import { useEffect, useState } from "react";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { Stack } from "@mui/material";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";
import { useTranslation } from "react-i18next";

export default function FtpSettingsPage() {
  const { t } = useTranslation("Settings");
  const { context } = useGlobalContext();
  const refresh = useRefresh();
  const [enabled, setEnabled] = useState(false);
  const [host, setHost] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [uploadPath, setUploadPath] = useState("");
  const [port, setPort] = useState(21);
  const [secure, setSecure] = useState(false);

  const handleSubmit = async () => {
    if (!context.settings) return;
    await SettingsService.updateSettings({
      appSettings: {
        ...context.settings,
        ftp: {
          enabled,
          host,
          username,
          password,
          uploadPath,
          port,
          secure,
        },
      },
    });
    refresh();
  };

  useEffect(() => {
    if (!context.settings) return;
    setEnabled(context.settings.ftp.enabled);
    setHost(context.settings.ftp.host);
    setUsername(context.settings.ftp.username);
    setPassword(context.settings.ftp.password);
    setPort(context.settings.ftp.port);
    setSecure(context.settings.ftp.secure);
    setUploadPath(context.settings.ftp.uploadPath);
  }, [context.settings]);

  return (
    <FormTemplate
      title={t("FTP")}
      disableHeaderCloseButton
      disableCancelButton
      submitButtonText={t("Save")}
      disableEnter
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <FormInput
          field="enabled"
          label={t("Enabled")}
          type="boolean"
          value={enabled}
          onChange={(value) => setEnabled(value as boolean)}
        />
        <FormInput
          field="host"
          label={t("Host")}
          type="string"
          value={host}
          onChange={(value) => setHost(value as string)}
        />
        <FormInput
          field="username"
          label={t("Username")}
          type="string"
          value={username}
          onChange={(value) => setUsername(value as string)}
        />
        <FormInput
          field="password"
          label={t("Password")}
          type="string"
          inputType="password"
          value={password}
          onChange={(value) => setPassword(value as string)}
        />
        <FormInput
          field="uploadPath"
          label={t("UploadPath")}
          placeholder={t("UploadPathPlaceholder")}
          type="string"
          value={uploadPath}
          onChange={(value) => setUploadPath(value as string)}
        />
        <FormInput
          field="port"
          label={t("Port")}
          type="number"
          value={port}
          onChange={(value) => setPort(value as number)}
        />
        <FormInput
          field="secure"
          label={t("Secure")}
          type="boolean"
          value={secure}
          onChange={(value) => setSecure(value as boolean)}
        />
      </Stack>
    </FormTemplate>
  );
}
