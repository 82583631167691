export type ViewableMediaType = "image" | "video" | "audio";

export type ViewableFileType = "pdf" | "text" | ViewableMediaType;

export function getFileExtension(filePath: string) {
  return filePath.split(".").pop()?.toLowerCase();
}

export function getFileCategory(
  filePath: string
): ViewableFileType | undefined {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const videoExtensions = ["mp4"];
  const audioExtensions = ["mp3", "wav", "ogg"];
  const pdfExtensions = ["pdf"];
  const textExtensions = ["txt"];

  const fileExtension = getFileExtension(filePath);
  if (!fileExtension) return;
  if (pdfExtensions.includes(fileExtension)) return "pdf";
  else if (textExtensions.includes(fileExtension)) return "text";
  else if (imageExtensions.includes(fileExtension)) return "image";
  else if (videoExtensions.includes(fileExtension)) return "video";
  else if (audioExtensions.includes(fileExtension)) return "audio";
}
