import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  StepContent,
  Typography,
} from "@mui/material";
import FormInput from "../molecules/FormInput";
import Stepper, { Step } from "../molecules/Stepper";
import EmailIcon from "@mui/icons-material/Email";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SettingsIcon from "@mui/icons-material/Settings";
import { useEffect, useState } from "react";
import { SettingsService } from "../../services/api";
import {
  AppSettings,
  FTPSettings,
  OpenAISettings,
  SAPSettings,
  SMTPSettings,
} from "../../services/swagger";
import { useNavigate } from "react-router";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import StepperContent from "../atoms/StepperContent";
import EmailSetup from "../organisms/Setup/EmailSetup";
import FormTemplate from "../templates/FormTemplate";
import FtpSetup from "../organisms/Setup/FtpSetup";
import OpenAiSetup from "../organisms/Setup/OpenAiSetup";
import SapSetup from "../organisms/Setup/SapSetup";
import { useTranslation } from "react-i18next";

const allSteps: Step[] = [
  { value: "email", label: "SMTP", icon: <EmailIcon />, step: 1 },
  { value: "openAi", label: "OpenAI", icon: <AutoAwesomeIcon />, step: 2 },
  { value: "sap", label: "SAP B1", icon: <AssessmentIcon />, step: 3 },
  { value: "ftp", label: "FTP", icon: <FileOpenIcon />, step: 4 },
];

export default function SetupPage() {
  const { t } = useTranslation("SetupPage");
  const navigate = useNavigate();

  const [isSetupReady, setIsSetupReady] = useState(false);

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [emailService, setEmailService] = useState(false);
  const [openAiService, setOpenAiService] = useState(false);
  const [sapService, setSapService] = useState(false);
  const [ftpService, setFtpService] = useState(false);

  const [emailConfig, setEmailConfig] = useState<SMTPSettings>();
  const [openAiConfig, setOpenAiConfig] = useState<OpenAISettings>();
  const [sapConfig, setSapConfig] = useState<SAPSettings>();
  const [ftpConfig, setFtpConfig] = useState<FTPSettings>();

  const [isLoading, setIsLoading] = useState(false);
  const [qr, setQr] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<Step[]>([]);

  const checkSetup = async () => {
    const res = await SettingsService.getSetup();
    if (!res?.data) return navigate("/home");
    const setup = res.data.data;
    if (setup) navigate("/home");
    else setIsSetupReady(true);
  };

  useEffect(() => {
    checkSetup();
  }, []);

  useEffect(() => {
    if (isSetupReady) localStorage.removeItem("authToken");
  }, [isSetupReady]);

  useEffect(() => {
    const initialStep: Step = {
      value: "setup",
      icon: <SettingsIcon />,
      label: t("Setup"),
      step: 0,
    };
    const arr: Step[] = allSteps.filter((e, i) => {
      if (emailService && e.value === "email") return true;
      if (openAiService && e.value === "openAi") return true;
      if (sapService && e.value === "sap") return true;
      if (ftpService && e.value === "ftp") return true;
      return false;
    });

    const finalStep: Step = {
      value: "finish",
      icon: <DoneOutlineIcon />,
      label: t("Finish"),
      step: 5,
    };
    setSteps([initialStep, ...arr, finalStep]);
  }, [emailService, openAiService, sapService, ftpService]);

  const handleFinishSetup = async () => {
    if (qr) navigate("/");
    setIsLoading(true);
    const res = await SettingsService.createSettingsSetup({
      createSetupBody: {
        admin: { email, name, password, username },
        settings: {
          ftp: ftpConfig,
          openAi: openAiConfig,
          sap: sapConfig,
          smtp: emailConfig,
        },
      },
    });
    setIsLoading(false);

    if (res?.data?.success) {
      setQr(res.data?.data?.qr || "");
    }
  };

  const handleGoNext = () => {
    if (activeStep === steps.length - 1) return;
    setActiveStep(activeStep + 1);
  };

  const handleGoBack = () => {
    if (activeStep === 0) return;
    setActiveStep(activeStep - 1);
  };

  if (!isSetupReady) return null;

  return (
    <Box
      height={1}
      width={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="background.paper"
    >
      <Paper
        sx={{
          bgcolor: "background.default",
          p: 4,
          minWidth: 600,
        }}
      >
        <Stack spacing={2} width={1}>
          <Stepper activeStep={activeStep} steps={steps} />
          {steps.length > 0 && (
            <Box>
              <StepperContent step={0} activeStep={steps[activeStep].step}>
                <FormTemplate
                  title={t("Setup")}
                  cancelButtonText={t("Back")}
                  submitButtonText={t("Next")}
                  onSubmit={handleGoNext}
                  onCancel={handleGoBack}
                  disableHeaderCloseButton
                >
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={2}>
                      <FormInput
                        required
                        field="name"
                        label={t("FullName")}
                        type="string"
                        onChange={(value) => setName(value as string)}
                        value={name}
                      />
                      <FormInput
                        required
                        field="email"
                        label={t("Email")}
                        type="string"
                        inputType="email"
                        onChange={(value) => setEmail(value as string)}
                        value={email}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormInput
                        required
                        field="username"
                        label={t("Username")}
                        type="string"
                        onChange={(value) => setUsername(value as string)}
                        value={username}
                      />
                      <FormInput
                        required
                        field="password"
                        label={t("Password")}
                        type="string"
                        inputType="password"
                        onChange={(value) => setPassword(value as string)}
                        value={password}
                      />
                    </Stack>
                    <Typography fontWeight="bold" fontSize="large">
                      {t("Features")}
                    </Typography>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormInput
                          field="smtp"
                          label="SMTP"
                          type="switch"
                          onChange={(value) =>
                            setEmailService(value as boolean)
                          }
                          value={emailService}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormInput
                          field="openAi"
                          label="Open AI"
                          type="switch"
                          onChange={(value) =>
                            setOpenAiService(value as boolean)
                          }
                          value={openAiService}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormInput
                          field="sap"
                          label="SAP Business One"
                          type="switch"
                          onChange={(value) => setSapService(value as boolean)}
                          value={sapService}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormInput
                          field="ftp"
                          label="FTP"
                          type="switch"
                          onChange={(value) => setFtpService(value as boolean)}
                          value={ftpService}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </FormTemplate>
              </StepperContent>
              <StepperContent step={1} activeStep={steps[activeStep].step}>
                <EmailSetup
                  smtpSettings={emailConfig}
                  onCancel={handleGoBack}
                  onSubmit={(emailConfig) => {
                    setEmailConfig(emailConfig);
                    handleGoNext();
                  }}
                />
              </StepperContent>
              <StepperContent step={2} activeStep={steps[activeStep].step}>
                <OpenAiSetup
                  openAiSettings={openAiConfig}
                  onCancel={handleGoBack}
                  onSubmit={(openAiConfig) => {
                    setOpenAiConfig(openAiConfig);
                    handleGoNext();
                  }}
                />
              </StepperContent>
              <StepperContent step={3} activeStep={steps[activeStep].step}>
                <SapSetup
                  sapSettings={sapConfig}
                  onCancel={handleGoBack}
                  onSubmit={(sapConfig) => {
                    setSapConfig(sapConfig);
                    handleGoNext();
                  }}
                />
              </StepperContent>
              <StepperContent step={4} activeStep={steps[activeStep].step}>
                <FtpSetup
                  ftpSettings={ftpConfig}
                  onCancel={handleGoBack}
                  onSubmit={(ftpConfig) => {
                    setFtpConfig(ftpConfig);
                    handleGoNext();
                  }}
                />
              </StepperContent>
              <StepperContent step={5} activeStep={steps[activeStep].step}>
                <Alert>{t("Finalize")}</Alert>
                {qr && (
                  <>
                    <Typography pt={4}>{t("ScanQrGuide")}</Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      py={4}
                    >
                      <img src={qr} />
                    </Box>
                  </>
                )}
                <Stack
                  spacing={1}
                  mt={2}
                  sx={{ justifyContent: "flex-end" }}
                  direction="row"
                >
                  <Button
                    onClick={handleGoBack}
                    variant="outlined"
                    color="error"
                    disabled={Boolean(qr)}
                  >
                    {t("GoBack")}
                  </Button>
                  <Button
                    onClick={handleFinishSetup}
                    variant="contained"
                    color="success"
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="warning" />
                    ) : (
                      t("Finish")
                    )}
                  </Button>
                </Stack>
              </StepperContent>
            </Box>
          )}
        </Stack>
      </Paper>
    </Box>
  );
}
