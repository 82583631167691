import { Box, Button, TableCell } from "@mui/material";
import { useState } from "react";
import {
  FirewallRecord,
  PaginatedListFirewallRecord,
} from "../../services/swagger";
import { FirewallService } from "../../services/api";
import CreateRecordDialog from "../organisms/CreateRecordDialog";
import useRefresh from "../../hooks/useRefresh";
import useConfirm from "../../hooks/useConfirm";
import { useTranslation } from "react-i18next";
import DataView, { QueryParameters } from "../organisms/DataView";
import ItemCard from "../organisms/ItemCard";

export default function SecurityPage() {
  const { t } = useTranslation("SecurityPage");
  const refresh = useRefresh();
  const [createDialog, setCreateDialog] = useState(false);
  const [records, setRecords] = useState<PaginatedListFirewallRecord>({
    count: 0,
    docs: [],
  });

  const loadRecords = async (query: QueryParameters) => {
    const recordsRes = await FirewallService.getRecordList({
      search: query.search,
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortDirection: query.sortDirection,
      sortField: query.sortField,
    });
    if (recordsRes?.data?.data) setRecords(recordsRes?.data?.data);
  };

  const handleDelete = async (record: FirewallRecord) => {
    await FirewallService.deleteRecord({ id: record._id?.toString() || "" });
    refresh();
  };

  const handleDataTableChange = (query: QueryParameters) => {
    loadRecords(query);
  };

  const { confirm: confirmDelete, ConfirmDialog } = useConfirm();

  return (
    <Box height={1}>
      <ConfirmDialog />
      <CreateRecordDialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
      />
      <DataView
        name={t("FirewallRecords")}
        headers={[
          { field: "date", name: t("Date"), type: "date" },
          { field: "ip", name: t("IP"), type: "string" },
          { field: "subnet", name: t("Subnet"), type: "string" },
          { field: "type", name: t("Type"), type: "string" },
          {
            field: "delete",
            name: "",
            type: "other",
            renderHeaderCell: () => (
              <TableCell align="center" width={70}>
                {t("Action")}
              </TableCell>
            ),
            renderRowCell: (row: FirewallRecord) => (
              <TableCell>
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={() => confirmDelete(() => handleDelete(row))}
                >
                  {t("Delete")}
                </Button>
              </TableCell>
            ),
          },
        ]}
        onChange={handleDataTableChange}
        items={records.docs}
        count={records.count}
        renderItem={(record) => <ItemCard record={record} />}
        pagination
        queryFunction={FirewallService.getRecordListQuery}
        button={{
          text: t("NewRecord"),
          onClick: () => setCreateDialog(true),
        }}
      />
    </Box>
  );
}
