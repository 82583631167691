import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import AttachmentList from "../../molecules/AttachmentList";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useState } from "react";
import UploadDialog from "../UploadDialog";
import { useTranslation } from "react-i18next";

interface TicketAttachmentsProps {
  ticketId?: string;
  onChange: (files: File[]) => void;
}

export default function TicketAttachments(props: TicketAttachmentsProps) {
  const { t } = useTranslation("Ticket");
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const handleChangeFiles = (newFiles: File[]) => {
    setFiles(newFiles);
    setOpen(false);
  };

  useEffect(() => {
    props.onChange(files);
  }, [files]);

  return (
    <Paper sx={{ bgcolor: "background.default", p: 2 }}>
      <UploadDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={handleChangeFiles}
      />
      <Stack spacing={1}>
        <Typography fontWeight={600}>{t("Attachments")}</Typography>
        <Divider />
        <Box>
          {files.length === 0 ? (
            <Typography align="center" mt={1} mb={1}>
              {t("NoFilesSelected")}
            </Typography>
          ) : (
            <AttachmentList ticketId={props.ticketId} upload files={files} />
          )}
        </Box>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={files.length > 0 ? 6 : 12}>
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                fullWidth
                startIcon={<CloudUploadIcon />}
              >
                {t("Upload")}
              </Button>
            </Grid>
            {files.length > 0 && (
              <Grid item xs={6}>
                <Button
                  color="error"
                  onClick={() => setFiles([])}
                  variant="contained"
                  fullWidth
                >
                  {t("Clear")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>
    </Paper>
  );
}
