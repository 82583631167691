import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import FormInput from "../../molecules/FormInput";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Department, TicketPriorityEnum } from "../../../services/swagger";
import { useGlobalContext } from "../../../contexts/Global";
import AvatarBanner from "../../molecules/AvatarBanner";
import { DepartmentService, SettingsService } from "../../../services/api";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import useEnums from "../../../hooks/useEnums";
import { DropDownItem } from "../../molecules/Dropdown";
import Authorized from "../../atoms/Authorized";

export interface CreateTicketInfoFields {
  company: string;
  city: string;
  voucher: string;
  priority: TicketPriorityEnum;
  departmentId: string;
}

interface CreateTicketInfoProps {
  onChange: (fields: CreateTicketInfoFields) => void;
}

export default function CreateTicketInfo(props: CreateTicketInfoProps) {
  const { t } = useTranslation("Ticket");
  const { priorityDropDownItems } = useEnums();
  const { context } = useGlobalContext();
  const [company, setCompany] = useState("");
  const [voucher, setVoucher] = useState("");
  const [cities, setCities] = useState<DropDownItem[]>([]);
  const [city, setCity] = useState("");
  const [priority, setPriority] = useState<TicketPriorityEnum>("medium");
  const [departmentId, setDepartmentId] = useState("");
  const [departments, setDepartments] = useState<Department[]>([]);
  const [voucherAdd, setVoucherAdd] = useState(false);

  const loadCities = async () => {
    const res = await SettingsService.getCities();
    if (!res?.data?.data) return;
    setCities(res.data.data.map((e) => ({ name: e, value: e })));
    if (res.data.data.length > 0) setCity(res.data.data[0]);
  };

  const loadDepartments = async () => {
    const res = await DepartmentService.getDepartmentMiniList();
    if (!res?.data?.data) return;
    setDepartments(res.data.data);
    if (res.data.data.length > 0) setDepartmentId(res.data.data[0]._id || "");
  };

  useEffect(() => {
    props.onChange({
      company,
      city,
      voucher,
      priority,
      departmentId,
    });
  }, [company, voucher, priority, city, departmentId]);

  useEffect(() => {
    loadDepartments();
    loadCities();
  }, []);

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <Stack spacing={2}>
        <Box>
          <AvatarBanner size={72}>{context.user?.name || ""}</AvatarBanner>
          <Typography fontSize="large" textAlign="center" fontWeight={600}>
            {context.user?.name}
          </Typography>
          <Typography align="center" color="text.secondary">
            {context.user?.company}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Grid container spacing={2}>
            <Authorized role="agent">
              <Grid item xs={12}>
                <FormInput
                  role="agent"
                  field="company"
                  label={t("Company")}
                  type="string"
                  placeholder={t("CompanyPlaceholder")}
                  onChange={(v) => setCompany(v as string)}
                  value={company}
                  required
                />
              </Grid>
            </Authorized>
            <Grid item xs={12}>
              <FormInput
                field="priority"
                label={t("Priority")}
                onChange={(v) => setPriority(v as TicketPriorityEnum)}
                type="list"
                value={priority}
                list={priorityDropDownItems}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                field="city"
                label={t("City")}
                type="list"
                placeholder={t("CityPlaceholder")}
                onChange={(v) => setCity(v as string)}
                value={city}
                list={cities}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                field="departmentId"
                label={t("Department")}
                type="list"
                placeholder={t("DepartmentPlaceholder")}
                list={departments.map((department) => {
                  return {
                    name: department.name,
                    value: department._id || "",
                  };
                })}
                onChange={(v) => setDepartmentId(v as string)}
                value={departmentId}
                required
              />
            </Grid>
            <Grid item xs={12}>
              {voucherAdd ? (
                <FormInput
                  field="voucher"
                  label={t("Voucher")}
                  onChange={(v) => setVoucher(v as TicketPriorityEnum)}
                  type="string"
                  value={voucher}
                  placeholder="XXXX-XXXX-XXXX-XXXX"
                />
              ) : (
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => setVoucherAdd(true)}
                >
                  {t("AddVoucher")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>

        <Button variant="contained" color="success" type="submit">
          {t("CreateTicket")}
        </Button>
      </Stack>
    </Paper>
  );
}
