import {
  Box,
  ButtonBase,
  Divider,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../contexts/Global";
import useBreakpoint from "../../hooks/useBreakpoint";
import SideBarButton from "../molecules/SideBarButton";
import SettingsIcon from "@mui/icons-material/Settings";
import StorageIcon from "@mui/icons-material/Storage";
import SecurityIcon from "@mui/icons-material/Security";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import Authorized from "../atoms/Authorized";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AvatarWithInitials from "../atoms/AvatarWithInitials";
import { useRef, useState } from "react";
import AccountPopover from "../molecules/AccountPopover";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import GradingIcon from "@mui/icons-material/Grading";
import { useTranslation } from "react-i18next";
import useEnums from "../../hooks/useEnums";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BallotIcon from "@mui/icons-material/Ballot";
import { TicketStatusEnum } from "../../services/swagger";

export const sideBarWidth = 260;

function SideBarContent() {
  const { t } = useTranslation("Navigation");
  const { statusObj } = useEnums();

  return (
    <Stack spacing={1} sx={{ p: 2 }}>
      <SideBarButton
        icon={<DashboardIcon />}
        value={t("Dashboard")}
        page="dashboard"
      />

      <SideBarButton
        icon={<ConfirmationNumberIcon />}
        value={t("Tickets")}
        page="ticket"
        filterField="status"
        submenu={[
          {
            icon: <BallotIcon />,
            index: "all-tickets",
            value: t("AllTickets"),
          },
          {
            icon: <ErrorRoundedIcon />,
            index: TicketStatusEnum.NotReviewed,
            filter: {
              field: "status",
              value: TicketStatusEnum.NotReviewed,
            },
            value: statusObj["Not reviewed"],
          },
          {
            icon: <HourglassBottomRoundedIcon />,
            index: TicketStatusEnum.Incomplete,
            filter: {
              field: "status",
              value: TicketStatusEnum.Incomplete,
            },
            value: statusObj["Incomplete"],
          },
          {
            icon: <WatchLaterRoundedIcon />,
            index: TicketStatusEnum.PendingConfirmation,
            filter: {
              field: "status",
              value: TicketStatusEnum.PendingConfirmation,
            },
            value: statusObj["Pending confirmation"],
          },
          {
            icon: <CheckCircleIcon />,
            index: TicketStatusEnum.Complete,
            filter: {
              field: "status",
              value: TicketStatusEnum.Complete,
            },
            value: statusObj["Complete"],
          },
        ]}
      />
      <SideBarButton
        icon={<StorageIcon />}
        value={t("Storage")}
        page="storage"
      />
      <Authorized role="admin">
        <SideBarButton icon={<PersonIcon />} value={t("Users")} page="user" />
        <SideBarButton
          icon={<GroupIcon />}
          value={t("Departments")}
          page="department"
        />
      </Authorized>
      <Divider />
      <Authorized role="admin">
        <SideBarButton
          icon={<SecurityIcon />}
          value={t("Security")}
          page="security"
        />
        <SideBarButton icon={<GradingIcon />} value={t("Logs")} page="logs" />
        <SideBarButton
          icon={<SettingsIcon />}
          value={t("Settings")}
          page="settings"
        />
      </Authorized>
    </Stack>
  );
}

export default function SideBar() {
  const { context } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<any>(null);
  return (
    <>
      <Stack
        sx={{
          height: 1,
          overflowY: "auto",
          width: context.sidebar ? sideBarWidth : undefined,
        }}
      >
        <Box flexGrow={1} overflow="auto" sx={{ overflowX: "hidden" }}>
          <SideBarContent />
        </Box>
        <Box>
          <Divider />
          <ButtonBase
            onClick={() => setOpen(true)}
            ref={anchorEl}
            sx={{
              p: 2,
              width: 1,
              justifyContent: context.sidebar ? "space-between" : undefined,
            }}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <AvatarWithInitials size={36}>
                {context.user?.name || ""}
              </AvatarWithInitials>
              {context.sidebar && (
                <Box textAlign="left">
                  <Typography fontSize={16} fontWeight="bold">
                    {context?.user?.username}
                  </Typography>
                  <Typography fontWeight="light" fontSize={15}>
                    {context?.user?.role}
                  </Typography>
                </Box>
              )}
            </Stack>
            {context.sidebar && (
              <KeyboardArrowRightIcon
                sx={{ fontSize: 30, color: "text.secondary" }}
              />
            )}
          </ButtonBase>
        </Box>
      </Stack>
      <AccountPopover
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ ml: 1 }}
      />
    </>
  );
}
