import { Button, TableCell } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Log, PaginatedListLog } from "../../services/swagger";
import { LogService } from "../../services/api";
import { useTranslation } from "react-i18next";
import DataView, { QueryParameters } from "../organisms/DataView";
import ItemCard from "../organisms/ItemCard";

export default function LogsPage() {
  const { t } = useTranslation("Logs");
  const navigate = useNavigate();
  const [logs, setLogs] = useState<PaginatedListLog>({
    count: 0,
    docs: [],
  });

  const loadLogs = async (query: QueryParameters) => {
    const res = await LogService.getLogList({
      search: query.search,
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortDirection: query.sortDirection,
      sortField: query.sortField,
    });
    if (res?.data?.data) setLogs(res?.data?.data);
  };

  const handleDataTableChange = (query: QueryParameters) => {
    loadLogs(query);
  };

  const handleReferenceNavigation = (log: Log) => {
    if (!log?.object || !log?.model) return;
    navigate(`/m/${log.model}/${log.object}`);
  };

  return (
    <DataView
      name={t("Logs")}
      headers={[
        { field: "date", name: t("Date"), type: "date" },
        {
          field: "object",
          name: t("Reference"),
          type: "other",
          renderRowCell: (row: Log) => (
            <TableCell key={row._id} width={100}>
              <Button
                size="small"
                onClick={() => handleReferenceNavigation(row)}
                variant="contained"
                disabled={!row.object || !row.model}
              >
                {t("Ref")}
              </Button>
            </TableCell>
          ),
        },
        {
          field: "user",
          name: t("User"),
          type: "other",
          renderRowCell: (row: Log) => (
            <TableCell key={row._id}>
              {row.user?.username || row.username}
            </TableCell>
          ),
        },
        { field: "model", name: t("Model"), type: "string" },
        {
          field: "action",
          name: t("Action"),
          type: "other",
          renderRowCell: (row: Log) => (
            <TableCell key={row._id}>
              {row.action || `${row.object} ${(row as any).type}`}
            </TableCell>
          ),
        },
      ]}
      onChange={handleDataTableChange}
      items={logs.docs}
      count={logs.count}
      renderItem={(log) => (
        <ItemCard log={log} onClick={() => handleReferenceNavigation(log)} />
      )}
      pagination
      queryFunction={LogService.getLogListQuery}
    />
  );
}
