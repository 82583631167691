import { Box, Paper, Typography, useTheme } from "@mui/material";
import { BarChart, BarItem } from "@mui/x-charts";
import { DashboardData, DateRange } from "../../../services/swagger";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface DashboardTicketCountProps {
  data: DashboardData;
}

export default function DashboardTicketCount(props: DashboardTicketCountProps) {
  const { t } = useTranslation("Dashboard");
  const { data } = props;
  const ticketCount = data.ticketCount;
  const theme = useTheme();

  const empty = useMemo(() => {
    return !data.ticketCount.some((item) => item.count > 0);
  }, [ticketCount]);

  return (
    <Paper sx={{ bgcolor: "background.default" }}>
      <Typography
        color="primary"
        variant="h3"
        fontSize={20}
        fontWeight="bold"
        pb={1}
        pt={4}
        pl={4}
      >
        {t("Tickets")}
      </Typography>
      <BarChart
        series={[{ data: empty ? [] : ticketCount.map((e) => e.count) }]}
        // barLabel="value"
        yAxis={[{ tickMinStep: 1, disableTicks: true }]}
        xAxis={[
          {
            data: empty ? [] : ticketCount.map((e) => e.label),
            scaleType: "band",
            disableTicks: true,
          },
        ]}
        borderRadius={4}
        colors={[theme.palette.primary.main]}
        height={300}
      />
    </Paper>
  );
}
