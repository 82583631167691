import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import ManagementTemplate from "../../templates/ManagementTemplate";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import TextEditor from "../../organisms/TextEditor";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { FtpService } from "../../../services/api";
import useConfirm from "../../../hooks/useConfirm";

export default function TextEditorPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const path = searchParams.get("path");
  const [text, setText] = useState("");
  const { confirm, ConfirmDialog } = useConfirm();

  const loadTextFile = async () => {
    if (!path) return;
    const res = await FtpService.getFileDownload({
      path,
      ticketId: "",
      disableForceDownload: true,
      mimeType: "text/plain; charset=UTF-8",
    });
    const content = res.data as unknown as string;
    if (typeof content === "object") setText(JSON.stringify(content));
    else setText(content);
  };

  const handleClose = () => {
    if (!path) return;
    navigate({
      pathname: "/m/storage",
      search: createSearchParams({
        path: path.substring(0, path.lastIndexOf("/")),
      }).toString(),
    });
  };

  const handleSave = () => {
    confirm(async () => {
      if (!path) return;
      const folderPath = path.substring(0, path.lastIndexOf("/"));
      const filename = path.substring(path.lastIndexOf("/") + 1, path.length);
      const file = new File([text], filename, {
        type: "text/plain",
      });
      const formData = new FormData();
      formData.append("files", file);
      const res = await FtpService.createFileUpload(
        {
          path: folderPath,
        },
        {
          data: formData,
        }
      );
      if (res.data.success) loadTextFile();
    });
  };

  useEffect(() => {
    loadTextFile();
  }, []);

  return (
    <Paper sx={{ bgcolor: "background.default", height: 1 }}>
      <ConfirmDialog />
      <Box height={1} display="flex" flexDirection="column">
        <Box
          p={1}
          px={2}
          display="flex"
          boxSizing="border-box"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={500} color="text.secondary">
            {path && path.substring(path.lastIndexOf("/") + 1, path.length)}
          </Typography>
          <Stack direction="row" spacing={1}>
            <IconButton onClick={handleSave}>
              <SaveIcon sx={{ color: "primary.main" }} />
            </IconButton>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Box>
        <Divider />
        <Box p={1} flexGrow={1} overflow="auto">
          <TextEditor value={text} onChange={setText} onSave={handleSave} />
        </Box>
      </Box>
    </Paper>
  );
}
