import {
  ButtonBase,
  ButtonBaseProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../contexts/Global";
import { MouseEventHandler } from "react";

interface SideBarButtonBaseProps {
  icon?: any;
  value: string;
  matched: boolean;
  buttonBaseProps?: ButtonBaseProps;
  forceViewText?: boolean;
  forceHideTooltip?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function SideBarButtonBase(props: SideBarButtonBaseProps) {
  const { context } = useGlobalContext();

  return (
    <Tooltip
      title={
        context.sidebar || props.forceViewText || props.forceHideTooltip
          ? ""
          : props.value
      }
      placement="right"
    >
      <ButtonBase
        {...props.buttonBaseProps}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: (theme) => theme.shape.borderRadius / 8,
          transitionDuration: "200ms",
          p: context.sidebar ? 1 : 1.5,
          py: 1.5,
          fontWeight: "light",
          color: props.matched
            ? (theme) => theme.palette.primary.main
            : (theme) => theme.palette.text.secondary,
          background: props.matched
            ? (theme) => theme.palette.background.paper
            : "none",
          ":hover": {
            color: (theme) =>
              props.matched
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            background: (theme) => theme.palette.background.paper,
          },
          ":hover > p": {
            transitionDuration: "600ms",
            ml: 1,
          },
          ...props.buttonBaseProps?.sx,
        }}
        onClick={props.onClick}
        focusRipple
      >
        {props.icon}
        {(context.sidebar || props.forceViewText) && (
          <Typography fontWeight={500} pl={props.icon ? 1 : 0}>
            {props.value}
          </Typography>
        )}
      </ButtonBase>
    </Tooltip>
  );
}
