import { Box, Button, Link, Paper, Stack, Typography } from "@mui/material";
import MainTemplate from "../templates/MainTemplate";
import { useNavigate } from "react-router";
import { FormEvent, useEffect, useState } from "react";
import { useGlobalContext } from "../../contexts/Global";
import FormInput from "../molecules/FormInput";
import { UserService } from "../../services/api";
import Stepper from "../molecules/Stepper";
import PasswordIcon from "@mui/icons-material/Password";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import StepperContent from "../atoms/StepperContent";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useTranslation } from "react-i18next";

export default function ForgotPasswordPage() {
  const { t } = useTranslation("ForgotPasswordPage");
  const breakpoint = useBreakpoint();
  const { context } = useGlobalContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [counter, setCounter] = useState(0);

  const getConfirmationCode = async () => {
    const res = await UserService.createUserPasswordChangeConfirmation({
      email,
    });
    if (res?.data?.success) {
      setActiveStep(1);
      setCounter(30);
    }
  };

  const handleSubmitEmail = async (e: FormEvent) => {
    e.preventDefault();
    if (activeStep !== 0) return;
    getConfirmationCode();
  };

  const handleTryAgain = () => {
    if (counter > 0) return;
    getConfirmationCode();
  };

  const handleUpdatePassword = async (e: FormEvent) => {
    e.preventDefault();
    const res = await UserService.updateUserPassword({
      email,
      updateUserPasswordBody: {
        code,
        newPassword,
      },
    });
    if (res?.data?.success) navigate("/");
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter((c) => c - 1);
      }, 1000);
    }
  }, [counter]);

  return (
    <MainTemplate>
      <Box
        height={1}
        display="flex"
        justifyContent="center"
        bgcolor="background.paper"
        p={breakpoint.md ? 8 : 2}
        boxSizing="border-box"
      >
        <Paper
          sx={{ bgcolor: "background.default", boxSizing: "border-box", p: 4 }}
        >
          <Stepper
            activeStep={activeStep}
            steps={[
              {
                icon: <PasswordIcon />,
                label: t("RequestCode"),
                step: 0,
                value: "request-code",
              },
              {
                icon: <VpnKeyIcon />,
                label: t("SubmitCode"),
                step: 0,
                value: "submit-count",
              },
            ]}
          />
          <StepperContent activeStep={activeStep} step={0}>
            <Box component="form" onSubmit={handleSubmitEmail}>
              <Typography variant="h4" gutterBottom mt={4}>
                {context.user
                  ? t("ChangeYourPassword")
                  : t("ForgotYourPassword")}
              </Typography>
              <Typography mb={4}>{t("ResetPasswordBody")}</Typography>
              <Stack spacing={2}>
                <FormInput
                  field="email"
                  type="string"
                  inputType="email"
                  label={t("EmailAddress")}
                  required
                  value={email}
                  onChange={(value) => setEmail(value as string)}
                />
              </Stack>
              <Button
                sx={{ mt: 4 }}
                variant="contained"
                fullWidth
                type="submit"
              >
                {t("RequestCode")}
              </Button>
              <Button
                sx={{ mt: 2, fontWeight: "bold" }}
                fullWidth
                onClick={() => navigate(context.user ? "../info" : "/login")}
              >
                {context.user ? t("BackToAccountInfo") : t("BackToLogin")}
              </Button>
            </Box>
          </StepperContent>
          <StepperContent activeStep={activeStep} step={1}>
            <Box component="form" onSubmit={handleUpdatePassword}>
              <Typography variant="h4" gutterBottom mt={4}>
                {t("Code")}
              </Typography>
              <Typography mb={4} maxWidth={400}>
                {t("CheckYourCode")}
              </Typography>
              <Stack spacing={2}>
                <FormInput
                  field="code"
                  type="string"
                  label={t("ConfirmationCode")}
                  required
                  value={code}
                  onChange={(value) => setCode(value as string)}
                />
                <FormInput
                  field="newPassword"
                  type="string"
                  inputType="password"
                  label={t("NewPassword")}
                  required
                  value={newPassword}
                  onChange={(value) => setNewPassword(value as string)}
                />
              </Stack>
              <Button
                sx={{ mt: 4 }}
                variant="contained"
                fullWidth
                type="submit"
              >
                {t("ChangePassword")}
              </Button>
              <Typography mt={2}>
                {t("DidntGetCode")}{" "}
                <Link
                  component="span"
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: counter > 0 ? "text.disabled" : undefined,
                  }}
                  onClick={handleTryAgain}
                >
                  {t("TryAgain")}{" "}
                  {counter > 0
                    ? ` ${t("SecondsLeft", { seconds: counter })}`
                    : ""}
                </Link>
              </Typography>
            </Box>
          </StepperContent>
        </Paper>
      </Box>
    </MainTemplate>
  );
}
