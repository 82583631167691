import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  Department,
  FirewallRecord,
  Log,
  Ticket,
  User,
} from "../../services/swagger";
import AvatarWithInitials from "../atoms/AvatarWithInitials";
import DateAndName from "../molecules/DateAndName";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import TicketStatus from "../atoms/TicketStatus";
import PriorityChip from "../atoms/PriorityChip";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../helpers/string";
import useBreakpoint from "../../hooks/useBreakpoint";
import UserRoleChip from "../atoms/UserRoleChip";
import EmailIcon from "@mui/icons-material/Email";
import useEnums from "../../hooks/useEnums";

interface ItemCardProps {
  ticket?: Ticket;
  user?: User;
  log?: Log;
  department?: Department;
  record?: FirewallRecord;
  onClick?: () => void;
}

function ItemCardTitle(props: ItemCardProps) {
  const { ticket, user, log, department, record } = props;
  const breakpoint = useBreakpoint();

  if (ticket)
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <AvatarWithInitials size={breakpoint.md ? undefined : 22}>
          {ticket.user?.name || ticket.person || ""}
        </AvatarWithInitials>
        <Typography
          align="left"
          fontWeight={(ticket as any).unRead ? "bold" : undefined}
        >
          {ticket.title}
        </Typography>
      </Stack>
    );
  if (user)
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <AvatarWithInitials size={breakpoint.md ? undefined : 22}>
          {user.name}
        </AvatarWithInitials>
        <Typography>{user.username}</Typography>
      </Stack>
    );
  if (log && log.model)
    return (
      <Typography align="left">{capitalizeFirstLetter(log.model)}</Typography>
    );
  if (department)
    return <Typography align="left">{department.name}</Typography>;
  if (record) return <Typography align="left">{record.ip}</Typography>;
}

function DesktopCardBody(props: ItemCardProps) {
  const { ticket, user, log, department, record } = props;
  const { recordTypeObj } = useEnums();

  if (ticket)
    return (
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <PriorityChip priority={ticket.priority} />
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <ApartmentIcon sx={{ color: "primary.main" }} />
          <Typography align="left">{ticket.company}</Typography>
        </Stack>
        {ticket.department?.name && (
          <Stack direction="row" spacing={1} alignItems="center">
            <GroupOutlinedIcon sx={{ color: "primary.main" }} />
            <Typography align="left">{ticket.department?.name}</Typography>
          </Stack>
        )}
        <>
          <Stack direction="row" spacing={1} alignItems="center">
            <TicketStatus status={ticket.status} />
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <LocationOnIcon sx={{ color: "text.secondary" }} />
            <Typography align="left">
              {ticket.city}, {ticket.location}
            </Typography>
          </Stack>
        </>
      </Stack>
    );
  if (user)
    return (
      <Stack alignItems={"flex-start"} spacing={1}>
        <Typography align="left">{user.name}</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <PersonIcon sx={{ color: "text.secondary" }} />
          <Typography align="left" color="primary.main" fontWeight="bold">
            {user.username}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <ApartmentIcon sx={{ color: "primary.main" }} />
          <Typography align="left" color="primary.main" fontWeight="bold">
            {user.company}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          fontWeight="bold"
        >
          <EmailIcon />
          <Typography align="left">{user.email}</Typography>
        </Stack>
      </Stack>
    );
  if (log)
    return (
      <Stack alignItems={"flex-start"} spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <PersonIcon sx={{ color: "text.secondary" }} />
          <Typography align="left" color="primary.main" fontWeight="bold">
            {log.user?.username || log.username}
          </Typography>
        </Stack>
        <Typography align="left">
          {log.action || `${log.object} ${(log as any).type}`}
        </Typography>
      </Stack>
    );
  if (department)
    return (
      <Typography align="left" color="text.secondary">
        {department.description}
      </Typography>
    );
  if (record)
    return (
      <Stack alignItems={"flex-start"} spacing={1}>
        <Typography align="left" color="text.secondary">
          {record.ip}/{record.subnet}
        </Typography>
        <Typography align="left" color="text.secondary" fontWeight="bold">
          {recordTypeObj[record.type]}
        </Typography>
      </Stack>
    );
}

function MobileCardBody(props: ItemCardProps) {
  const { ticket, user, log, department, record } = props;
  const { recordTypeObj } = useEnums();

  if (ticket)
    return (
      <Stack alignItems={"flex-start"} spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <PersonIcon sx={{ color: "text.secondary" }} />
          <Typography align="left" color="text.secondary" fontWeight="bold">
            {ticket.user?.name || ticket.person || ""}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <ApartmentIcon sx={{ color: "primary.main" }} />
          <Typography align="left" color="primary.main" fontWeight="bold">
            {ticket.company}
          </Typography>
        </Stack>
        {ticket.department?.name && (
          <Stack direction="row" spacing={1} alignItems="center">
            <GroupOutlinedIcon sx={{ color: "primary.main" }} />
            <Typography align="left" color="primary.main" fontWeight="bold">
              {ticket.department?.name}
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  if (user)
    return (
      <Stack alignItems={"flex-start"} spacing={1}>
        <Typography align="left">{user.name}</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <PersonIcon sx={{ color: "text.secondary" }} />
          <Typography align="left" color="primary.main" fontWeight="bold">
            {user.username}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <ApartmentIcon sx={{ color: "primary.main" }} />
          <Typography align="left" color="primary.main" fontWeight="bold">
            {user.company}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          fontWeight="bold"
        >
          <EmailIcon />
          <Typography align="left">{user.email}</Typography>
        </Stack>
      </Stack>
    );
  if (log)
    return (
      <Stack alignItems={"flex-start"} spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <PersonIcon sx={{ color: "text.secondary" }} />
          <Typography align="left" color="primary.main" fontWeight="bold">
            {log.user?.username || log.username}
          </Typography>
        </Stack>
        <Typography align="left">{capitalizeFirstLetter(log.model)}</Typography>
        <Typography align="left">{log.action}</Typography>
      </Stack>
    );
  if (department)
    return (
      <Typography align="left" color="text.secondary">
        {department.description}
      </Typography>
    );
  if (record)
    return (
      <Stack alignItems={"flex-start"} spacing={1}>
        <Typography align="left" color="text.secondary">
          {record.ip}/{record.subnet}
        </Typography>
        <Typography align="left" color="text.secondary" fontWeight="bold">
          {recordTypeObj[record.type]}
        </Typography>
      </Stack>
    );
}

function ItemCardFooter(props: ItemCardProps) {
  const { ticket, log, department, record, user } = props;
  const breakpoint = useBreakpoint();
  if (ticket && breakpoint.md) return <DateAndName date={ticket.date} />;
  else if (ticket)
    return (
      <Stack direction={"row"} alignItems="center" spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <TicketStatus status={ticket.status} />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <LocationOnIcon sx={{ color: "text.secondary" }} />
          <Typography align="left">
            {ticket.city}, {ticket.location}
          </Typography>
        </Stack>
      </Stack>
    );
  if (log) return <DateAndName date={log.date} />;
  if (record) return <DateAndName date={record.date} />;
  if (department) return <DateAndName date={department.date} />;
  if (user) return <UserRoleChip role={user.role} />;
}

function DesktopCardSide(props: ItemCardProps) {
  const { ticket, log, user, department } = props;
  const { t } = useTranslation("Ticket");
  const { t: tLog } = useTranslation("Logs");

  return (
    <Stack height={1} justifyContent="space-between">
      <Box>
        {ticket && (
          <Typography align="right" fontWeight="bold">
            #{ticket.id}
          </Typography>
        )}
        {ticket?.service_call?.call_id && (
          <>
            <Typography align="right" fontSize={12} color="text.secondary">
              {t("ServiceCallID")}
            </Typography>
            <Typography align="right" fontSize={12} color="text.secondary">
              {ticket.service_call.call_id}
            </Typography>
          </>
        )}
      </Box>
      <Box textAlign="right">
        {log ? (
          <Button
            variant="contained"
            sx={{ fontSize: 10 }}
            onClick={props.onClick}
            disabled={!log.object || !log.model}
          >
            {tLog("Ref")}
          </Button>
        ) : (
          (ticket || user || department) && (
            <Button
              variant="contained"
              sx={{ fontSize: 10 }}
              onClick={props.onClick}
            >
              {t("View")}
            </Button>
          )
        )}
      </Box>
    </Stack>
  );
}

function MobileCardSide(props: ItemCardProps) {
  const { ticket } = props;
  const breakpoint = useBreakpoint();
  const { t } = useTranslation("Ticket");

  return (
    <Stack height={1} justifyContent="space-between">
      <Box>
        {ticket && (
          <Typography align="right" fontWeight="bold">
            #{ticket.id}
          </Typography>
        )}
        {ticket?.service_call?.call_id && (
          <>
            <Typography align="right" fontSize={12} color="text.secondary">
              {t("ServiceCallID")}
            </Typography>
            <Typography align="right" fontSize={12} color="text.secondary">
              {ticket.service_call.call_id}
            </Typography>
          </>
        )}
      </Box>
      <Box textAlign="right">
        {ticket && <PriorityChip priority={ticket.priority} />}
      </Box>
    </Stack>
  );
}

export default function ItemCard(props: ItemCardProps) {
  const breakpoint = useBreakpoint();

  return (
    <Paper
      component={!breakpoint.md && props.onClick ? ButtonBase : Paper}
      onClick={!breakpoint.md ? props.onClick : undefined}
      sx={{ p: 1, bgcolor: "background.default" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Stack spacing={1}>
            <ItemCardTitle {...props} />
            <Box
              sx={{
                ".MuiTypography-root": { fontSize: 12 },
                ".MuiChip-label": { fontSize: 12 },
              }}
              textAlign="left"
            >
              {breakpoint.md ? (
                <DesktopCardBody {...props} />
              ) : (
                <MobileCardBody {...props} />
              )}
            </Box>
            <Box
              sx={{
                ".MuiTypography-root": { fontSize: 12 },
                ".MuiChip-label": { fontSize: 12 },
              }}
              textAlign="left"
            >
              <ItemCardFooter {...props} />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          {breakpoint.md ? (
            <DesktopCardSide {...props} />
          ) : (
            <MobileCardSide {...props} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
