import { useEffect, useState } from "react";
import FormTemplate from "../../templates/FormTemplate";
import { Box, Stack } from "@mui/material";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";
import { useTranslation } from "react-i18next";
import ReorderableList from "../../molecules/ReorderableList";

export default function GeneralSettingsPage() {
  const { t } = useTranslation("Settings");
  const { context } = useGlobalContext();
  const refresh = useRefresh();

  const [cities, setCities] = useState<string[]>([]);

  const handleSubmit = async () => {
    if (!context.settings) return;
    await SettingsService.updateSettings({
      appSettings: {
        ...context.settings,
        cities,
      },
    });
    refresh();
  };

  useEffect(() => {
    if (!context.settings) return;
    setCities(context.settings.cities);
  }, [context.settings]);

  return (
    <FormTemplate
      title={t("General")}
      disableHeaderCloseButton
      disableCancelButton
      submitButtonText={t("Save")}
      disableEnter
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <ReorderableList
          unique
          confirmDeletion
          label={t("Cities")}
          items={cities}
          onChange={(list) => setCities(list)}
        />
      </Stack>
    </FormTemplate>
  );
}
