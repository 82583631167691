import { Box, Stack } from "@mui/material";
import { useState } from "react";
import FormInput from "../../molecules/FormInput";
import { SMTPSettings } from "../../../services/swagger";
import FormTemplate from "../../templates/FormTemplate";
import { useTranslation } from "react-i18next";

interface EmailSetupProps {
  smtpSettings?: SMTPSettings;
  onSubmit: (emailConfig: SMTPSettings) => void;
  onCancel: () => void;
}

export default function EmailSetup(props: EmailSetupProps) {
  const { t } = useTranslation("SetupPage");
  const [from, setFrom] = useState(props.smtpSettings?.from || "");
  const [username, setUsername] = useState(props.smtpSettings?.username || "");
  const [password, setPassword] = useState(props.smtpSettings?.password || "");
  const [server, setServer] = useState(props.smtpSettings?.server || "");
  const [port, setPort] = useState(props.smtpSettings?.port || 25);
  const [secure, setSecure] = useState(props.smtpSettings?.secure || false);

  const handleSubmit = () => {
    props.onSubmit({
      enabled: true,
      from,
      password,
      port,
      secure,
      server,
      username,
    });
  };

  return (
    <FormTemplate
      title={t("EmailSetup.EmailSetup")}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
      cancelButtonText={t("Back")}
      submitButtonText={t("Next")}
      disableHeaderCloseButton
    >
      <Stack spacing={1}>
        <FormInput
          required
          label={t("EmailSetup.Server")}
          placeholder="smtp.gmail.com"
          field="server"
          type="string"
          value={server}
          onChange={(value) => setServer(value as string)}
        />
        <FormInput
          label={t("EmailSetup.From")}
          placeholder="john.doe@email.com"
          field="from"
          type="string"
          value={from}
          onChange={(value) => setFrom(value as string)}
        />
        <FormInput
          required
          label={t("EmailSetup.Username")}
          field="username"
          type="string"
          value={username}
          onChange={(value) => setUsername(value as string)}
        />
        <FormInput
          required
          label={t("EmailSetup.Password")}
          field="password"
          inputType="password"
          type="string"
          value={password}
          onChange={(value) => setPassword(value as string)}
        />
        <FormInput
          required
          label={t("EmailSetup.Port")}
          field="port"
          type="number"
          value={port}
          onChange={(value) => setPort(value as number)}
        />
        <FormInput
          label={t("EmailSetup.Secure")}
          field="secure"
          type="switch"
          value={secure}
          onChange={(value) => setSecure(value as boolean)}
        />
      </Stack>
    </FormTemplate>
  );
}
