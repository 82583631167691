import { Stack } from "@mui/material";
import { useState } from "react";
import FormInput from "../../molecules/FormInput";
import { OpenAISettings } from "../../../services/swagger";
import FormTemplate from "../../templates/FormTemplate";
import { useTranslation } from "react-i18next";

interface OpenAiSetupProps {
  openAiSettings?: OpenAISettings;
  onSubmit: (openAiConfig: OpenAISettings) => void;
  onCancel: () => void;
}

export default function OpenAiSetup(props: OpenAiSetupProps) {
  const { t } = useTranslation("SetupPage");
  const [apiKey, setApiKey] = useState(props.openAiSettings?.apiKey || "");
  const [assistantId, setAssistantId] = useState(
    props.openAiSettings?.assistantId || ""
  );
  const [organizationId, setOrganizationId] = useState(
    props.openAiSettings?.organizationId || ""
  );

  const handleSubmit = () => {
    props.onSubmit({
      enabled: true,
      apiKey,
      assistantId,
      organizationId,
    });
  };

  return (
    <FormTemplate
      title={t("OpenAISetup.OpenAISetup")}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
      cancelButtonText={t("Back")}
      submitButtonText={t("Next")}
      disableHeaderCloseButton
    >
      <Stack spacing={1}>
        <FormInput
          required
          label={t("OpenAISetup.ApiKey")}
          field="apiKey"
          type="string"
          value={apiKey}
          onChange={(value) => setApiKey(value as string)}
        />
        <FormInput
          required
          label={t("OpenAISetup.AssistantID")}
          field="assistantId"
          type="string"
          value={assistantId}
          onChange={(value) => setAssistantId(value as string)}
        />

        <FormInput
          required
          label={t("OpenAISetup.OrganizationID")}
          field="organizationId"
          type="string"
          value={organizationId}
          onChange={(value) => setOrganizationId(value as string)}
        />
      </Stack>
    </FormTemplate>
  );
}
