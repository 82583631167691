import { Box, Stack } from "@mui/material";
import { Filter, FilterValueType, Operator } from "../../services/swagger";
import { DropDownItem } from "./Dropdown";
import { useState } from "react";
import DeleteButton from "../atoms/DeleteButton";
import OperatorSelectorButton from "../atoms/OperatorSelectorButton";
import FormInput from "./FormInput";
import useDidMountEffect from "../../hooks/useDidMountEffect";

interface EndAdornmentProps {
  type: FilterValueType;
  onChange: (operator: Operator) => void;
  onDelete: () => void;
}

interface FilterInputProps {
  label: string;
  field: string;
  type: FilterValueType;
  list?: DropDownItem[];
  onDelete: () => void;
  onChange: (filter: Filter) => void;
  value: string | number | boolean;
}

type AllowedOperators = {
  [key in FilterValueType]: Operator[];
};

export const allowedOperators: AllowedOperators = {
  id: ["eq", "ne"],
  boolean: ["eq", "ne"],
  date: ["gt", "gte", "lt", "lte"],
  list: ["eq", "ne"],
  number: ["eq", "ne", "gt", "gte", "lt", "lte"],
  string: ["regex", "eq", "ne"],
};

function EndAdornment(props: EndAdornmentProps & { mr?: number }) {
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ ml: 1, mr: props.mr }}
    >
      <OperatorSelectorButton
        type={props.type}
        onChange={(newOperator) => {
          props.onChange(newOperator);
        }}
        size={25}
      />
      <DeleteButton size={25} onClick={props.onDelete} />
    </Stack>
  );
}

export default function FilterInput(props: FilterInputProps) {
  const [operator, setOperator] = useState<Operator>(
    allowedOperators[props.type][0]
  );

  const [value, setValue] = useState<any>("");
  const handleChange = (filter?: Filter) => {
    props.onChange(
      filter || {
        field: props.field,
        operator,
        type: props.type,
        value,
      }
    );
  };

  useDidMountEffect(() => {
    handleChange();
  }, [operator]);

  if (props.type === FilterValueType.List)
    return (
      <FormInput
        type="list"
        labelFontSize={12}
        field={props.field}
        label={props.label}
        placeholder="Any"
        value={props.value}
        list={props.list || []}
        endAdornment={
          <EndAdornment
            type={props.type}
            onChange={(newOperator) => setOperator(newOperator)}
            onDelete={props.onDelete}
            mr={-1}
          />
        }
        onChange={(value) => {
          setValue(value || null);
          props.onChange?.({
            field: props.field,
            operator,
            type: props.type,
            value: value || null,
          });
        }}
      />
    );
  else if (props.type === FilterValueType.Boolean)
    return (
      <FormInput
        type="boolean"
        labelFontSize={12}
        field={props.field}
        label={props.label}
        endAdornment={
          <EndAdornment
            type={props.type}
            onChange={(newOperator) => setOperator(newOperator)}
            onDelete={props.onDelete}
            mr={-1}
          />
        }
        onChange={(value) => {
          setValue(value === undefined ? undefined : value);
          props.onChange?.({
            field: props.field,
            operator,
            type: props.type,
            value: value === undefined ? undefined : value,
          });
        }}
      />
    );
  else
    return (
      <FormInput
        labelFontSize={12}
        field={props.field}
        label={props.label}
        placeholder={props.label}
        value={props.value}
        endAdornment={
          <Box>
            <EndAdornment
              type={props.type}
              onChange={(newOperator) => setOperator(newOperator)}
              onDelete={props.onDelete}
            />
          </Box>
        }
        type={props.type}
        inputType={props.type}
        onChange={(value) => {
          setValue(value);
          props.onChange?.({
            field: props.field,
            operator,
            type: props.type,
            value,
          });
        }}
      />
    );
}
