import { useMemo, useRef, useState } from "react";
import { TicketService } from "../../../services/api";
import { Button, Stack, TableCell } from "@mui/material";
import ManagementTemplate from "../../templates/ManagementTemplate";
import { PaginatedListTicket, Ticket } from "../../../services/swagger";
import { useNavigate } from "react-router";
import PriorityChip from "../../atoms/PriorityChip";
import AvatarWithInitials from "../../atoms/AvatarWithInitials";
import useRefresh from "../../../hooks/useRefresh";
import TicketStatus from "../../atoms/TicketStatus";
import { useGlobalContext } from "../../../contexts/Global";
import { useTranslation } from "react-i18next";
import ItemCard from "../../organisms/ItemCard";
import DataView, { QueryParameters } from "../../organisms/DataView";

export default function TicketsPage() {
  const { t } = useTranslation("Ticket");
  const { context } = useGlobalContext();
  const navigate = useNavigate();
  const lastQuery = useRef<QueryParameters | null>(null);
  const [tickets, setTickets] = useState<PaginatedListTicket>({
    count: 0,
    docs: [],
  });

  const getTicketReadStatus = useMemo(() => {
    if (!context.user) return (ticket: Ticket) => ticket;
    return (ticket: Ticket): Ticket & { unRead?: boolean } => {
      const username = context.user?.username || "";
      const _id = context.user?._id || "";
      let userUpdateById = ticket.usersUpdated.find((userUpdate) => {
        if (userUpdate.user) return (userUpdate.user as any) === _id;
      });
      let userUpdateByUsername = ticket.usersUpdated.find((userUpdate) => {
        if (typeof (userUpdate as any).username === "string")
          return (userUpdate as any).username === username;
      });
      const userUpdate = userUpdateById || userUpdateByUsername;
      const read = (userUpdate?.lastUpdate || 0) > ticket.lastUpdate;
      if (userUpdate) {
        return userUpdate.lastUpdate >= ticket.lastUpdate
          ? { ...ticket, unRead: !read }
          : { ...ticket, unRead: !read };
      } else return { ...ticket, unRead: !read };
    };
  }, [context.user]);

  const loadTickets = async (query: QueryParameters) => {
    lastQuery.current = query;
    const res = await TicketService.getTicketList({
      search: query.search,
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortField: query.sortField,
      sortDirection: query.sortDirection,
    });
    if (res?.data?.data) setTickets(res?.data?.data);
  };

  const navigateToTicket = (ticket: Ticket) => {
    navigate(`/m/ticket/${ticket._id}`);
  };

  useRefresh(() => {
    if (lastQuery.current) loadTickets(lastQuery.current);
  }, "ticket");

  const handleDataTableChange = (query: QueryParameters) => {
    loadTickets(query);
  };

  return (
    <DataView
      name={t("Tickets")}
      button={{
        text: t("NewTicket"),
        onClick: () => navigate(`/m/ticket/create`),
      }}
      pagination
      count={tickets.count}
      items={
        context.user
          ? tickets.docs.map((ticket) => getTicketReadStatus(ticket))
          : []
      }
      headers={[
        {
          field: "person",
          name: t("Person"),
          type: "other",
          renderHeaderCell: () => (
            <TableCell width={20}>{t("Person")}</TableCell>
          ),
          renderRowCell: (row: Ticket) => {
            return (
              <TableCell>
                <AvatarWithInitials>
                  {row.user?.name || row.person || ""}
                </AvatarWithInitials>
              </TableCell>
            );
          },
        },
        {
          field: "id",
          name: t("ID"),
          type: "number",
        },
        {
          field: "date",
          name: t("Date"),
          type: "date",
        },
        {
          field: "company",
          name: t("Company"),
          type: "string",
        },
        {
          field: "title",
          name: t("Title"),
          type: "other",
          renderRowCell: (row: Ticket) => (
            <TableCell
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: 300,
                textOverflow: "ellipsis",
              }}
            >
              {row?.title}
            </TableCell>
          ),
        },
        {
          field: "department",
          name: t("Department"),
          type: "other",
          renderRowCell: (row: Ticket) => {
            return <TableCell>{row?.department?.name}</TableCell>;
          },
        },
        {
          field: "status",
          name: t("Status"),
          type: "other",
          renderRowCell: (row: Ticket) => {
            return (
              <TableCell>
                <TicketStatus status={row.status} />
              </TableCell>
            );
          },
        },
        {
          field: "service_call.call_id",
          name: t("ServiceCallNumber"),
          type: "other",
          renderRowCell: (row: Ticket) => {
            return (
              <TableCell>
                {row.service_call?.call_id || t("NotAvailable")}
              </TableCell>
            );
          },
        },
        {
          field: "location",
          name: t("Location"),
          type: "other",
          renderRowCell: (row: Ticket) => (
            <TableCell
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: 200,
                textOverflow: "ellipsis",
              }}
            >
              {[row.city, row.location].filter((e) => Boolean(e)).join(", ")}
            </TableCell>
          ),
        },
        {
          field: "priority",
          name: t("Priority"),
          type: "other",
          renderHeaderCell: () => (
            <TableCell width={50} align="center">
              {t("Priority")}
            </TableCell>
          ),
          renderRowCell: (row: Ticket) => {
            return (
              <TableCell align="center">
                <PriorityChip priority={row.priority} />
              </TableCell>
            );
          },
        },
        {
          field: "delete",
          name: "",
          type: "other",
          renderHeaderCell: () => (
            <TableCell align="center" width={70}>
              {t("Action")}
            </TableCell>
          ),
          renderRowCell: (row: Ticket) => (
            <TableCell>
              <Stack direction="row" spacing={1}>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => navigateToTicket(row)}
                >
                  {t("Manage")}
                </Button>
              </Stack>
            </TableCell>
          ),
        },
      ]}
      queryFunction={TicketService.getTicketListQuery}
      onChange={handleDataTableChange}
      renderItem={(ticket) => (
        <ItemCard ticket={ticket} onClick={() => navigateToTicket(ticket)} />
      )}
    />
  );
}
