import {
  Avatar,
  ButtonBase,
  ButtonBaseProps,
  SvgIconTypeMap,
  Tooltip,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface SquareIconButton extends ButtonBaseProps {
  tooltip?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
}

export default function SquareIconButton(props: SquareIconButton) {
  return (
    <Tooltip title={props.tooltip}>
      <ButtonBase
        sx={{
          borderRadius: (theme) => theme.shape.borderRadius / 8,
          ...props.sx,
        }}
        {...props}
      >
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: (theme) => theme.palette[props.color || "primary"].main,
            width: 27,
            height: 27,
          }}
        >
          <props.icon
            sx={{
              width: 18,
              height: 18,
              color: (theme) =>
                theme.palette[props.color || "primary"].contrastText,
            }}
          />
        </Avatar>
      </ButtonBase>
    </Tooltip>
  );
}
