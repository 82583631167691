import { Box, Collapse, Drawer, Paper, Stack } from "@mui/material";
import SideBar, { sideBarWidth } from "../organisms/SideBar";
import TopBar from "../organisms/TopBar";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useGlobalContext } from "../../contexts/Global";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Route, Routes } from "react-router";
import DashboardPage from "../pages/Dashboard";
import StoragePage from "../pages/Storage";
import TextEditorPage from "../pages/Storage/TextEditorPage";
import TicketsPage from "../pages/Tickets";
import CreateManagementTicketPage from "../pages/Tickets/CreateManagementTicket";
import ManageTicketPage from "../pages/Tickets/ManageTicket";
import DepartmentsPage from "../pages/Departments";
import EditDepartmentPage from "../pages/Departments/EditDepartment";
import UsersPage from "../pages/Users";
import LogsPage from "../pages/Logs";
import SettingsPage from "../pages/Settings";
import SecurityPage from "../pages/Security";
import EditUserPage from "../pages/Users/EditUser";

interface ManagementTemplateProps {
  paper?: boolean;
  noPadding?: boolean;
  children?: any;
}

export default function ManagementTemplate(props: ManagementTemplateProps) {
  const { context, setContext } = useGlobalContext();
  const breakpoint = useBreakpoint();

  return (
    <Stack height={1} bgcolor="background.paper">
      {!breakpoint.md && (
        <Drawer
          open={context.sidebar}
          onClose={() => setContext({ ...context, sidebar: false })}
          elevation={4}
          anchor="left"
          variant="temporary"
          sx={{
            transitionDuration: "200ms",
            width: context.sidebar ? sideBarWidth : 0,
            flexShrink: 0,
            [`> .MuiDrawer-Paper`]: {
              height: "100%",
              background: (theme) =>
                breakpoint.md ? theme.palette.background.paper : "transparent",
              borderRight: "none",
              position: "relative",
              width: sideBarWidth,
            },
          }}
        >
          <Box height={document.getElementById("g-topbar")?.clientHeight} />
          <SideBar />
        </Drawer>
      )}
      <TopBar />
      <Stack direction="row" flexGrow={1} overflow="hidden">
        {breakpoint.md && (
          <Box p={2} bgcolor="background.paper" height={1}>
            <Box
              height={1}
              width={context.sidebar ? sideBarWidth : undefined}
              display="flex"
              flexDirection="column"
              sx={{
                transitionDuration: "200ms",
                transitionTimingFunction: "ease-in-out",
              }}
            >
              <Paper
                sx={{
                  position: "relative",
                  bgcolor: "background.default",
                  height: 1,
                }}
              >
                <Paper
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 30,
                    width: 30,
                    borderRadius: "50%",
                    position: "absolute",
                    top: -10,
                    right: -10,
                    bgcolor: "background.default",
                    border: "1px solid var(--Color-Border, #E2E8F0)",
                    zIndex: 9,
                  }}
                  onClick={() =>
                    setContext({ ...context, sidebar: !context.sidebar })
                  }
                >
                  <KeyboardArrowRightIcon
                    sx={{
                      transition: "transform 200ms ease-in-out",
                      transform: !context.sidebar
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                    }}
                  />
                </Paper>
                <SideBar />
              </Paper>
            </Box>
          </Box>
        )}
        <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: (theme) => theme.palette.background.paper,
              width: 1,
              height: 1,
              pr: breakpoint.md ? 2 : 0,
            }}
          >
            <Box
              sx={{
                width: 1,
                height: 1,
                overflow: "auto",
                bgcolor: (theme) => theme.palette.background.paper,
                p: props.noPadding ? 0 : breakpoint.md ? 2 : 1,
              }}
            >
              <Routes>
                <Route path="dashboard" element={<DashboardPage />} />
                <Route path="storage">
                  <Route index element={<StoragePage />} />
                  <Route path="text-editor" element={<TextEditorPage />} />
                </Route>
                <Route path="ticket">
                  <Route index element={<TicketsPage />} />
                  <Route
                    path="create"
                    element={<CreateManagementTicketPage />}
                  />
                  <Route path=":_id" element={<ManageTicketPage />} />
                </Route>
                <Route path="department">
                  <Route index element={<DepartmentsPage />} />
                  <Route path=":_id" element={<EditDepartmentPage />} />
                </Route>
                <Route path="user">
                  <Route index element={<UsersPage />} />
                  <Route path=":_id" element={<EditUserPage />} />
                </Route>
                <Route path="logs" element={<LogsPage />} />
                <Route path="settings/*" element={<SettingsPage />} />
                <Route path="security" element={<SecurityPage />} />
              </Routes>
              {props.paper ? (
                <Paper
                  sx={{
                    bgcolor: "background.default",
                    height: 1,
                    overflow: "hidden",
                  }}
                >
                  {props.children}
                </Paper>
              ) : (
                props.children
              )}
            </Box>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}
