import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useMatch, useNavigate, useParams } from "react-router";
import { TicketService } from "../../../services/api";
import { JSX, useEffect, useState } from "react";
import { Ticket } from "../../../services/swagger";
import TicketResponse from "../../organisms/Ticket/TicketResponse";
import TicketInfo from "../../organisms/Ticket/TicketInfo";
import useRefresh from "../../../hooks/useRefresh";
import TicketDetails from "../../organisms/Ticket/TicketDetails";
import CreateTicketResponse from "../../organisms/Ticket/CreateTicketResponse";
import TicketTasks from "../../organisms/Ticket/TicketTasks";
import TicketServiceCall from "../../organisms/Ticket/TicketServiceCall";
import Authorized from "../../atoms/Authorized";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import TicketAssignees from "../../organisms/Ticket/TicketAssignees";
import useBreakpoint from "../../../hooks/useBreakpoint";
import TicketContact from "../../organisms/Ticket/TicketContact";

export default function ManageTicketPage() {
  const { t } = useTranslation("ManageTicketPage");
  const breakpoint = useBreakpoint();
  const match = useMatch("/:page/*");
  const navigate = useNavigate();
  const { _id } = useParams();
  const [ticket, setTicket] = useState<Ticket>();

  const loadTicket = async () => {
    if (!_id) return;
    const res = await TicketService.getTicket({ id: _id });
    if (res?.data?.data) setTicket(res.data.data);
  };

  useRefresh(loadTicket, "ticket");

  useEffect(() => {
    loadTicket();
  }, []);

  if (breakpoint.xl) {
    return (
      <Box
        bgcolor="background.paper"
        height={1}
        overflow="hidden"
        display="flex"
        justifyContent="center"
        px={match?.params.page === "my-tickets" ? 2 : undefined}
      >
        <Stack
          width="fit-content"
          maxWidth={1}
          spacing={2}
          py={match?.params.page === "my-tickets" ? 2 : undefined}
          height={1}
        >
          <Paper
            sx={{
              width: 1,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "background.default",
            }}
          >
            <Typography fontWeight={600}>
              {t("Ticket")} #{ticket?.id}
            </Typography>
            <IconButton onClick={() => navigate(-1)}>
              <CloseIcon />
            </IconButton>
          </Paper>
          <Box flexGrow={1} overflow="hidden">
            <Stack direction="row" spacing={2} height={1} pb={1}>
              <Stack
                spacing={2}
                minWidth={250}
                width={350}
                sx={{ overflowY: "auto" }}
                pb={1}
              >
                {ticket && <TicketContact ticket={ticket} />}
                <Authorized role="agent">
                  {ticket && <TicketAssignees ticket={ticket} />}
                  {ticket && <TicketTasks ticket={ticket} />}
                  {ticket && <TicketServiceCall ticket={ticket} />}
                </Authorized>
              </Stack>
              <Stack
                pb={1}
                spacing={2}
                flexGrow={1}
                minWidth={400}
                width={700}
                sx={{ overflowY: "auto" }}
              >
                <TicketDetails ticket={ticket} />
                <Divider />
                {ticket?.responses.map((response) => {
                  if (!ticket?._id) return null;
                  return (
                    <TicketResponse
                      key={response._id}
                      ticket={ticket}
                      response={response}
                    />
                  );
                })}
                {ticket?.id && (
                  <CreateTicketResponse ticketId={ticket.id.toString()} />
                )}
              </Stack>
              <Stack spacing={2} minWidth={310} width={350} pb={1}>
                {ticket && <TicketInfo ticket={ticket} />}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  } else if (breakpoint.lg) {
    return (
      <Box
        bgcolor="background.paper"
        height={1}
        overflow="hidden"
        display="flex"
        justifyContent="center"
        px={match?.params.page === "my-tickets" ? 2 : undefined}
      >
        <Stack
          width="fit-content"
          maxWidth={1}
          spacing={2}
          py={match?.params.page === "my-tickets" ? 2 : undefined}
          height={1}
        >
          <Paper
            sx={{
              width: 1,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "background.default",
            }}
          >
            <Typography fontWeight={600}>
              {t("Ticket")} #{ticket?.id}
            </Typography>
            <IconButton onClick={() => navigate(-1)}>
              <CloseIcon />
            </IconButton>
          </Paper>
          <Box flexGrow={1} overflow="hidden">
            <Stack direction="row" spacing={2} height={1} pb={1}>
              <Stack
                pb={1}
                spacing={2}
                flexGrow={1}
                minWidth={400}
                width={700}
                sx={{ overflowY: "auto" }}
              >
                <TicketDetails ticket={ticket} />
                <Divider />
                {ticket?.responses.map((response) => {
                  if (!ticket?._id) return null;
                  return (
                    <TicketResponse
                      key={response._id}
                      ticket={ticket}
                      response={response}
                    />
                  );
                })}
                {ticket?.id && (
                  <CreateTicketResponse ticketId={ticket.id.toString()} />
                )}
              </Stack>
              <Stack
                spacing={2}
                minWidth={310}
                width={350}
                pb={1}
                overflow="auto"
              >
                {ticket && <TicketInfo ticket={ticket} />}
                {ticket && <TicketContact ticket={ticket} />}
                <Authorized role="agent">
                  {ticket && <TicketAssignees ticket={ticket} />}
                  {ticket && <TicketTasks ticket={ticket} />}
                  {ticket && <TicketServiceCall ticket={ticket} />}
                </Authorized>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  } else {
    return (
      <Stack
        spacing={2}
        pb={2}
        pt={2}
        px={match?.params.page === "my-tickets" ? 1 : 0}
        bgcolor="background.paper"
      >
        <Paper
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            bgcolor: "background.default",
          }}
        >
          <Typography fontWeight={600}>
            {t("Ticket")} #{ticket?.id}
          </Typography>
          <IconButton onClick={() => navigate(-1)}>
            <CloseIcon />
          </IconButton>
        </Paper>
        <Stack spacing={2} height={1}>
          <TicketDetails ticket={ticket} />
          <Divider />
          {ticket?.responses.map((response) => {
            if (!ticket?._id) return null;
            return (
              <TicketResponse
                key={response._id}
                ticket={ticket}
                response={response}
              />
            );
          })}
          {ticket?.id && (
            <CreateTicketResponse ticketId={ticket.id.toString()} />
          )}
          {ticket && <TicketContact ticket={ticket} />}
          <Authorized role="agent">
            {ticket && <TicketAssignees ticket={ticket} />}
            {ticket && <TicketTasks ticket={ticket} />}
            {ticket && <TicketServiceCall ticket={ticket} />}
          </Authorized>
          {ticket && <TicketInfo ticket={ticket} />}
        </Stack>
      </Stack>
    );
  }
}
