import {
  Box,
  ButtonProps,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  OutputDTOQueryFilterTemplateArray,
  QueryFilterTemplate,
  SortDirection,
} from "../../services/swagger";
import {
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import { AxiosPromise } from "axios";
import FilterBox from "./FilterBox";
import { prepareFilterString } from "../../helpers/filters";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import useRefresh from "../../hooks/useRefresh";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { useSearchParams } from "react-router-dom";
import { objectToParamString, paramStringToObject } from "../../helpers/params";
import useBreakpoint from "../../hooks/useBreakpoint";
import DataTable, { DataTableHeader } from "./DataTable";
import CardList from "./CardList";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import AddButton from "../atoms/AddButton";

export interface QueryParameters {
  search: string;
  filters: string;
  skip: number;
  limit: number;
  sortField?: string;
  sortDirection?: SortDirection;
}

interface DataViewProps<T> {
  name: string;
  button?: Omit<ButtonProps, "sx"> & { text: string };
  headers: DataTableHeader[];
  items: T[];
  count: number;
  pagination?: boolean;
  renderItem: (item: T) => ReactNode;
  queryFunction?: () => AxiosPromise<OutputDTOQueryFilterTemplateArray>;
  onChange?: (query: QueryParameters) => void;
}

export default function DataView<T>(props: DataViewProps<T>) {
  const { t } = useTranslation("DataView");
  const [textPending, startTransition] = useTransition();
  const [searchParams, setSearchParams] = useSearchParams();
  const headerRef = useRef<any>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const searchInput = useRef<HTMLInputElement | null>(null);
  const [searchExpanded, setSearchExpenaded] = useState(
    Boolean(searchInput.current?.value || "")
  );
  const [anchorEl, setAnchorEl] = useState<any>();
  const breakpoint = useBreakpoint();

  const tableBreakpoint = breakpoint.lg;

  const sortField = useMemo(
    () => searchParams.get("sortField") || "",
    [searchParams]
  );
  const sortDirection = useMemo(
    () => searchParams.get("sortDirection") || SortDirection.None,
    [searchParams]
  );
  const page = useMemo(
    () => parseInt(searchParams.get("page") || "0"),
    [searchParams]
  );
  const rowsPerPage = useMemo(
    () => parseInt(searchParams.get("rowsPerPage") || "25"),
    [searchParams]
  );

  const [filterTemplate, setFilterTemplate] = useState<QueryFilterTemplate[]>(
    []
  );

  const filters = useMemo(
    () =>
      paramStringToObject(
        searchParams.get("filters") || objectToParamString([])
      ),
    [searchParams]
  );

  const [isLoadable, setIsLoadable] = useState<boolean>(
    !Boolean(props.queryFunction)
  );

  const loadQuery = async () => {
    const res = await props.queryFunction?.();
    if (res?.data?.data) {
      setFilterTemplate(res.data.data);
      if (!sortField) {
        for (const template of res.data.data) {
          if (template.defaultSortDirection) {
            setSearchParams(
              (sp) => {
                sp.set("sortField", template.field);
                if (template.defaultSortDirection)
                  sp.set("sortDirection", template.defaultSortDirection);
                return sp;
              },
              { replace: true }
            );
            break;
          }
        }
      }
    }
    setIsLoadable(true);
  };

  const handleClickFilterButton = () => {
    setAnchorEl(!Boolean(anchorEl) ? headerRef.current : null);
  };

  const handleCloseFilters = () => {
    setAnchorEl(null);
  };

  const handleSearch = (searchStr: string) => {
    startTransition(() => {
      setSearchParams((sp) => {
        sp.set("search", searchStr);
        return sp;
      });
    });
  };

  useEffect(() => {
    loadQuery();
  }, []);

  useDidMountEffect(() => {
    if (props.count < page * rowsPerPage) {
      setSearchParams((sp) => {
        sp.set("page", "0");
        return sp;
      });
    }
  }, [props.count]);

  const handleChange = () => {
    if (!isLoadable) {
      return;
    }
    if (filterTemplate.length > 0 && searchParams.size === 0) {
      for (const template of filterTemplate) {
        if (template.defaultSortDirection) {
          setSearchParams(
            (sp) => {
              sp.set("sortField", template.field);
              if (template.defaultSortDirection)
                sp.set("sortDirection", template.defaultSortDirection);
              return sp;
            },
            { replace: true }
          );
          return;
        }
      }
    }
    props.onChange?.({
      search: searchInput.current?.value || "",
      filters: prepareFilterString(filters),
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      sortDirection: sortDirection as SortDirection,
      sortField,
    });
  };

  useRefresh(handleChange);

  useDidMountEffect(() => {
    const newSearch = searchParams.get("search") || "";
    if (searchInput.current) searchInput.current.value = newSearch;
    handleChange();
  }, [searchParams, isLoadable]);

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Box
        ref={headerRef}
        component={Paper}
        sx={{
          p: 1.5,
          mb: 1,
          bgcolor: (theme) => theme.palette.background.default,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: (theme) => theme.shape.borderRadius / 8,
        }}
      >
        <InputBase
          ref={searchRef}
          inputRef={searchInput}
          sx={{
            borderRadius: (theme) => theme.shape.borderRadius / 8,
            bgcolor: (theme) =>
              searchExpanded ? theme.palette.background.paper : "none",
            px: 1,
            py: 1,
            mr: 1,
            flexGrow: 1,
            transitionDuration: "50ms",
            transitionTimingFunction: "ease-in-out",
          }}
          onFocus={(e) => {
            if (searchRef.current) setSearchExpenaded(true);
          }}
          onBlur={(e) => {
            if (searchRef.current && !searchInput.current?.value)
              setSearchExpenaded(false);
          }}
          onChange={() => {
            handleSearch(searchInput.current?.value || "");
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     e.preventDefault();
          //     if (!breakpoint.md) e.currentTarget.blur();
          //     handleSearch(searchInput.current?.value || "");
          //   }
          // }}
          placeholder={t("Search", { topic: props.name })}
          endAdornment={
            <IconButton size="small">
              <SearchIcon
                onClick={() => handleSearch(searchInput.current?.value || "")}
              />
            </IconButton>
          }
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {Boolean(props.queryFunction) && (
            <Tooltip placement="top" title={t("Filters")}>
              <IconButton onClick={handleClickFilterButton}>
                {Boolean(anchorEl) ? (
                  <FilterAltOffIcon
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <FilterAltIcon
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}
          {props.button && (breakpoint.md || !searchExpanded) && (
            <AddButton
              tooltip={t("Add")}
              onClick={props.button.onClick}
              boxProps={{ ml: 1 }}
            />
          )}
        </Box>
      </Box>
      {Boolean(props.queryFunction) && (
        <Box>
          <FilterBox
            open={Boolean(anchorEl)}
            onClose={handleCloseFilters}
            filterTemplate={filterTemplate}
            onChange={(newFilters) =>
              setSearchParams((sp) => {
                sp.set("filters", objectToParamString(newFilters));
                return sp;
              })
            }
          />
        </Box>
      )}
      {tableBreakpoint ? (
        <DataTable
          content={{
            count: props.count,
            docs: props.items,
          }}
          filterTemplate={filterTemplate}
          headers={props.headers}
          pagination={props.pagination}
        />
      ) : (
        <CardList
          count={props.count}
          items={props.items}
          renderItem={props.renderItem}
        />
      )}
    </Box>
  );
}
