import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { ServiceCall } from "../../../services/swagger";
import { useEffect, useState } from "react";
import FormTemplate from "../../templates/FormTemplate";
import { formatMilliseconds } from "../../../helpers/date";
import { SapService } from "../../../services/api";
import { useTranslation } from "react-i18next";

interface TicketOldServiceReportDialogProps {
  serviceCall: ServiceCall;
}

export default function TicketOldServiceReportDialog(
  props: TicketOldServiceReportDialogProps
) {
  const { t } = useTranslation("Ticket");
  const { serviceCall } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [employees, setEmployees] = useState<any[]>([]);

  const loadEmployees = async () => {
    const res = await SapService.getEmployees();
    if (!res?.data?.data) return;
    setEmployees(res.data.data);
  };

  useEffect(() => {
    if (!props) return;
    loadEmployees();
  }, [open]);

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <FormTemplate
          title={`Service Call # ${serviceCall.call_id}`}
          onCancel={handleClose}
          cancelButtonText="Close"
          disableSubmitButton
        >
          <Stack spacing={2}>
            {serviceCall.expenses.length > 0 && (
              <>
                <Box>
                  <Typography fontWeight="bold">{t("Expenses")}</Typography>
                </Box>
                {serviceCall.expenses.map((expense, i) => {
                  return (
                    <Box>
                      <Stack spacing={1}>
                        <Typography fontWeight="bold">#{i + 1}</Typography>
                        <Box>
                          <Typography fontWeight="bold">
                            {t("ItemNo")}
                          </Typography>
                          <Typography>{expense.item_no}</Typography>
                        </Box>
                        <Box>
                          <Typography fontWeight="bold">
                            {t("SerialNo")}
                          </Typography>
                          <Typography>{expense.serial_no}</Typography>
                        </Box>
                        <Box>
                          <Typography fontWeight="bold">
                            {t("Quantity")}
                          </Typography>
                          <Typography>{expense.quantity}</Typography>
                        </Box>
                        {expense.description && (
                          <Typography variant="caption">
                            {t("Description")}: {expense.description}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  );
                })}
              </>
            )}
            {serviceCall.times.length > 0 && (
              <>
                <Box>
                  <Typography fontWeight="bold">{t("Times")}</Typography>
                </Box>
                {serviceCall.times.map((time, i) => {
                  return (
                    <Box>
                      <Stack spacing={1}>
                        <Typography fontWeight="bold">#{i + 1}</Typography>
                        <Typography>
                          {new Date(time.start).toLocaleString("tr")} -{" "}
                          {new Date(time.end).toLocaleString("tr")}
                        </Typography>
                        <Typography>
                          {t("Duration")}:{" "}
                          {formatMilliseconds(
                            new Date(time.end).getTime() -
                              new Date(time.start).getTime(),
                            { ignoreMilliseconds: true }
                          )}
                        </Typography>
                        {time.remarks && (
                          <Typography variant="caption">
                            {t("Remarks")}: {time.remarks}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  );
                })}
              </>
            )}
            {serviceCall.workers.length > 0 && (
              <>
                <Box>
                  <Stack spacing={2}>
                    <Box>
                      <Typography fontWeight="bold">{t("Workers")}</Typography>
                    </Box>
                    {serviceCall.workers.map((e) => {
                      const employee = employees.find((emp) => emp.empID === e);
                      if (!employee) return null;
                      return (
                        <Typography>
                          {employee.firstName} {employee.lastName}
                        </Typography>
                      );
                    })}
                  </Stack>
                </Box>
              </>
            )}
          </Stack>
        </FormTemplate>
      </Dialog>
      <Button fullWidth variant="outlined" onClick={() => setOpen(true)}>
        {t("View")}
      </Button>
    </Box>
  );
}
