import { Box, InputBase, Paper, Stack, Typography } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import ClearIcon from "@mui/icons-material/Clear";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { reorder } from "../../helpers/list";
import useConfirm from "../../hooks/useConfirm";
import AddIcon from "@mui/icons-material/Add";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown, { DropDownItem } from "./Dropdown";

interface ReorderableListProps {
  onChange: (list: string[]) => void;
  onAdd?: (list: string[], addedItem: string) => void;
  onDelete?: (list: string[], deletedItem: string) => void;
  renderItem?: (value: string) => ReactNode;
  addList?: DropDownItem[];
  confirmDeletion?: boolean;
  confirmAddition?: boolean;
  items: string[];
  labelFontSize?: number;
  label?: string;
  disabled?: boolean;
  unique?: boolean;
  disableReorder?: boolean;
}

export default function ReorderableList(props: ReorderableListProps) {
  const { t } = useTranslation("ReorderableList");
  const { confirm, ConfirmDialog } = useConfirm();
  const [newValue, setNewValue] = useState("");

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    const newItems = reorder(
      props.items,
      result.source.index,
      result.destination.index
    );

    props.onChange(newItems);
  };

  const handleAdd = () => {
    if (!newValue) return;
    const confirmAdd = () => {
      if (props.unique && props.items.includes(newValue)) return;
      const newList = [...props.items, newValue];
      props.onChange(newList);
      props.onAdd?.(newList, newValue);
      setNewValue("");
    };
    if (props.confirmAddition) confirm(confirmAdd);
    else confirmAdd();
  };

  const handleDelete = (index: number) => {
    const confirmDelete = () => {
      const newArr = [...props.items];
      const item = newArr[index];
      newArr.splice(index, 1);
      props.onChange(newArr);
      props.onDelete?.(newArr, item);
    };
    if (props.confirmDeletion) confirm(confirmDelete);
    else confirmDelete();
  };

  return (
    <Stack spacing={1}>
      <ConfirmDialog />
      {props.label && (
        <Typography
          fontWeight={400}
          fontSize={props.labelFontSize ?? 16}
          color="primary"
          gutterBottom
        >
          {props.label}
        </Typography>
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId="droppable-list"
          isDropDisabled={Boolean(props.disabled)}
          direction="vertical"
          ignoreContainerClipping
          isCombineEnabled={false}
        >
          {(provided) => (
            <Stack
              spacing={1}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {props.items.map((item: string, index: number) => (
                <Draggable
                  draggableId={item}
                  index={index}
                  key={item}
                  isDragDisabled={props.disabled || props.disableReorder}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      component={Paper}
                      sx={
                        snapshot.isDragging
                          ? { bgcolor: "background.paper" }
                          : {
                              ":hover": {
                                bgcolor: "background.paper",
                              },
                            }
                      }
                    >
                      <Stack
                        p={2}
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        {!props.disableReorder && (
                          <DragHandleIcon color="disabled" />
                        )}
                        <Typography sx={{ flexGrow: 1 }}>
                          {props.renderItem ? props.renderItem?.(item) : item}
                        </Typography>
                        {!props.disabled && (
                          <ClearIcon
                            onClick={() => handleDelete(index) as any}
                            color="disabled"
                            sx={{ cursor: "pointer" }}
                          />
                        )}
                      </Stack>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <Stack
        component={Paper}
        p={1}
        pr={2}
        spacing={props.addList ? 0 : 2}
        direction="row"
        alignItems="center"
      >
        {props.addList ? (
          <Dropdown
            disabled={props.disabled}
            items={props.addList}
            sx={{
              flexGrow: 1,
              ".MuiInputBase-root": {
                flexGrow: 1,
                p: 1,
                bgcolor: "transparent",
              },
            }}
            onChange={(e, item?: DropDownItem) => {
              setNewValue((item?.value as string) || "");
            }}
            value={props.addList?.find((e) => e.value === newValue) || ""}
            placeholder={t("NewPlaceholder")}
          />
        ) : (
          <InputBase
            disabled={props.disabled}
            placeholder={t("NewPlaceholder")}
            size="small"
            sx={{ p: 1, flexGrow: 1 }}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        )}
        {!props.disabled && (
          <AddIcon
            onClick={handleAdd as any}
            color={newValue ? "primary" : "disabled"}
            sx={{ cursor: "pointer" }}
          />
        )}
      </Stack>
    </Stack>
  );
}
