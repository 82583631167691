import { useEffect, useState } from "react";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { Box, Grid, Stack } from "@mui/material";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";
import { DropDownItem } from "../../molecules/Dropdown";
import { capitalizeFirstLetter } from "../../../helpers/string";
import { useTranslation } from "react-i18next";

export default function MessagesSettingsPage() {
  const { t } = useTranslation("Settings");
  const { context } = useGlobalContext();
  const refresh = useRefresh();
  const [emailConfigName, setEmailConfigName] = useState<string | undefined>();
  const [configNames, setConfigNames] = useState<DropDownItem[]>([]);
  const [subject, setSubject] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [from, setFrom] = useState("");
  const [direct, setDirect] = useState("");
  const [forward, setForward] = useState("");

  const handleSubmit = async () => {
    if (!context.settings) return;
    await SettingsService.updateSettings({
      appSettings: {
        ...context.settings,
      },
    });
    refresh();
  };

  useEffect(() => {
    if (!context.settings || !emailConfigName) return;
    const section = emailConfigName.split(".")[0];
    const subsection = emailConfigName.split(".")[1];
    if (!section || !subsection) return;
    if (
      typeof (context.settings.messages as any)[section][subsection].enabled !==
        "boolean" ||
      typeof (context.settings.messages as any)[section][subsection].direct !==
        "string" ||
      typeof (context.settings.messages as any)[section][subsection].forward !==
        "string" ||
      typeof (context.settings.messages as any)[section][subsection].from !==
        "string" ||
      typeof (context.settings.messages as any)[section][subsection].subject !==
        "string"
    )
      return;
    (context.settings.messages as any)[section][subsection].enabled = enabled;
    (context.settings.messages as any)[section][subsection].direct = direct;
    (context.settings.messages as any)[section][subsection].forward = forward;
    (context.settings.messages as any)[section][subsection].from = from;
    (context.settings.messages as any)[section][subsection].subject = subject;
  }, [direct, forward, from, subject, enabled]);

  useEffect(() => {
    if (!context.settings || !emailConfigName) return;
    const section = emailConfigName.split(".")[0];
    const subsection = emailConfigName.split(".")[1];
    if (!section || !subsection) return;
    if (
      typeof (context.settings.messages as any)[section][subsection].enabled !==
        "boolean" ||
      typeof (context.settings.messages as any)[section][subsection].direct !==
        "string" ||
      typeof (context.settings.messages as any)[section][subsection].forward !==
        "string" ||
      typeof (context.settings.messages as any)[section][subsection].from !==
        "string" ||
      typeof (context.settings.messages as any)[section][subsection].subject !==
        "string"
    )
      return;
    setEnabled((context.settings.messages as any)[section][subsection].enabled);
    setDirect((context.settings.messages as any)[section][subsection].direct);
    setForward((context.settings.messages as any)[section][subsection].forward);
    setFrom((context.settings.messages as any)[section][subsection].from);
    setSubject((context.settings.messages as any)[section][subsection].subject);
  }, [emailConfigName]);

  useEffect(() => {
    if (!context.settings) return;
    const arr: DropDownItem[] = [];
    for (const sectionKey of Object.keys(context.settings.messages)) {
      const subsections = (context.settings.messages as any)[sectionKey];
      for (const subsectionKey of Object.keys(subsections)) {
        arr.push({
          name: `${capitalizeFirstLetter(sectionKey)} ${subsectionKey
            .split(/(?=[A-Z])/)
            .map((e) => capitalizeFirstLetter(e))
            .join(" ")}`,
          value: `${sectionKey}.${subsectionKey}`,
        });
      }
    }
    setConfigNames(arr);
  }, [context.settings]);

  return (
    <FormTemplate
      title={t("Mail")}
      disableHeaderCloseButton
      disableCancelButton
      submitButtonText={t("Save")}
      disableEnter
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <FormInput
          field="emailConfigName"
          label={t("EmailConfigurationName")}
          type="list"
          placeholder="None"
          value={emailConfigName}
          list={configNames}
          onChange={(value) => setEmailConfigName(value as string)}
        />
        <FormInput
          field="enabled"
          label={t("Enabled")}
          type="boolean"
          value={enabled}
          onChange={(value) => setEnabled(value as boolean)}
        />
        <FormInput
          field="subject"
          label={t("Subject")}
          placeholder={t("SubjectPlaceholder")}
          type="string"
          required
          value={subject}
          onChange={(value) => setSubject(value as string)}
        />
        <FormInput
          field="from"
          label={t("FromEmail")}
          placeholder={t("FromEmailPlaceholder")}
          type="string"
          value={from}
          onChange={(value) => setFrom(value as string)}
        />
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                field="forward"
                label={t("Forward")}
                placeholder={t("ForwardPlaceholder")}
                multiline
                type="string"
                minRows={Math.max(
                  5,
                  direct.split("\n").length,
                  forward.split("\n").length
                )}
                maxRows={15}
                value={forward}
                onChange={(value) => setForward(value as string)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                field="direct"
                label={t("Direct")}
                placeholder={t("DirectPlaceholder")}
                multiline
                type="string"
                minRows={Math.max(
                  5,
                  direct.split("\n").length,
                  forward.split("\n").length
                )}
                maxRows={15}
                value={direct}
                onChange={(value) => setDirect(value as string)}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </FormTemplate>
  );
}
