import settings from "../settings";

interface DownloadOptions {
  disableForceDownload?: boolean;
  mimeType?: string;
}

export function getDownloadSrc(
  path: string,
  ticketId?: string,
  options?: DownloadOptions
): string {
  let str = `${settings.baseUrl}/api/ftp/file?path=${encodeURIComponent(
    path
  )}&authorization=${encodeURIComponent(
    localStorage.getItem("authToken") || ""
  )}&ticketId=${ticketId}`;
  if (options?.disableForceDownload) str += `&disableForceDownload=true`;
  if (options?.mimeType) str += `&mimeType=${options.mimeType}`;
  return str;
}
