import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import MainTemplate from "../templates/MainTemplate";
import { useNavigate } from "react-router";
import { useGlobalContext } from "../../contexts/Global";
import { capitalizeFirstLetter } from "../../helpers/string";
import useBreakpoint from "../../hooks/useBreakpoint";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import FormInput from "../molecules/FormInput";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { UserService } from "../../services/api";
import QRButton from "../organisms/QRButton";
import { useTranslation } from "react-i18next";

export default function AccountInfoPage() {
  const { t } = useTranslation("AccountInfoPage");
  const breakpoint = useBreakpoint();
  const { context, setContext } = useGlobalContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = context;
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState<string>(user?.phone || "");
  const [phone, setPhone] = useState<string>(user?.phone || "");
  const [company, setCompany] = useState<string>(user?.company || "");
  const [otpEnabled, setOtpEnabled] = useState<boolean>(
    user?.otpEnabled || false
  );
  const [emailConfirmation, setEmailConfirmation] = useState<boolean>(
    user?.emailConfirmation || false
  );

  useEffect(() => {
    if (!user) return;
    setName(user.name);
    setPhone(user.phone);
    setCompany(user.company);
    setOtpEnabled(user.otpEnabled);
    setEmailConfirmation(user.emailConfirmation);
  }, [user, editMode]);

  const handleUpdateInfo = async () => {
    const res = await UserService.updateMyUser({
      updateMyUserBody: {
        company,
        name,
        phone,
        emailConfirmation,
        otpEnabled,
      },
    });
    if (!res?.data?.success) return;
    setEditMode(false);
    setContext({ ...context, user: res.data.data });
  };

  const handleLoadMyQr = async () => {
    const res = await UserService.getMyQR();
    return res?.data?.data;
  };

  return (
    <MainTemplate>
      <Box
        bgcolor="background.paper"
        display="flex"
        justifyContent="center"
        p={breakpoint.md ? 4 : 2}
        height={1}
        boxSizing="border-box"
        width={1}
      >
        <Paper sx={{ bgcolor: "background.default", py: 4 }}>
          <Box
            mb={2}
            px={6}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant={breakpoint.md ? "h3" : "h6"}
              whiteSpace="initial"
            >
              {t("AccountInfo")}
            </Typography>
            {editMode ? (
              <Stack direction="row" spacing={1}>
                <IconButton
                  sx={{ height: 50, width: 50 }}
                  onClick={handleUpdateInfo}
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  sx={{ height: 50, width: 50 }}
                  onClick={() => setEditMode(false)}
                  color="error"
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            ) : (
              <IconButton
                sx={{ height: 50, width: 50 }}
                onClick={() => setEditMode(true)}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>

          <TableContainer
            sx={{
              minWidth: { xs: 1, md: 600 },
              px: 4,
            }}
          >
            <Table sx={{ td: { borderBottom: "none" } }}>
              <TableHead></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>{t("Name")}</TableCell>
                  <TableCell>
                    {editMode ? (
                      <FormInput
                        field="name"
                        value={name}
                        onChange={(v) => setName(v as string)}
                        label=""
                        type="string"
                        rows={1}
                      />
                    ) : (
                      <Typography variant="body2">{user?.name}</Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {t("Username")}
                  </TableCell>
                  <TableCell>{user?.username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {t("Email")}
                  </TableCell>
                  <TableCell>{user?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {t("Phone")}
                  </TableCell>
                  <TableCell>
                    {editMode ? (
                      <FormInput
                        field="phone"
                        value={phone}
                        onChange={(x) => setPhone(x as string)}
                        label=""
                        type="string"
                        rows={1}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        color={!user?.phone ? "text.secondary" : undefined}
                      >
                        {user?.phone || "N/A"}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {t("Company")}
                  </TableCell>
                  <TableCell>
                    {editMode ? (
                      <FormInput
                        field="company"
                        value={company}
                        onChange={(x) => setCompany(x as string)}
                        label=""
                        type="string"
                        rows={1}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        color={!user?.company ? "text.secondary" : undefined}
                      >
                        {user?.company || "N/A"}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>{t("Role")}</TableCell>
                  <TableCell>
                    <Chip label={capitalizeFirstLetter(user?.role || "")} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", borderBottom: "none" }}>
                    {t("AccountStatus")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: user?.active
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                      borderBottom: "none",
                    }}
                  >
                    {user?.active ? t("Activated") : t("Inactive")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "large",
                      borderBottom: "none",
                    }}
                  >
                    {t("MultiFactorAuthentication")}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", borderBottom: "none" }}>
                    {t("Email")}
                  </TableCell>
                  <TableCell>
                    <FormInput
                      field="emailConfirmation"
                      value={emailConfirmation}
                      onChange={(x) => setEmailConfirmation(x as boolean)}
                      label=""
                      type="switch"
                      disabled={!editMode}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", borderBottom: "none" }}>
                    {t("Authenticator")}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Box width={50}>
                        <FormInput
                          field="otpEnabled"
                          value={otpEnabled}
                          onChange={(x) => setOtpEnabled(x as boolean)}
                          label=""
                          type="switch"
                          disabled={!editMode || user?.role !== "user"}
                        />
                      </Box>
                      <QRButton
                        srcFunction={handleLoadMyQr}
                        disabled={!otpEnabled}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box px={6}>
            <Typography fontWeight="bold">
              {t("ChangeYourPassword")}
              <Typography
                ml={1}
                component="span"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("../forgot-password")}
              >
                {t("ClickHere")}
              </Typography>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </MainTemplate>
  );
}
