import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { englishTranslations } from "../locales/en";
import { turkishTranslations } from "../locales/tr";

i18n.use(initReactI18next).init({
  resources: {
    en: englishTranslations,
    tr: turkishTranslations,
  },
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
