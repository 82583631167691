import axios from "axios";
import { toast } from "react-toastify";
import { OutputDTO } from "./swagger";
import { getBrowserId } from "../helpers/browser";

export function setupInterceptors() {
  axios.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "authToken"
    )}`;
    config.headers["Accept-Language"] =
      localStorage.getItem("language") || "en";
    config.headers["browser-id"] = getBrowserId();
    return config;
  });

  axios.interceptors.response.use(
    (res) => {
      if (res?.data?.success && res?.data?.message) {
        toast(res.data.message, { type: "success" });
      }
      return res;
    },
    (err) => {
      if (err?.response?.data) {
        const data = err.response.data as OutputDTO;
        if (data.data?.otpReset) {
          localStorage.removeItem("authToken");
          window.location.href = window.location.origin;
        }
        if (data.error?.message && !data.error?.ignore)
          toast(data.error.message, { type: "error" });
      } else toast("No Connection!", { type: "error" });
    }
  );
}
