import {
  Box,
  ButtonBase,
  Divider,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useGlobalContext } from "../../contexts/Global";
import useBreakpoint from "../../hooks/useBreakpoint";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import Authorized from "../atoms/Authorized";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AvatarWithInitials from "../atoms/AvatarWithInitials";
import { useRef, useState } from "react";
import AccountPopover from "../molecules/AccountPopover";
import Unauthorized from "../atoms/Unauthorized";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import MainSideBarButton from "../molecules/MainSideBarButton";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useTranslation } from "react-i18next";

export const sideBarWidth = 260;

function MainSideBarContent() {
  const { t } = useTranslation("Navigation");
  return (
    <Stack spacing={1} sx={{ p: 2 }}>
      <MainSideBarButton icon={<HomeIcon />} value={t("Home")} path="/home" />
      <Unauthorized>
        <MainSideBarButton
          icon={<PersonIcon />}
          value={t("Login")}
          path="/login"
        />
        <MainSideBarButton
          icon={<PersonAddAlt1Icon />}
          value={t("Register")}
          path="/register"
        />
      </Unauthorized>
      <Authorized role="user">
        <MainSideBarButton
          icon={<ConfirmationNumberIcon />}
          value={t("MyTickets")}
          path="/my-tickets"
        />
      </Authorized>
      <Authorized role="agent">
        <MainSideBarButton
          icon={<SettingsIcon />}
          value={t("Management")}
          path="/m/dashboard"
        />
      </Authorized>
    </Stack>
  );
}

export default function MainSideBar() {
  const { context, setContext } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<any>(null);
  const handleClosePopover = () => {
    setOpen(false);
    setContext({ ...context, sidebar: false });
  };
  return (
    <>
      <Box
        height={1}
        width={context.sidebar ? sideBarWidth : undefined}
        display="flex"
        flexDirection="column"
        sx={{ transitionDuration: "200ms" }}
      >
        <Toolbar sx={{ height: 50 + 32 }} />
        <Paper
          sx={{
            position: "relative",
            bgcolor: "background.default",
            flexGrow: 1,
            width: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box flexGrow={1}>
            <MainSideBarContent />
          </Box>
          <Authorized role="user">
            <Box ref={anchorEl}>
              <Divider />
              <ButtonBase
                onClick={() => setOpen(true)}
                sx={{
                  p: 2,
                  width: 1,
                  justifyContent: context.sidebar ? "space-between" : undefined,
                }}
              >
                <Stack spacing={2} direction="row" alignItems="center">
                  <AvatarWithInitials size={36}>
                    {context.user?.name || ""}
                  </AvatarWithInitials>
                  {context.sidebar && (
                    <Box textAlign="left">
                      <Typography fontSize={16} fontWeight="bold">
                        {context?.user?.username}
                      </Typography>
                      <Typography fontWeight="light" fontSize={15}>
                        {context?.user?.role}
                      </Typography>
                    </Box>
                  )}
                </Stack>
                {context.sidebar && (
                  <KeyboardArrowRightIcon
                    sx={{ fontSize: 30, color: "text.secondary" }}
                  />
                )}
              </ButtonBase>
            </Box>
          </Authorized>
        </Paper>
      </Box>
      <AccountPopover
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </>
  );
}
