import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import AuthStateProvider from "./contexts/Global";
import { setupInterceptors } from "./services/interceptors";

const root = createRoot(document.getElementById("root") as HTMLElement);

setupInterceptors();

root.render(
  <AuthStateProvider>
    <App />
  </AuthStateProvider>
);
