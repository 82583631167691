import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  IconProps,
  Menu,
  MenuItem,
} from "@mui/material";
import { FilterValueType, Operator } from "../../services/swagger";
import { allowedOperators } from "../molecules/FilterInput";
import { useEffect, useState } from "react";

interface OperatorSelectorButtonProps {
  type: FilterValueType;
  boxProps?: BoxProps;
  iconButtonProps?: IconButtonProps;
  avatarProps?: AvatarProps;
  iconProps?: IconProps;
  size?: number;
  onChange: (operator: Operator) => void;
}

const operatorSymbol: { [key in Operator]: string } = {
  eq: "=",
  gt: ">",
  lt: "<",
  ne: "≠",
  gte: "≥",
  lte: "≤",
  regex: "~",
};

export default function OperatorSelectorButton(
  props: OperatorSelectorButtonProps
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [operator, setOperator] = useState<Operator>(
    allowedOperators[props.type][0]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeOperator = (newOperator: Operator) => {
    setOperator(newOperator);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    props.onChange(operator);
  }, [operator]);

  return (
    <Box {...props.boxProps}>
      <Menu
        anchorEl={anchorEl}
        elevation={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl !== null)}
        onClose={handleClose}
      >
        {allowedOperators[props.type].map((operator) => {
          return (
            <MenuItem
              key={operator}
              onClick={() => handleChangeOperator(operator)}
              disableRipple
            >
              {operatorSymbol[operator]}
            </MenuItem>
          );
        })}
      </Menu>
      <IconButton
        onClick={handleClick}
        sx={{ p: 0 }}
        {...props.iconButtonProps}
      >
        <Avatar
          color="error"
          variant="rounded"
          sx={{
            bgcolor: (theme) => theme.palette.primary.light,
            height: props.size ?? 25,
            width: props.size ?? 25,
          }}
          {...props.avatarProps}
        >
          {operatorSymbol[operator]}
        </Avatar>
      </IconButton>
    </Box>
  );
}
