import { Box, Grid, Stack, Typography } from "@mui/material";
import OfficeMap from "../atoms/OfficeMap";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FollowUs from "../molecules/FollowUs";
import appConfig from "../../config/app-config";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function Footer() {
  const { t } = useTranslation("Footer");
  return (
    <Grid
      container
      sx={{
        background: "#343434F2",
        bottom: 0,
        color: (theme) => theme.palette.primary.contrastText,
      }}
    >
      <Grid item xs={12} md={3} p={4}>
        <Typography gutterBottom variant="h6">
          {t("WhatWeDo")}
        </Typography>
        <Typography variant="body2" fontWeight={200}>
          {t("WhatWeDoBody")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} p={4}>
        <Typography gutterBottom variant="h6">
          {t("Contact")}
        </Typography>
        <Stack direction={"column"} spacing={1}>
          <Stack direction={"row"}>
            <EmailIcon sx={{ mr: 2 }} />
            <Typography flexGrow={1} fontWeight={200}>
              {appConfig.contact.email}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <PhoneIcon sx={{ mr: 2 }} />
            <Typography flexGrow={1} fontWeight={200}>
              {appConfig.contact.phone}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            component="a"
            href={`https://wa.me/${appConfig.contact.whatsapp}`}
            target="_blank"
            sx={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <WhatsAppIcon sx={{ mr: 2 }} />
            <Typography maxWidth={180} fontWeight={200}>
              {appConfig.contact.whatsappLabel}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <PlaceIcon sx={{ mr: 2 }} />
            <Typography maxWidth={180} fontWeight={200}>
              {appConfig.contact.address}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} md={3} p={4}>
        <Typography gutterBottom variant="h6">
          {t("FollowUs")}
        </Typography>
        <Box display="flex">
          <FollowUs />
        </Box>
      </Grid>
      <Grid item xs={12} md={3} p={4}>
        <OfficeMap />
      </Grid>
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          background: "#343434",
          p: 2,
          color: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} spacing={1}>
          <Typography sx={{ fontWeight: 300 }}>
            {t("Copyright")} © {new Date().getFullYear()}
          </Typography>
          <Typography
            component="a"
            sx={{
              textDecoration: "none",
              color: (theme) => theme.palette.primary.light,
              fontWeight: 300,
            }}
            target="_blank"
            href={appConfig.links.website.link}
          >
            {appConfig.links.website.label}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
