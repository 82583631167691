import MainTemplate from "../templates/MainTemplate";
import LoginForm from "../organisms/LoginForm";
import { Box } from "@mui/material";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGlobalContext } from "../../contexts/Global";

export default function LoginPage() {
  const breakpoint = useBreakpoint();
  const { context } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (context.user?.active) navigate("/home");
  }, [context]);

  return (
    <MainTemplate>
      <Box
        py={8}
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <img
          style={{
            position: "absolute",
            height: 1200,
            width: 1200,
            left: -600,
            bottom: -200,
          }}
          src={require("../../assets/grey-circles.png")}
        />
        <Box ml={breakpoint.md ? 20 : 0} zIndex={2} position="relative">
          {breakpoint.md && (
            <img
              style={{
                position: "absolute",
                height: 500,
                width: 340,
                objectFit: "cover",
                left: -300,
                zIndex: 3,
              }}
              src={require("../../assets/guy-sitting.png")}
            />
          )}
          <LoginForm />
        </Box>
      </Box>
    </MainTemplate>
  );
}
