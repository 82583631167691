import { Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { DropDownItem } from "../molecules/Dropdown";
import { FirewallService } from "../../services/api";
import { CreateRecordBodyTypeEnum } from "../../services/swagger";
import FormInput from "../molecules/FormInput";
import useRefresh from "../../hooks/useRefresh";
import FormTemplate from "../templates/FormTemplate";
import useEnums from "../../hooks/useEnums";
import { useTranslation } from "react-i18next";

interface CreateRecordDialogProps {
  open: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
}

export default function CreateRecordDialog(props: CreateRecordDialogProps) {
  const { t } = useTranslation("CreateRecordDialog");
  const refresh = useRefresh();
  const { recordTypeDropDownItems } = useEnums();
  const [ip, setIP] = useState("");
  const [subnet, setSubnet] = useState("");
  const [type, setType] = useState<CreateRecordBodyTypeEnum>("blacklist");

  useEffect(() => {
    if (!props.open) return;
    setIP("");
    setSubnet("");
    setType("blacklist");
  }, [props.open]);

  const handleSubmit = async () => {
    const res = await FirewallService.createRecord({
      createRecordBody: {
        ip,
        subnet,
        type,
      },
    });
    if (res?.data?.success) {
      refresh();
      props.onClose?.();
    }
  };
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormTemplate
        title={t("CreateRecord")}
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      >
        <Stack spacing={2}>
          <FormInput
            field="type"
            label={t("Type")}
            onChange={(v) => {
              setType(v as CreateRecordBodyTypeEnum);
            }}
            type="list"
            required
            value={type}
            list={recordTypeDropDownItems}
          />
          <FormInput
            label={t("IP.Label")}
            value={ip}
            placeholder={t("IP.Placeholder")}
            required
            field="ip"
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setIP(v);
            }}
          />
          <FormInput
            label={t("Subnet.Label")}
            field="subnet"
            type="string"
            value={subnet}
            placeholder={t("Subnet.Placeholder")}
            required
            onChange={(v) => {
              if (typeof v === "string") setSubnet(v);
            }}
          />
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}
