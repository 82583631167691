import { Box, Grid } from "@mui/material";
import Attachment from "../atoms/Attachment";
import { Attachment as ResponseAttachment } from "../../services/swagger";

interface AttachmentListProps {
  ticketId?: string;
  files?: File[];
  attachments?: ResponseAttachment[];
  sources?: string[];
  upload?: boolean;
}

export default function AttachmentList(props: AttachmentListProps) {
  return (
    <Box>
      <Grid container spacing={2}>
        {props.files?.map((e) => {
          return (
            <Grid key={e.name} item xs={6} md={4} lg={3} xl={2}>
              <Box textAlign="center">
                <Attachment upload={props.upload} file={e} />
              </Box>
            </Grid>
          );
        })}
        {props.attachments?.map((e) => {
          return (
            <Grid key={e.name} item xs={6} md={4} lg={3} xl={2}>
              <Box textAlign="center">
                <Attachment
                  ticketId={props.ticketId}
                  upload={props.upload}
                  attachment={e}
                />
              </Box>
            </Grid>
          );
        })}
        {props.sources?.map((e) => {
          return (
            <Grid key={e} item xs={6} md={4} lg={3} xl={2}>
              <Attachment
                ticketId={props.ticketId}
                upload={props.upload}
                src={e}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
