import { englishTranslations } from "./en";

export const turkishTranslations: typeof englishTranslations = {
  HomePage: {
    MainHeader: "7/24 Özel Destek",
    SubHeader:
      "Kendini işine adamış uzman ekibimiz size hızlı ve güvenilir destek sunmaya hazır ve endişelerinizin hızla giderilmesini sağlar.",
    OpenTicketButton: "Destek Talebi Oluştur",
    CustomerSupport: "Müşteri Desteği",
    ModuleOne: {
      title: "Her Adımda Uzman Rehberliği",
      body: "Her türlü sorunu sorunsuz bir şekilde çözmek için bilgi ve deneyim zenginliğimizden yararlanın.",
    },
    ModuleTwo: {
      title: "İhtiyaçlarınıza Özel Çözümler",
      body: "Destek hizmetlerimiz uyarlanabilir ve kişiselleştirilmiş olup, ihtiyaçlarınıza özel olarak uyarlanmış çözümler almanızı sağlar.",
    },
    ModuleThree: {
      title: "Güvenilirlik ve İnandırıcılık",
      body: "Desteğimiz en üst düzeyde güvenilirlik ve güvenlikle sunulur ve size en önemli şeylere odaklanmanız için gereken güveni sağlar.",
    },
    HowToCreateTicket: "Destek Talebi Nasıl Oluşturulur",
    StepOne:
      'Kayıt olun ve hesabınıza giriş yapın. "Destek Taleplerim" sekmesine gidin destek talebinizi oluşturmak için "Yeni Destek Talebi"ne tıklayın.',
    StepTwo:
      "Lütfen personelimizden biri yanıt verirken bekleyin, bu işlem biraz zaman alabilir. Daha sonra, sorununuzu nasıl çözeceğiniz konusunda size daha fazla ayrıntı sunacağız.",
    StepThree:
      "Tarafımızdan gelen yanıtın ardından, size uzaktan veya yerinde yardımcı olması için bir teknik destek üyesi atayarak sorunu çözmeye başlayabiliriz.",
    GetStarted: "Başlayın",
    Step: "ADIM",
  },
  Footer: {
    WhatWeDo: "NE YAPIYORUZ",
    WhatWeDoBody:
      "Proje danışmanlığı, kurulum ve kurulum sonrası hizmetlerin yanı sıra TDM/IP PBX ve Çağrı Merkezi çözümleri, fiber optik kablolama, sonlandırma ve test hizmetleri ile ağ ve iletim ekipmanlarının satış ve satış sonrası desteğini de sağlıyoruz.",
    Contact: "İLETİŞİM",
    FollowUs: "BİZİ TAKİP EDİN",
    Copyright: "Telif Hakkı",
  },
  AccountInfoPage: {
    AccountInfo: "Hesap Bilgisi",
    Name: "İsim",
    Username: "Kullanıcı Adı",
    Email: "E-posta",
    Phone: "Telefon",
    Company: "Firma",
    Role: "Rol",
    AccountStatus: "Hesap Durumu",
    Activated: "Etkinleştirildi",
    Inactive: "Etkin Değil",
    MultiFactorAuthentication: "Çoklu Faktör Doğrulama",
    Authenticator: "Kimlik Doğrulaycı",
    ChangeYourPassword: "Şifrenizi Değiştirin",
    ClickHere: "Buraya tıklayın",
  },
  ForgotPasswordPage: {
    RequestCode: "Kod iste",
    SubmitCode: "Kodu gönder",
    ChangeYourPassword: "Şifrenizi değiştirin",
    ForgotYourPassword: "Şifrenizi mi unuttunuz?",
    ResetPasswordBody:
      "Size bir şifre sıfırlama kodu gönderebilmemiz için lütfen e-postanızı girin.",
    Code: "Kod",
    EmailAddress: "E-posta Adresi",
    CheckYourCode:
      "Size bir onay kodu gönderdik. Lütfen e-postanızı kontrol edin ve kodu girin.",
    ConfirmationCode: "Onay Kodu",
    NewPassword: "Yeni Şifre",
    BackToAccountInfo: "Hesap Bİlgisine Geri Dön",
    BackToLogin: "Oturum Açmaya Geri Dön",
    ChangePassword: "Şifre Değiştir",
    DidntGetCode: "Kod almadınız mı?",
    TryAgain: "Tekrar Deneyin",
    SecondsLeft: "{{seconds}} saniye içinde",
  },
  Navigation: {
    Home: "Ana Sayfa",
    AllTickets: "Tüm Biletler",
    MyTickets: "Destek Taleplerim",
    Management: "Yönetim",
    AccountInfo: "Hesap Bilgisi",
    ChangePassword: "Şifre Değiştir",
    Logout: "Oturumu Kapat",
    Dashboard: "Konsol",
    Tickets: "Destek Talepleri",
    Storage: "Depolama",
    Users: "Kullanıcılar",
    Departments: "Bölümler",
    Security: "Güvenlik",
    Logs: "GÜnlük Dosyaları",
    Settings: "Ayarlar",
    Login: "Oturum Aç",
    Register: "Kayıt Ol",
    ForgotPassword: "Şifremi Unuttum",
  },
  ManageTicketPage: {
    Ticket: "Bilet",
  },
  CreateTicketPage: {
    CreateTicket: "Bilet Oluştur",
  },
  SetupPage: {
    Setup: "Kurulum",
    Finish: "Bitir",
    FullName: "Tam isim",
    Email: "E-posta",
    Username: "Kullanıcı_adı",
    Password: "Şifre",
    Finalize: "Sonlandır",
    ScanQrGuide:
      "Yöneticiye giriş yapmak için lütfen kimlik doğrulayıcı uygulamasındaki QR kodunu tarayın.",
    GoBack: "Geri Git",
    Features: "Özellikler",
    Back: "Geri",
    Next: "Sonraki",
    EmailSetup: {
      EmailSetup: "E-posta Kurulumu",
      Server: "Sunucu",
      From: "Kimden",
      Username: "Kullanıcı_adı",
      Password: "Şifre",
      Port: "Port",
      Secure: "Güvenlik",
    },
    FtpSetup: {
      FTPSetup: "FTP Kurulumu",
      Host: "Alan Adı",
      Username: "Kullanıcı_Adı",
      Password: "Şifre",
      Port: "Port",
      Secure: "Güvenlik",
      UploadPath: "Yükleme YOlu",
    },
    OpenAISetup: {
      OpenAISetup: "Open AI Kurulumu",
      ApiKey: "Api Anahtarı",
      AssistantID: "Asistan ID",
      OrganizationID: "Organizasyon ID",
    },
    SapSetup: {
      SapSetup: "SAP Kurulumu",
      Server: "Sunucu",
      Database: "Veritabanı",
      Username: "Kullanıcı_Adı",
      Password: "Şifre",
    },
  },
  LoginForm: {
    Header: "Hoşgeldiniz",
    Subheader: "Lütfen hesabınıza buradan giriş yapın!",
    LoginInput: {
      label: "Giriş Yap",
      placeholder: "Kullanıcı_Adı ya da E-posta",
    },
    Password: "Şifre",
    DontHaveAccount: "Henüz bir hesabınız yok mu?",
    Register: "Kayıt Ol",
    Login: "Oturum Aç",
    ForgotPassword: "Şifreni mi unuttun?",
  },
  ActivateAccountDialog: {
    ActivateYourAccount: "Hesabını etkinleştir",
    PleaseEnterCode: "Lütfen e-posta adresinize gönderilen kodu girin.",
    ActivationCode: "Aktivasyon Kodu",
    Cancel: "İptal Et",
    Activate: "Etkinleştir",
  },
  OTPDialog: {
    OneTimePassword: "Tek Seferlik Şifre",
    PleaseEnterOtp:
      "Lütfen Authenticator uygulamasından aldığınız tek seferlik kodu girin",
    OtpCode: "OTP Kodu",
    Cancel: "İptal Et",
    Confirm: "Onayla",
  },
  EmailConfirmationDialog: {
    EmailConfirmation: "E-posta Doğrulama",
    PleaseEnterCode: "Lütfen e-posta adresinize gönderilen kodu girin.",
    ConfirmationCode: "Doğrulama Kodu",
    Cancel: "İptal Et",
    Confirm: "Onayla",
  },
  RegisterForm: {
    Register: "Kayıt Ol",
    ToContactOurSupport:
      "Destek ekibimizle iletişime geçmek ve destek talebi oluşturmak için lütfen buradan kaydolun.",
    Email: "E-posta",
    Company: "Firma",
    Phone: {
      Label: "Telefon",
      Placeholder: "Telefon Numarası",
    },
    Name: {
      Label: "İsim",
      Placeholder: "ör: Ali Öztürk",
    },
    Username: {
      Label: "Kullanıcı_Adı",
      Placeholder: "ör: aliozturk",
    },
    Password: "Şifre",
    AlreadyHaveAccount: "Zaten bir hesabınız mı var?",
    Login: "Oturum Açın",
  },
  NotificationButton: {
    LoadMore: "Daha fazla yükle...",
    Notifications: "Bildirimler",
    ClearNotifications: "Bildirimleri Temizle",
    NoNotificationsFound: "Bildirim Bulunamadı",
  },
  UploadButton: {
    Upload: "Yükle",
  },
  UploadDialog: {
    Upload: "Yükle",
    ClickToUpload: "Yüklemek için tıkla",
  },
  TaskDialog: {
    Cancelled: "İptal edildi",
    Started: "Başladı",
    Ended: "Bitti",
    Title: "Başlık",
    Description: "Açıklama",
    Name: "İsim",
    Action: "Aksiyon",
    SelectUserPlaceholder: "Atanacak kullanıcıyı seçin...",
    Comments: {
      Label: "Yorumlar",
      Placeholder: "ör: Görevin x saatinde yeniden değerlendirilmesi gerekli",
    },
    CancelTask: "Görevi İptal Et",
    Delete: "Sil",
    Start: "Başlat",
    Stop: "Durdur",
    AlreadyStarted: "Çoktan başladı mı?",
    StartAndEndDates: "Başlangıç & Bitiş Tarihleri",
    Set: "Ayarla",
    StartDate: "Başlama Tarihi",
    EndDate: "Bitiş Tarihi",
  },
  QRButton: {
    QR: "Karekod",
    PleaseScan: "Lütfen Authenticator uygulamasında karekodu taratın.",
    Close: "Kapat",
  },
  FilterBox: {
    Clear: "Temizle",
    Apply: "Uygula",
    AddFilter: "Filtre Ekle",
  },
  CardList: {
    NoResultsFound: "Hiçbir sonuç bulunamadı",
  },
  DataTable: {
    NoResultsFound: "Hiçbir sonuç bulunamadı",
    MaxRows: "Maksimum Satır",
    DisplayedRows: "{{info.count}}'in '{{info.from}}–{{info.to}}'i",
  },
  CreateUserDialog: {
    CreateUser: "Kullanıcı Oluştur",
    Role: "Rol",
    Email: {
      Label: "E-posta",
      Placeholder: "ör: ali.ozturk@email.com",
    },
    Username: "Kullanıcı_Adı",
    Company: "Firma",
    Phone: "Telefon",
    FullName: "Tam İsim",
    Password: "Şifre",
    EmailConfirmation: "E-posta Doğrulama",
    OTPEnabled: "OTP Etkinleştirildi",
  },
  CreateRecordDialog: {
    CreateRecord: "Kayıt Oluştur",
    Type: "Tür",
    IP: {
      Label: "IP",
      Placeholder: "ör: 192.168.0.1",
    },
    Subnet: {
      Label: "Alt Ağ",
      Placeholder: "ör: 255.255.255.0",
    },
  },
  ReorderableList: {
    NewPlaceholder: "Yeni seçenek ekle",
  },
  CreateDepartmentDialog: {
    CreateDepartment: "Bölüm Oluştur",
    Name: "İsim",
    Description: "Açıklama",
    Administrative: "Yönetimsel",
  },
  Atoms: {
    AskAiTooltip: "YZ'ye Sor'",
    DeleteTooltip: "Sil",
    SummarizeTooltip: "Özetle",
    TranslateTooltip: "Tercüme Et",
  },
  AskAiDialog: {
    Description:
      "Onaylamadan önce herhangi bir özel bilgiyi kaldırmak için içeriği düzenleyebilirsiniz.",
    PromptLabel: "İstem",
    Cancel: "İptal Et",
    AskAI: "YZ'ye Sor'",
  },
  AskAiResponseDialog: {
    AIResponse: "YZ Yanıtı",
  },
  DateRanges: {
    Week: "Hafta",
    Month: "Ay",
    Year: "Yıl",
    AllTime: "Tüm Zamanlar",
  },
  EditButton: {
    Tooltip: "Düzenle",
    Save: "Kaydet",
    Cancel: "İptal Et",
  },
  FormInput: {
    True: "Doğru",
    False: "Yanlış",
  },
  Dashboard: {
    ActiveTasks: "Aktif Görevler",
    Tickets: "Destek Talepleri",
    View: "Görüntüle",
    NoActiveTasks: "Aktif görev yok.",
    TicketStatus: "Destek Durumu",
    TicketsResolved: "Çözülmüş Talepler",
    UnsolvedTickets: "Çözülmemiş Talepler",
    PendingTickets: "Bekleyen Biletler",
    SolvedTickets: "Çözülmüş Talepler",
    AvgResponseTime: "Ortalama Yanıt Süresi",
    AvgResolutionTime: "Ortalama Çözüm Süresi",
  },
  Ticket: {
    MustSelectDepartment: "Öncelikle bir bölüm seçmelisiniz",
    Assignees: "Atananlar",
    NoAssignees: "Hiçbir atanan yok",
    NoServiceCall: "Servis çağrısı yok",
    NoTasks: "Hiçbir görev yok",
    MyTickets: "Destek Taleplerim",
    CreateTask: "Görev Oluştur",
    CreateServiceCall: "Servis Çağrısı Oluştur",
    AttachServiceCall: "Servis Çağrısı Ekle",
    Title: "Başlık",
    Description: "Açıklama",
    DescriptionPlaceholder: "ör: Kutuları renk sırasına göre yeniden sırala",
    ServiceCallTitle: "Servis Çağrısı Başlığı",
    ProblemType: "Problem Türü",
    CallType: "Çağrı Türü",
    Priority: "Öncelik",
    Customer: "Müşteri",
    ContactInfo: "İletişim Bilgileri",
    Contact: "İletişim",
    Technician: "Teknisyen",
    Search: "Ara",
    SearchPlaceholder: "Ara...",
    Response: "Yanıt",
    ResponsePlaceholder: "Yeni Yanıt",
    Attachments: "Ekler",
    NoFilesSelected: "Dosya Seçilmedi",
    Upload: "Yükle",
    Clear: "Temizle",
    Respond: "Yanıtla",
    TitlePlaceholder: "Bozuk Parça No: 123",
    NewTitlePlaceholder: "Yeni Başlık",
    Details: "Detaylar",
    DetailsPlaceholder: "Talep detayları",
    AIFeatures: "YZ Özellikleri",
    Company: "Firma",
    CompanyPlaceholder: "Firmanız",
    Department: "Bölüm",
    DepartmentPlaceholder: "Bir bölüm seçin",
    City: "Şehir",
    CityPlaceholder: "Şehir adı",
    Location: "Konum",
    LocationPlaceholder: "Adres...",
    Status: "Durum",
    Update: "Güncelle",
    ServiceCall: "Servis Çağrısı",
    Expenses: "Masraflar",
    ItemNo: "Parça No:",
    SerialNo: "Seri No:",
    Quantity: "Miktar",
    Times: "Zaman",
    Duration: "Süre",
    Remarks: "İşaretler",
    Workers: "İşçiler",
    View: "Görüntüle",
    EditContent: "İçeriği Düzenle",
    Create: "Oluştur",
    Attach: "Ekle",
    Voucher: "Kupon",
    AddVoucher: "Kupon ekle",
    CreateTicket: "Destek Talebi Oluştur",
    Tasks: "Görevler",
    Tickets: "Destek Talepleri",
    NewTicket: "Yeni Talep",
    Person: "Kişi",
    Phone: "Telefon",
    PhonePlaceholder: "Telefon numarası",
    ID: "Kimlik",
    Date: "Tarih",
    ServiceCallNumber: "Servis Çağrı Numarası",
    ServiceCallID: "Servis Çağrısı ID",
    NotAvailable: "Mevcut Değil",
    Action: "Eylem",
    Manage: "Yönet",
    Name: "İsim",
    Cellular: "Hücresel",
    Tel1: "Tel1",
    Tel2: "Tel2",
    Email: "E-posta",
    SapContact: "Sap İletişim",
  },
  Department: {
    Users: "Kullanıcılar",
    Departments: "Bölümler",
    Action: "Eylem",
    Edit: "Düzenle",
    Delete: "Sil",
    Name: "İsim",
    Description: "Açıklama",
    Administrative: "Yönetimsel",
    NewDepartment: "Yeni Bölüm",
  },
  Settings: {
    Cities: "Şehirler",
    General: "Genel",
    ForwardEmails: "E-postaları YÖnlendir",
    Save: "Kaydet",
    CustomerTicketsRecipients: "Müşteri Talep Alıcıları",
    FirewallRecipients: "Güvenlik Duvarı Alıcıları",
    ItemsRecipients: "Parça Alıcıları",
    ItemReminderRecipients: "Parça Hatırlatıcı Alıcıları",
    QnapRecipients: "Qnap Alıcıları",
    SapRecipients: "Sap Alıcıları",
    SubscriptionsRecipients: "Üyelik Alıcıları",
    TasksRecipients: "Görev Alıcıları",
    TicketsRecipients: "Talep Alıcıları",
    UsersRecipients: "Kullanıcı Alıcıları",
    VouchersRecipients: "Kupon Alıcıları",
    FTP: "FTP",
    Enabled: "Etkinleştirildi",
    Host: "Alan Adı",
    Username: "Kullanıcı_Adı",
    Password: "Şifre",
    UploadPath: "Yükleme Yolu",
    UploadPathPlaceholder: "ör: /upload",
    Port: "Port",
    Secure: "Güvenlik?",
    Messages: "Mesajlar",
    Forwards: "Yönlendirmeler",
    SMTP: "SMTP",
    OpenAI: "OpenAI",
    SAP: "SAP",
    Mail: "Posta",
    EmailConfigurationName: "E-posta Yapılandırma Adı",
    Subject: "Konu",
    SubjectPlaceholder: "ör: Yeni Destek Talebi [${ticket.id}]",
    FromEmail: "E-postadan",
    FromEmailPlaceholder: "Varsayılan",
    Forward: "Yönlendir",
    ForwardPlaceholder:
      "E-posta yönlendirme listesindeki alıcılar tarafından alındı...",
    Direct: "Doğrudan",
    DirectPlaceholder: "E-posta hedeflenen alıcı tarafından alındı...",
    AssistantID: "Asistan ID",
    APIKey: "API Anahtarı",
    OrganizationID: "Organizasyon ID",
    Server: "Sunucu",
    Database: "Veritabanı",
    Test: "Test",
    SMTPServerPlaceholder: "smtp.gmail.com",
    SecureConnection: "Güvenli Bağlantı?",
    TestEmail: "E-posta Test Et",
    TestEmailPlaceholder: "ali.orturk@email.com",
  },
  User: {
    Users: "Kullanıcılar",
    Name: "İsim",
    Edit: "Düzenle",
    Role: "Rol",
    Email: "E-posta",
    EmailPlaceholder: "ör: ali.ozturk@email.com",
    Username: "Kullanıcı_Adı",
    FullName: "Tam İsim",
    Company: "Firma",
    Phone: "Telefon",
    NewPassword: "Yeni Şifre",
    EmailConfirmation: "E-posta Doğrulama (Çok Faktörlü Doğrulama)",
    OTP: "Tek Seferlik Şifre (Çok Faktörlü Doğrulama)",
    Action: "Eylem",
    Delete: "Sil",
    NewUser: "Yeni Kullanıcı",
  },
  Logs: {
    Logs: "Günlük Dosyaları",
    Date: "Tarih",
    Reference: "Referans",
    Ref: "Ref",
    User: "Kullanıcı",
    Model: "Model",
    Action: "Eylem",
  },
  SecurityPage: {
    FirewallRecords: "Güvenlik Duvarı Kayıtları",
    Date: "Tarih",
    IP: "IP",
    Subnet: "Alt Ağ",
    Type: "Tür",
    Action: "Eylem",
    Delete: "Sil",
    NewRecord: "Yeni Kayıt",
  },
  StoragePage: {
    Rename: "Yeniden Adlandır",
    Delete: "Sil",
    FTPStorage: "FTP Depo",
    NewFolder: "Yeni Klasör",
    Path: "Yol",
    Search: "Ara",
    Name: "İsim",
    Date: "Tarih",
    Download: "İndir",
    DownloadConfirm: "Bu dosyayı indirmeye devam etmek istiyor musunuz?",
    Size: "Boyut",
    Unknown: "Bilinmeyen",
    Folder: "Klasör",
    SymbolicLink: "Sembolik Bağlantı",
    NewFile: "Yeni Dosya",
    Edit: "Düzenle",
  },
  FormTemplate: {
    Submit: "Gönder",
    Cancel: "İptal Et",
  },
  ConfirmDialog: {
    Question: "Devam etmek istediğinize emin misiniz?",
    Cancel: "İptal Et",
    Confirm: "Onayla",
  },
  LanguageSwitcher: {
    Tooltip: "Dilinizi değiştirmek için buraya tıklayın",
    English: "İngilizce",
    Turkish: "Türkçe",
  },
  DataView: {
    Search: "{{topic}} ara...",
    Filters: "Filtreler",
    Add: "Ekle",
  },
  Enums: {
    Roles: {
      User: "Kullanıcı",
      Agent: "Temsilci",
      Admin: "Yönetici",
    },
    RecordTypes: {
      Whitelist: "Beyaz Liste",
      Blacklist: "Kara Liste",
    },
    Priorities: {
      Low: "Düşük",
      Medium: "Orta",
      High: "Yüksek",
    },
    Statuses: {
      "Not reviewed": "Gözden geçirilmedi",
      Incomplete: "Tamamlanmadı",
      "Pending confirmation": "Onay Bekliyor",
      Complete: "Tamamlandı",
    },
  },
  TextEditor: {
    TypeHere: "Buraya yazın...",
  },
};
