import { Paper } from "@mui/material";
import FormInput from "../../molecules/FormInput";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";

export interface TicketBodyFields {
  title: string;
  details: string;
}

interface TicketBodyProps {
  onChange: (fields: TicketBodyFields) => void;
}

export default function TicketBody(props: TicketBodyProps) {
  const { t } = useTranslation("Ticket");
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");

  useEffect(() => {
    props.onChange({ title, details });
  }, [title, details]);

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <Stack spacing={2}>
        <FormInput
          required
          field="title"
          label={t("Title")}
          value={title}
          onChange={(value) => setTitle(value as string)}
          type="string"
          placeholder={t("TitlePlaceholder")}
        />
        <FormInput
          required
          field="details"
          label={t("Details")}
          value={details}
          onChange={(value) => setDetails(value as string)}
          type="string"
          multiline
          minRows={8}
          placeholder={t("DetailsPlaceholder")}
        />
      </Stack>
    </Paper>
  );
}
