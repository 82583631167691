import {
  Alert,
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Task, Ticket } from "../../../services/swagger";
import { useState } from "react";
import Add from "@mui/icons-material/Add";
import CreateTaskDialog from "./CreateTaskDialog";
import TicketTaskDialog from "../TaskDialog";
import { useTranslation } from "react-i18next";
import SquareIconButton from "../../atoms/SquareIconButton";

export interface TicketTasksProps {
  ticket: Ticket;
}

export interface TicketTaskProps {
  task: Task;
  ticket: Ticket;
}

function TicketTask(props: TicketTaskProps) {
  const { t } = useTranslation("Ticket");
  const { task, ticket } = props;
  const [open, setOpen] = useState(false);
  let severity: "info" | "error" | "success" | "warning" = "info";
  if (task.cancelDate) severity = "error";
  else if (task.endDate) severity = "success";
  else if (task.startDate) severity = "warning";
  return (
    <>
      <TicketTaskDialog
        open={open}
        ticket={ticket}
        task={task}
        onClose={() => setOpen(false)}
      />
      <Alert
        severity={severity}
        action={
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={() => setOpen(true)}
          >
            {t("View")}
          </Button>
        }
      >
        {task.title}
      </Alert>
    </>
  );
}

export default function TicketTasks(props: TicketTasksProps) {
  const { t } = useTranslation("Ticket");
  const { ticket } = props;
  const [createDialog, setCreateDialog] = useState(false);

  const handleClickCreateTask = () => {
    setCreateDialog(true);
  };

  const handleCloseCreateTask = () => {
    setCreateDialog(false);
  };

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <CreateTaskDialog
        open={createDialog}
        ticketId={ticket._id}
        onClose={handleCloseCreateTask}
      />
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            fontWeight={400}
            fontSize={16}
            color="primary"
            gutterBottom
          >
            {t("Tasks")}
          </Typography>
          {(ticket.status === "Not reviewed" ||
            ticket.status === "Incomplete") && (
            <SquareIconButton
              icon={Add}
              onClick={handleClickCreateTask}
              tooltip={t("Create")}
            />
          )}
        </Box>
        {ticket.tasks.length > 0 ? (
          ticket.tasks.map((e) => {
            return <TicketTask key={e._id} ticket={ticket} task={e} />;
          })
        ) : (
          <Typography color="text.secondary">{t("NoTasks")}</Typography>
        )}
      </Stack>
    </Paper>
  );
}
