import {
  Box,
  Button,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  PopoverPosition,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import { FtpService } from "../../../services/api";
import { FileInfo, SortDirection } from "../../../services/swagger";
import FolderIcon from "@mui/icons-material/Folder";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SearchIcon from "@mui/icons-material/Search";
import useConfirm from "../../../hooks/useConfirm";
import useDownload from "../../../hooks/useDownload";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import UploadButton from "../../organisms/UploadButton";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Authorized from "../../atoms/Authorized";
import { useTranslation } from "react-i18next";
import SortingArrow from "../../atoms/SortingArrow";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DownloadIcon from "@mui/icons-material/Download";
import {
  getFileCategory,
  getFileExtension,
  ViewableMediaType,
} from "../../../helpers/file";
import ArticleIcon from "@mui/icons-material/Article";
import PdfIcon from "../../../assets/pdf-icon.webp";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import MovieIcon from "@mui/icons-material/Movie";
import { getDownloadSrc } from "../../../helpers/download";
import ImageIcon from "../../atoms/ImageIcon";
import Image from "@mui/icons-material/Image";
import MediaDialog from "../../atoms/MediaDialog";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EditNoteIcon from "@mui/icons-material/EditNote";
import useBreakpoint from "../../../hooks/useBreakpoint";

function normalizeFTPPath(inputPath: string): string {
  // Remove consecutive slashes
  const cleanedPath = inputPath.replace(/\/+/g, "/");

  // Ensure the path starts with a single slash
  const absolutePath = cleanedPath.startsWith("/")
    ? cleanedPath
    : "/" + cleanedPath;

  // Ensure it's a valid FTP path
  const ftpPath = `${absolutePath}`;

  return ftpPath;
}

export function formatBytes(bytes: number) {
  if (bytes === 0) return "0 B";

  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${formattedSize} ${sizes[i]}`;
}

const rowsPerPage = 100;

export default function StoragePage() {
  const { t } = useTranslation("StoragePage");
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();
  const { download, DownloadLink } = useDownload();
  const [createDirectory, setCreateDirectory] = useState(false);
  const [createFile, setCreateFile] = useState(false);
  const searchInput = useRef<HTMLInputElement | null>(null);
  const [pathText, setPathText] = useState("");
  const remoteFiles = useRef<FileInfo[]>([]);
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | null>(
    null
  );
  const [currentFile, setCurrentFile] = useState<FileInfo | null>();
  const [renameFile, setRenameFile] = useState<FileInfo | null>();
  const [name, setName] = useState("");
  const [sortField, setSortField] = useState<"name" | "date" | "size">("name");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  );
  const table = useRef<any>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const [searchExpanded, setSearchExpenaded] = useState(
    Boolean(searchInput.current?.value || "")
  );

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [mediaSrc, setMediaSrc] = useState("");
  const [mediaType, setMediaType] = useState<ViewableMediaType>();
  const [mediaFilename, setMediaFilename] = useState("");
  const [mediaDownloadPath, setMediaDownloadPath] = useState("");

  const page = useMemo(
    () => parseInt(searchParams.get("page") || "0"),
    [searchParams]
  );

  const createDirectoryElement = useRef<HTMLInputElement | null>(null);
  const createFileElement = useRef<HTMLInputElement | null>(null);

  const path = useMemo(() => searchParams.get("path") || "/", [searchParams]);

  const { confirm: confirmDownload, ConfirmDialog: DownloadConfirmDialog } =
    useConfirm({
      title: t("Download"),
      text: t("DownloadConfirm"),
    });

  const handlePageChange = (event: any, page: number) => {
    setSearchParams(
      (sp) => {
        sp.set("page", page.toString());
        return sp;
      },
      { replace: true }
    );
    table.current.scroll({ top: 0 });
  };

  const handleClickCreateFile = useCallback(() => {
    handleCloseMenu();
    table.current.scroll({ top: 0 });
    setName("");
    setCreateFile(true);
  }, []);

  const handleClickCreateDirectory = useCallback(() => {
    handleCloseMenu();
    table.current.scroll({ top: 0 });
    setName("");
    setCreateDirectory(true);
  }, []);

  useLayoutEffect(
    () => createDirectoryElement.current?.focus(),
    [createDirectory]
  );

  useLayoutEffect(() => createFileElement.current?.focus(), [createFile]);

  const handleCreateDirectory = useCallback(() => {
    confirm(async () => {
      let paths = path.split("/");
      paths = [...paths, name];
      const folderpath = normalizeFTPPath(paths.join("/"));
      const res = await FtpService.createDirectory({ path: folderpath });
      if (!res?.data?.success) return;
      handleCancelCreateDirectory();
      loadFiles();
    });
  }, [path]);

  const handleCreateFile = useCallback(() => {
    confirm(async () => {
      let paths = path.split("/");
      paths = [...paths, name];
      const fullPath = normalizeFTPPath(paths.join("/"));
      const folderPath = fullPath.substring(0, fullPath.lastIndexOf("/"));
      const file = new File([""], name, {
        type: "text/plain",
      });
      const formData = new FormData();
      formData.append("files", file);
      const res = await FtpService.createFileUpload(
        {
          path: folderPath,
        },
        {
          data: formData,
        }
      );
      if (!res?.data?.success) return;
      handleCancelCreateFile();
      navigate({
        pathname: "/m/storage/text-editor",
        search: createSearchParams({
          path: folderPath + `/${name}`,
        }).toString(),
      });
    });
  }, [path]);

  const handleCancelCreateDirectory = useCallback(() => {
    setCreateDirectory(false);
    setName("");
  }, []);

  const handleCancelCreateFile = useCallback(() => {
    setCreateFile(false);
    setName("");
  }, []);

  const handleRightClick = useCallback((e: MouseEvent, file?: FileInfo) => {
    e.preventDefault();
    if (file !== undefined) {
      if (renameFile?.name === file?.name) return;
    }
    if (createDirectory) {
      setCreateDirectory(false);
      return;
    }
    e.stopPropagation();
    handleCancelRename();
    setAnchorPosition({ left: e.clientX, top: e.clientY });
    setCurrentFile(file);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorPosition(null);
    setCurrentFile(null);
  }, []);

  const handleGoBack = useCallback(() => {
    if (path === "/") return;
    let paths = path.split("/");
    paths.splice(paths.length - 1, 1);
    setSearchParams((prev) => {
      prev.set("path", normalizeFTPPath(paths.join("/")));
      return prev;
    });
  }, [path]);

  const openFile = useCallback(
    (filename: string) => {
      navigate({
        pathname: "/m/storage/text-editor",
        search: createSearchParams({
          path: path + `/${filename}`,
        }).toString(),
      });
    },
    [path]
  );

  const handleRowClick = useCallback(
    (file: FileInfo) => {
      if (createDirectory) {
        setCreateDirectory(false);
        return;
      }
      if (renameFile) {
        if (renameFile.name !== file.name) setRenameFile(null);
        return;
      }
      // if it's file
      if (file.type === 1) {
        let paths = path.split("/");
        paths = [...paths, file.name];
        const filePath = normalizeFTPPath(paths.join("/"));
        const category = getFileCategory(file.name);
        if (!category)
          return confirmDownload(() => {
            download(filePath);
          });
        if (
          category === "audio" ||
          category === "video" ||
          category === "image"
        ) {
          const extension = getFileExtension(file.name);
          const mimeType = `${category}/${extension}`;
          setMediaSrc(
            getDownloadSrc(filePath, "", {
              disableForceDownload: true,
              mimeType,
            })
          );
          setMediaDownloadPath(filePath);
          setMediaFilename(file.name);
          setMediaType(category);
          setMediaDialogOpen(true);
        } else if (category === "pdf") {
          return window.open(
            getDownloadSrc(filePath, "", {
              disableForceDownload: true,
              mimeType: "application/pdf",
            }),
            "_blank"
          );
        } else if (category === "text") {
          return openFile(file.name);
        }
      } else if (file.type === 2) {
        let paths = path.split("/");
        paths = [...paths, file.name];

        setSearchParams((prev) => {
          prev.set("path", normalizeFTPPath(paths.join("/")));
          return prev;
        });
      }
    },
    [searchParams, createDirectory, renameFile, path]
  );

  // const handleSetPath = () => {
  //   let finalPath = pathText;
  //   if (!finalPath.startsWith("/")) finalPath = "/" + pathText;
  //   if (finalPath.endsWith("/") && finalPath.length > 1)
  //     finalPath = finalPath.substring(0, finalPath.length - 1);

  //   // Remove consecutive slashes
  //   finalPath = finalPath.replace(/\/+/g, "/");

  //   setSearchParams((prev) => {
  //     prev.set("path", finalPath);
  //     return prev;
  //   });
  // };

  const filterFiles = useCallback(() => {
    startTransition(() => {
      const multi = sortDirection === "desc" ? -1 : 1;
      let sortedList = [...remoteFiles.current];
      if (sortField === "name") {
        sortedList = sortedList.sort((a, b) =>
          a.name > b.name ? multi : -multi
        );
      } else if (sortField === "date") {
        sortedList = sortedList.sort((a, b) =>
          new Date(a.modifiedAt as string).getTime() >
          new Date(b.modifiedAt as string).getTime()
            ? multi
            : -multi
        );
      } else if (sortField === "size") {
        sortedList = sortedList.sort((a, b) =>
          a.size > b.size ? multi : -multi
        );
      }
      const searchFiltered = sortedList.filter((e) =>
        e.name
          .toLowerCase()
          .includes((searchInput.current?.value || "").toLowerCase())
      );
      const finalFiltered = [
        ...searchFiltered.filter(
          (e) => e.type === (sortDirection === "asc" ? 2 : 1)
        ),
        ...searchFiltered.filter(
          (e) => e.type === (sortDirection === "asc" ? 1 : 2)
        ),
      ];
      setFiles(finalFiltered);
    });
  }, [sortDirection, remoteFiles.current, sortField]);

  const loadFiles = useCallback(() => {
    startTransition((async () => {
      const res = await FtpService.getFileList({ path });
      if (res?.data?.data) {
        remoteFiles.current = res.data.data;
        if (searchInput.current) searchInput.current.value = "";
        filterFiles();
      }
    }) as () => void);
  }, [path]);

  useEffect(() => {
    setPathText(path);
    handlePageChange(null, 0);
    loadFiles();
  }, [path]);

  const { confirm: confirm, ConfirmDialog: ConfirmDialog } = useConfirm();

  const handleSort = useCallback(
    (field: "name" | "date" | "size") => {
      if (sortField === field) {
        setSortDirection(sortDirection === "desc" ? "asc" : "desc");
      } else {
        if (field === "name") setSortDirection("asc");
        else setSortDirection("desc");
        setSortField(field);
      }
    },
    [sortField, sortDirection]
  );

  useDidMountEffect(filterFiles, [sortField, sortDirection]);

  const handleCancelRename = useCallback(() => {
    setRenameFile(null);
    setName("");
  }, []);

  const handleRename = useCallback(async () => {
    confirm(async () => {
      if (!renameFile) return;
      let paths = path.split("/");
      const oldPath = normalizeFTPPath([...paths, renameFile.name].join("/"));
      const newPath = normalizeFTPPath([...paths, name].join("/"));
      const res = await FtpService.updateFilename({ oldPath, newPath });
      if (!res?.data?.success) return;
      handleCancelRename();
      loadFiles();
    });
  }, [path, renameFile, name]);

  const handleDelete = useCallback(async () => {
    if (!currentFile) return;
    let paths = path.split("/");
    paths = [...paths, currentFile.name];
    const finalpath = normalizeFTPPath(paths.join("/"));
    if (currentFile.type === 2) {
      await FtpService.deleteDirectory({ path: finalpath });
    } else if (currentFile.type === 1) {
      await FtpService.deleteFile({ path: finalpath });
    }
    loadFiles();
  }, [currentFile, path]);

  const handleClickDownload = useCallback(
    (e: MouseEvent) => {
      if (currentFile?.type === 1) {
        let paths = path.split("/");
        paths = [...paths, currentFile.name];
        const filePath = normalizeFTPPath(paths.join("/"));
        confirmDownload(() => {
          download(filePath);
        });
      }
    },
    [currentFile, path]
  );

  const handleClickRename = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (!currentFile) return;
      setRenameFile({ ...currentFile });
      handleCloseMenu();
      setName(currentFile.name);
    },
    [currentFile]
  );

  const handleClickDelete = useCallback((e: MouseEvent) => {
    e.preventDefault();
    confirm(handleDelete);
    handleCloseMenu();
  }, []);

  const handleMediaDialogClose = useCallback(() => {
    setMediaDialogOpen(false);
    setMediaFilename("");
    setMediaSrc("");
    setMediaType(undefined);
    setMediaDownloadPath("");
  }, []);

  return (
    <Box height={1}>
      {mediaType && (
        <MediaDialog
          open={mediaDialogOpen}
          src={mediaSrc}
          type={mediaType}
          filename={mediaFilename}
          downloadPath={mediaDownloadPath}
          onClose={handleMediaDialogClose}
        />
      )}
      <Menu
        onContextMenu={(e) => {
          e.preventDefault();
          handleCloseMenu();
        }}
        disableAutoFocus
        disableAutoFocusItem
        disableEnforceFocus
        disableRestoreFocus
        MenuListProps={{ sx: { bgcolor: "background.default" } }}
        onClose={handleCloseMenu}
        open={anchorPosition !== null}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition ?? { top: -999, left: -999 }}
      >
        <MenuItem onClick={handleClickCreateFile}>
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText>{t("NewFile")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickCreateDirectory}>
          <ListItemIcon>
            <CreateNewFolderIcon />
          </ListItemIcon>
          <ListItemText>{t("NewFolder")}</ListItemText>
        </MenuItem>
        {currentFile?.type === 1 && (
          <MenuItem onClick={() => openFile(currentFile.name)}>
            <ListItemIcon>
              <EditNoteIcon />
            </ListItemIcon>
            <ListItemText>{t("Edit")}</ListItemText>
          </MenuItem>
        )}
        {currentFile?.type === 1 && (
          <MenuItem onClick={handleClickDownload}>
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText>{t("Download")}</ListItemText>
          </MenuItem>
        )}
        {currentFile && (
          <MenuItem onClick={handleClickRename}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>{t("Rename")}</ListItemText>
          </MenuItem>
        )}
        {currentFile && (
          <Authorized role="admin">
            <MenuItem onClick={handleClickDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>{t("Delete")}</ListItemText>
            </MenuItem>
          </Authorized>
        )}
      </Menu>
      <DownloadLink />
      <DownloadConfirmDialog />
      <ConfirmDialog />
      <Stack height={1}>
        <Box
          component={Paper}
          sx={{
            p: 1.5,
            mb: 2,
            bgcolor: (theme) => theme.palette.background.default,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: (theme) => theme.shape.borderRadius / 8,
          }}
        >
          <InputBase
            ref={searchRef}
            inputRef={searchInput}
            sx={{
              borderRadius: (theme) => theme.shape.borderRadius / 8,
              bgcolor: (theme) =>
                searchExpanded ? theme.palette.background.paper : "none",
              px: 1,
              py: 1,
              mr: 2,
              flexGrow: 1,
              transitionDuration: "50ms",
              transitionTimingFunction: "ease-in-out",
            }}
            onFocus={(e) => {
              if (searchRef.current) setSearchExpenaded(true);
            }}
            onBlur={(e) => {
              if (searchRef.current && !searchInput.current?.value)
                setSearchExpenaded(false);
            }}
            onChange={filterFiles}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                if (!breakpoint.md) e.currentTarget.blur();
                handlePageChange(null, 0);
                filterFiles();
              }
            }}
            placeholder={t("Search")}
            endAdornment={
              <IconButton size="small">
                <SearchIcon
                  onClick={() => {
                    filterFiles();
                    handlePageChange(null, 0);
                  }}
                />
              </IconButton>
            }
          />
          <Stack spacing={2} direction="row">
            {breakpoint.md ? (
              <Button
                startIcon={<CreateNewFolderIcon />}
                variant="contained"
                onClick={handleClickCreateDirectory}
              >
                {t("NewFolder")}
              </Button>
            ) : (
              <Tooltip title={t("NewFolder")}>
                <Button
                  variant="contained"
                  onClick={handleClickCreateDirectory}
                >
                  <CreateNewFolderIcon />
                </Button>
              </Tooltip>
            )}
            <UploadButton path={path} onSuccess={loadFiles} />
          </Stack>
        </Box>
        <Box
          component={Paper}
          flexGrow={1}
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          <TableContainer
            sx={{ height: 1, overflow: "auto" }}
            onContextMenu={(e) => {
              e.preventDefault();
              handleRightClick(e);
            }}
            ref={table}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontSize="inherit" fontWeight="inherit">
                        {t("Name")}
                      </Typography>
                      <SortingArrow
                        onClick={() => handleSort("name")}
                        activeField={sortField}
                        field="name"
                        sort={sortDirection}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell width={100}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontSize="inherit" fontWeight="inherit">
                        {t("Date")}
                      </Typography>
                      <SortingArrow
                        onClick={() => handleSort("date")}
                        activeField={sortField}
                        field="date"
                        sort={sortDirection}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell width={100} align="right">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontSize="inherit" fontWeight="inherit">
                        {t("Size")}
                      </Typography>
                      <SortingArrow
                        onClick={() => handleSort("size")}
                        activeField={sortField}
                        field="size"
                        sort={sortDirection}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  <TableRow
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={handleGoBack}
                  >
                    <TableCell colSpan={3}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <FolderIcon
                          sx={{ color: (theme) => theme.palette.warning.light }}
                        />
                        <Box>..</Box>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  {createDirectory && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <FolderIcon
                            sx={{
                              color: (theme) => theme.palette.warning.light,
                            }}
                          />
                          <Box flexGrow={1}>
                            <TextField
                              inputRef={createDirectoryElement}
                              onKeyDown={(e) => {
                                if (e.code === "Enter") handleCreateDirectory();
                                if (e.code === "Escape")
                                  handleCancelCreateDirectory();
                              }}
                              inputProps={{
                                style: {
                                  paddingLeft: 4,
                                },
                              }}
                              InputProps={{
                                sx: { borderRadius: 0 },
                                endAdornment: (
                                  <Stack direction="row" spacing={1} p={0.5}>
                                    <IconButton
                                      color="primary"
                                      size="small"
                                      onClick={handleCreateDirectory}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                    <IconButton
                                      color="error"
                                      size="small"
                                      onClick={handleCancelCreateDirectory}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Stack>
                                ),
                              }}
                              size="small"
                              fullWidth
                              placeholder={t("NewFolder")}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Box>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                  {createFile && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <ArticleIcon
                            sx={{
                              color: (theme) => theme.palette.grey[700],
                            }}
                          />
                          <Box flexGrow={1}>
                            <TextField
                              inputRef={createFileElement}
                              onKeyDown={(e) => {
                                if (e.code === "Enter") handleCreateFile();
                                if (e.code === "Escape")
                                  handleCancelCreateFile();
                              }}
                              inputProps={{
                                style: {
                                  paddingLeft: 4,
                                },
                              }}
                              InputProps={{
                                sx: { borderRadius: 0 },
                                endAdornment: (
                                  <Stack direction="row" spacing={1} p={0.5}>
                                    <IconButton
                                      color="primary"
                                      size="small"
                                      onClick={handleCreateFile}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                    <IconButton
                                      color="error"
                                      size="small"
                                      onClick={handleCancelCreateFile}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Stack>
                                ),
                              }}
                              size="small"
                              fullWidth
                              placeholder={t("NewFile")}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Box>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                  {files
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((file) => {
                      const fileCategory = getFileCategory(file.name);
                      return (
                        <TableRow
                          key={file.uniqueID}
                          hover
                          sx={{
                            cursor: "pointer",
                            bgcolor:
                              file === currentFile
                                ? "background.paper"
                                : "background.default",
                          }}
                          onClick={() => handleRowClick(file)}
                          onContextMenu={(e) => {
                            handleRightClick(e, file);
                          }}
                        >
                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              {file.type === 2 ? (
                                <FolderIcon
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.warning.light,
                                  }}
                                />
                              ) : (
                                <>
                                  {!fileCategory && (
                                    <InsertDriveFileIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}
                                    />
                                  )}
                                  {fileCategory === "text" && (
                                    <ArticleIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.grey[700],
                                      }}
                                    />
                                  )}
                                  {fileCategory === "pdf" && (
                                    <ImageIcon src={PdfIcon} />
                                  )}
                                  {fileCategory === "image" && (
                                    <Image
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.primary.dark,
                                      }}
                                    />
                                  )}
                                  {fileCategory === "video" && (
                                    <MovieIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.primary.dark,
                                      }}
                                    />
                                  )}
                                  {fileCategory === "audio" && (
                                    <VolumeUpIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.primary.dark,
                                      }}
                                    />
                                  )}
                                </>
                              )}
                              <Box flexGrow={1}>
                                {renameFile?.name === file.name ? (
                                  <TextField
                                    onKeyDown={(e) => {
                                      if (e.code === "Enter") handleRename();
                                      if (e.code === "Escape")
                                        handleCancelRename();
                                    }}
                                    inputProps={{
                                      style: {
                                        paddingLeft: 4,
                                      },
                                    }}
                                    InputProps={{
                                      sx: { borderRadius: 0 },
                                      endAdornment: (
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          p={0.5}
                                        >
                                          <IconButton
                                            color="primary"
                                            size="small"
                                            onClick={handleRename}
                                          >
                                            <CheckIcon />
                                          </IconButton>
                                          <IconButton
                                            color="error"
                                            size="small"
                                            onClick={handleCancelRename}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Stack>
                                      ),
                                    }}
                                    size="small"
                                    fullWidth
                                    placeholder={renameFile.name}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                ) : (
                                  file.name
                                )}
                              </Box>
                            </Stack>
                          </TableCell>
                          <TableCell width={200}>
                            {new Date(file.modifiedAt as string).toLocaleString(
                              "tr"
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {file.type === 0 && t("Unknown")}
                            {file.type === 1 && formatBytes(file.size)}
                            {file.type === 2 && t("Folder")}
                            {file.type === 3 && t("SymbolicLink")}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          {files.length > rowsPerPage && (
            <TablePagination
              mt="1px"
              overflow="unset !important"
              component={Box}
              width={1}
              count={files.length}
              onPageChange={handlePageChange}
              rowsPerPageOptions={[rowsPerPage]}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
