import { Stack } from "@mui/material";
import { useState } from "react";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { SAPSettings } from "../../../services/swagger";
import { useTranslation } from "react-i18next";

interface SapSetupProps {
  sapSettings?: SAPSettings;
  onSubmit: (sapConfig: SAPSettings) => void;
  onCancel: () => void;
}

export default function SapSetup(props: SapSetupProps) {
  const { t } = useTranslation("SetupPage");
  const [database, setDatabase] = useState(props.sapSettings?.database || "");
  const [server, setServer] = useState(props.sapSettings?.server || "");
  const [username, setUsername] = useState(props.sapSettings?.username || "");
  const [password, setPassword] = useState(props.sapSettings?.password || "");

  const handleSubmit = () => {
    props.onSubmit({
      enabled: true,
      database,
      password,
      server,
      username,
    });
  };

  return (
    <FormTemplate
      title={t("SapSetup.SapSetup")}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
      cancelButtonText={t("Back")}
      submitButtonText={t("Next")}
      disableHeaderCloseButton
    >
      <Stack spacing={1}>
        <FormInput
          required
          label={t("SapSetup.Server")}
          field="server"
          type="string"
          value={server}
          onChange={(value) => setServer(value as string)}
        />
        <FormInput
          required
          label={t("SapSetup.Database")}
          field="database"
          type="string"
          value={database}
          onChange={(value) => setDatabase(value as string)}
        />

        <FormInput
          required
          label={t("SapSetup.Username")}
          field="username"
          type="string"
          value={username}
          onChange={(value) => setUsername(value as string)}
        />
        <FormInput
          required
          label={t("SapSetup.Password")}
          field="password"
          inputType="password"
          type="string"
          value={password}
          onChange={(value) => setPassword(value as string)}
        />
      </Stack>
    </FormTemplate>
  );
}
