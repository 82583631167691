import { AppBar, Box, Paper, Stack } from "@mui/material";
import NotificationButton from "./NotificationButton";
import TitleWithLogo from "../molecules/TitleWithLogo";
import MenuButton from "../atoms/MenuButton";
import { sideBarWidth } from "./SideBar";
import { useGlobalContext } from "../../contexts/Global";
import useBreakpoint from "../../hooks/useBreakpoint";
import LanguageSwitcher from "../molecules/LanguageSwitcher";

export default function TopBar() {
  const { context, setContext } = useGlobalContext();
  const breakpoint = useBreakpoint();
  return (
    <Box
      component={Paper}
      borderRadius={0}
      display="flex"
      boxShadow="0px 2px 6px 0px #0000000D"
      alignItems="center"
      p={2}
      boxSizing="border-box"
      bgcolor="background.default"
      id="g-topbar"
      zIndex={1250}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        boxSizing="border-box"
        pr={4}
        width={breakpoint.md ? sideBarWidth : 30}
      >
        {breakpoint.md && <TitleWithLogo />}
        {!breakpoint.md && (
          <MenuButton
            onClick={() =>
              setContext({ ...context, sidebar: !context.sidebar })
            }
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center" flexGrow={1}>
        {!breakpoint.md && <TitleWithLogo />}
      </Box>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <LanguageSwitcher />
        <NotificationButton />
      </Stack>
    </Box>
  );
}
