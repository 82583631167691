import {
  Box,
  Button,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { FormEvent, useState } from "react";
import { Filter, QueryFilterTemplate } from "../../services/swagger";
import FilterInput, { allowedOperators } from "../molecules/FilterInput";
import AddIcon from "@mui/icons-material/Add";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { objectToParamString, paramStringToObject } from "../../helpers/params";
import SearchIcon from "@mui/icons-material/Search";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { setSearchParams } from "../../services/swagger/common";

interface FilterBoxProps {
  open: boolean;
  onClose?: () => void;
  filterTemplate: QueryFilterTemplate[];
  onChange?: (filters: Filter[]) => void;
}

export default function FilterBox(props: FilterBoxProps) {
  const { t } = useTranslation("FilterBox");
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<any>();
  const [filtersApplied, setFiltersApplied] = useState<Filter[]>([]);

  const handleApplyFilters = (
    e?: FormEvent<HTMLElement>,
    override?: Filter[]
  ) => {
    e?.preventDefault?.();
    // props.onClose?.();
    const filtersToApply = override || filtersApplied;
    props.onChange?.(filtersToApply);
  };

  const handleAddFilter = (filter: { name: string; value: string }) => {
    const newFilter = props.filterTemplate.find(
      (f) => f.field === filter.value
    );
    if (!newFilter) return;
    setFiltersApplied([
      ...filtersApplied,
      {
        field: newFilter.field,
        operator: allowedOperators[newFilter.valueType][0],
        type: newFilter.valueType,
        value: "",
      },
    ]);
    setAnchorEl(null);
  };

  const handleClearFilters = () => {
    const clearedFilters = filtersApplied.map((e) => ({
      field: e.field,
      operator: e.operator,
      type: e.type,
      value: "",
    }));
    setFiltersApplied(clearedFilters);
    handleApplyFilters(undefined, clearedFilters);
  };

  useDidMountEffect(() => {
    // if (loaded.current) return;
    // loaded.current = true;
    if (!props.open) return;
    if (props.filterTemplate.length < 1) return;
    const defaultFilters: Filter[] = [];
    for (const filter of props.filterTemplate) {
      if (!Array.isArray(filter.default)) continue;
      filter.default.forEach((defaultFilter) => {
        defaultFilters.push({
          field: filter.field,
          operator: defaultFilter.operator,
          value: defaultFilter.value,
          type: filter.valueType,
        });
      });
    }
    const filters = searchParams.get("filters")
      ? paramStringToObject(searchParams.get("filters") as any)
      : null;
    if (filters === null) {
      setSearchParams(
        (sp) => {
          sp.set("filters", objectToParamString(defaultFilters));
          return sp;
        },
        { replace: true }
      );
      return setFiltersApplied(defaultFilters);
    }
    setFiltersApplied(filters);
  }, [props.filterTemplate, props.open, searchParams]);

  return (
    <Collapse in={props.open} component={Paper} sx={{ mb: 1 }}>
      <Stack
        component="form"
        spacing={2}
        sx={{
          p: 2,
        }}
        onSubmit={handleApplyFilters}
      >
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {[
            ...(props.filterTemplate?.map((filter) => {
              return {
                name: filter.name,
                value: filter.field,
              };
            }) || []),
          ].map((filter) => {
            return (
              <MenuItem
                key={filter.value}
                sx={{ minWidth: 200 }}
                onClick={() => handleAddFilter(filter)}
              >
                {filter.name}
              </MenuItem>
            );
          })}
        </Menu>
        {filtersApplied.length > 0 && (
          <Box>
            <Grid container spacing={2}>
              {filtersApplied.map((filter, i) => {
                const template = props.filterTemplate.find(
                  (f) => f.field === filter.field
                );
                if (!template) return null;
                return (
                  <Grid
                    item
                    key={i}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2.4}
                    className="filter-input-grid-item"
                  >
                    <FilterInput
                      field={template.field}
                      label={template.name}
                      list={template.listOptions}
                      type={template.valueType}
                      value={filter.value}
                      onDelete={() => {
                        const newFiltersApplied = [...filtersApplied];
                        newFiltersApplied.splice(i, 1);
                        setFiltersApplied(newFiltersApplied);
                        handleApplyFilters(undefined, newFiltersApplied);
                      }}
                      onChange={(newFilter) => {
                        filter.operator = newFilter.operator;
                        filter.value = newFilter.value;
                        const newFilters = [...filtersApplied];
                        setFiltersApplied(newFilters);
                        if (
                          template.valueType === "boolean" ||
                          template.valueType === "list"
                        )
                          handleApplyFilters(undefined, newFilters);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}

        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={t("Clear")}>
            <Button
              variant="contained"
              color="error"
              onClick={handleClearFilters}
            >
              <ClearAllIcon />
            </Button>
          </Tooltip>
          <Stack direction="row" spacing={1}>
            <Tooltip title={t("AddFilter")}>
              <Button
                onClick={(e) => setAnchorEl(e.target)}
                variant="contained"
              >
                <AddIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t("Apply")}>
              <Button variant="contained" type="submit">
                <SearchIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Collapse>
  );
}
