import { useEffect, useState } from "react";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { Stack } from "@mui/material";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";
import { useTranslation } from "react-i18next";

export default function ForwardEmailSettingsPage() {
  const { t } = useTranslation("Settings");
  const { context } = useGlobalContext();
  const refresh = useRefresh();
  const [customerTicketsRecipients, setCustomerTicketsRecipients] = useState<
    string[]
  >([]);
  const [firewallRecipients, setFirewallRecipients] = useState<string[]>([]);
  const [itemsRecipients, setItemsRecipients] = useState<string[]>([]);
  const [itemReminderRecipients, setItemReminderRecipients] = useState<
    string[]
  >([]);
  const [qnapRecipients, setQnapRecipients] = useState<string[]>([]);
  const [sapRecipients, setSapRecipients] = useState<string[]>([]);
  const [subscriptionsRecipients, setSubscriptionsRecipients] = useState<
    string[]
  >([]);
  const [tasksRecipients, setTasksRecipients] = useState<string[]>([]);
  const [ticketsRecipients, setTicketsRecipients] = useState<string[]>([]);
  const [usersRecipients, setUsersRecipients] = useState<string[]>([]);
  const [vouchersRecipients, setVouchersRecipients] = useState<string[]>([]);

  const handleSubmit = async () => {
    if (!context.settings) return;
    await SettingsService.updateSettings({
      appSettings: {
        ...context.settings,
      },
    });
    refresh();
  };

  useEffect(() => {
    if (!context.settings) return;
    setCustomerTicketsRecipients(
      context.settings.forwardEmails.customerTickets
    );
    setFirewallRecipients(context.settings.forwardEmails.firewall);
    setSapRecipients(context.settings.forwardEmails.sap);
    setQnapRecipients(context.settings.forwardEmails.qnap);
    setItemsRecipients(context.settings.forwardEmails.items);
    setTasksRecipients(context.settings.forwardEmails.tasks);
    setUsersRecipients(context.settings.forwardEmails.users);
    setTicketsRecipients(context.settings.forwardEmails.tickets);
    setVouchersRecipients(context.settings.forwardEmails.vouchers);
    setItemReminderRecipients(context.settings.forwardEmails.itemReminder);
    setSubscriptionsRecipients(context.settings.forwardEmails.subscriptions);
  }, [context.settings]);

  useEffect(() => {
    if (!context.settings) return;
    context.settings.forwardEmails.customerTickets = customerTicketsRecipients;
    context.settings.forwardEmails.firewall = firewallRecipients;
    context.settings.forwardEmails.sap = sapRecipients;
    context.settings.forwardEmails.qnap = qnapRecipients;
    context.settings.forwardEmails.items = itemsRecipients;
    context.settings.forwardEmails.tasks = tasksRecipients;
    context.settings.forwardEmails.users = usersRecipients;
    context.settings.forwardEmails.tickets = ticketsRecipients;
    context.settings.forwardEmails.vouchers = vouchersRecipients;
    context.settings.forwardEmails.itemReminder = itemReminderRecipients;
    context.settings.forwardEmails.subscriptions = subscriptionsRecipients;
  }, [
    customerTicketsRecipients,
    firewallRecipients,
    itemsRecipients,
    itemReminderRecipients,
    qnapRecipients,
    sapRecipients,
    subscriptionsRecipients,
    tasksRecipients,
    ticketsRecipients,
    usersRecipients,
    vouchersRecipients,
  ]);

  return (
    <FormTemplate
      title={t("ForwardEmails")}
      disableHeaderCloseButton
      disableCancelButton
      submitButtonText={t("Save")}
      disableEnter
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <FormInput
          field="customerTicketsRecipients"
          label={t("CustomerTicketsRecipients")}
          value={customerTicketsRecipients}
          onChange={(value) => setCustomerTicketsRecipients(value as string[])}
          type="chip-list"
        />

        <FormInput
          field="firewallRecipients"
          label={t("FirewallRecipients")}
          value={firewallRecipients}
          onChange={(value) => setFirewallRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="itemsRecipients"
          label={t("ItemsRecipients")}
          value={itemsRecipients}
          onChange={(value) => setItemsRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="itemReminderRecipients"
          label={t("ItemReminderRecipients")}
          value={itemReminderRecipients}
          onChange={(value) => setItemReminderRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="qnapRecipients"
          label={t("QnapRecipients")}
          value={qnapRecipients}
          onChange={(value) => setQnapRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="sapRecipients"
          label={t("SapRecipients")}
          value={sapRecipients}
          onChange={(value) => setSapRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="subscriptionsRecipients"
          label={t("SubscriptionsRecipients")}
          value={subscriptionsRecipients}
          onChange={(value) => setSubscriptionsRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="tasksRecipients"
          label={t("TasksRecipients")}
          value={tasksRecipients}
          onChange={(value) => setTasksRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="ticketsRecipients"
          label={t("TicketsRecipients")}
          value={ticketsRecipients}
          onChange={(value) => setTicketsRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="usersRecipients"
          label={t("UsersRecipients")}
          value={usersRecipients}
          onChange={(value) => setUsersRecipients(value as string[])}
          type="chip-list"
        />
        <FormInput
          field="vouchersRecipients"
          label={t("VouchersRecipients")}
          value={vouchersRecipients}
          onChange={(value) => setVouchersRecipients(value as string[])}
          type="chip-list"
        />
      </Stack>
    </FormTemplate>
  );
}
