import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import HomePage from "./components/pages/Home";
import { Box, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useGlobalContext } from "./contexts/Global";
import LoginPage from "./components/pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterPage from "./components/pages/Register";
import { AuthService, SettingsService } from "./services/api";
import MyTicketsPage from "./components/pages/MyTickets";
import CreateMyTicketPage from "./components/pages/Tickets/CreateMyTicket";
import ManageTicketPage from "./components/pages/Tickets/ManageTicket";
import MainTemplate from "./components/templates/MainTemplate";
import { startServiceWorker, stopServiceWorker } from "./workers/main";
import SetupPage from "./components/pages/Setup";
import ForgotPasswordPage from "./components/pages/ForgotPassword";
import { lightTheme } from "./themes/akcaba";
import AccountInfoPage from "./components/pages/AccountInfo";
import useBreakpoint from "./hooks/useBreakpoint";
import "./config/i18n";
import ManagementTemplate from "./components/templates/ManagementTemplate";

function IndexRoute() {
  const navigate = useNavigate();
  const checkSetup = async () => {
    const res = await SettingsService.getSetup();
    if (!res) return navigate("/home");
    const setup = res.data.data;
    if (!setup) navigate("/setup");
    else navigate("/home");
  };

  useEffect(() => {
    checkSetup();
  }, []);
  return null;
}

export default function App() {
  const { context, setContext } = useGlobalContext();
  const breakpoint = useBreakpoint();
  // useSocket(true);

  useEffect(() => {
    const language = localStorage.getItem("language");
    if (!localStorage.getItem("authToken"))
      setContext({
        ...context,
        user: undefined,
        language: language || context.language,
      });
    AuthService.getSession().then((res) => {
      if (res?.data?.success) {
        setContext({
          ...context,
          user: res.data.data?.user,
          language: language || context.language,
        });
        if (res.data.data?.newToken)
          localStorage.setItem("authToken", res.data.data?.newToken);
        startServiceWorker();
      } else {
        stopServiceWorker();
      }
    });
  }, []);

  return (
    <Box height={1} width={1}>
      <ToastContainer
        position={breakpoint.md ? "top-center" : "bottom-center"}
        style={{ zIndex: 999999999 }}
        toastStyle={{
          whiteSpace: "pre-line",
        }}
      />
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
          <Routes>
            <Route index element={<IndexRoute />} />
            <Route path="/setup" element={<SetupPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/my-tickets">
              <Route index element={<MyTicketsPage />} />
              <Route path="create" element={<CreateMyTicketPage />} />
              <Route
                path=":_id"
                element={
                  <MainTemplate>
                    <ManageTicketPage />
                  </MainTemplate>
                }
              />
            </Route>
            <Route path="/account">
              <Route index element={<ForgotPasswordPage />} />
              <Route path="info" element={<AccountInfoPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/m/*" element={<ManagementTemplate />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  );
}
