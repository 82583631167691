import { useNavigate, useParams } from "react-router";
import { Box, Paper, Stack } from "@mui/material";
import { Department, User } from "../../../services/swagger";
import { useEffect, useState } from "react";
import { DepartmentService, UserService } from "../../../services/api";
import useRefresh from "../../../hooks/useRefresh";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import useConfirm from "../../../hooks/useConfirm";
import { useTranslation } from "react-i18next";
import ReorderableList from "../../molecules/ReorderableList";

export default function EditDepartmentPage() {
  const { t } = useTranslation("Department");
  const { _id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [administrative, setAdministrative] = useState<boolean>(false);
  const [users, setUsers] = useState<string[]>([]);
  const [userList, setUserList] = useState<User[]>([]);
  const [department, setDepartment] = useState<Department>();

  const loadUsers = async () => {
    const res = await UserService.getAllUsersList();
    if (res?.data?.data) setUserList(res.data.data.docs);
  };

  const loadDepartment = async () => {
    const res = await DepartmentService.getDepartment({
      id: _id || "",
    });
    if (res?.data?.data) setDepartment(res.data.data);
  };

  const refresh = useRefresh(loadDepartment);

  useEffect(() => {
    if (!department) return;
    setName(department.name);
    setDescription(department.description);
    setUsers(department.users.map((user) => user._id || ""));
    setAdministrative(department.administrative);
  }, [department]);

  useEffect(() => {
    loadDepartment();
    loadUsers();
  }, []);

  const handleCancel = () => {
    navigate("/m/department");
  };

  const handleSubmit = async () => {
    if (!_id) return;
    const res = await DepartmentService.updateDepartment({
      id: _id,
      updateDepartmentBody: {
        name,
        description,
        users: users,
        administrative,
      },
    });
    if (res?.data?.success) navigate("/m/department");
  };

  const { confirm: confirmSubmit, ConfirmDialog } = useConfirm();

  return (
    <Paper sx={{ height: 1 }}>
      <ConfirmDialog />
      <FormTemplate
        title={`${t("Edit")} ${department?.name}`}
        onSubmit={() => confirmSubmit(handleSubmit)}
        onCancel={handleCancel}
      >
        <Stack spacing={2}>
          <FormInput
            field="name"
            label={t("Name")}
            onChange={(v) => {
              setName(v as string);
            }}
            type="string"
            value={name}
            required
          />
          <FormInput
            field="description"
            label={t("Description")}
            onChange={(v) => {
              setDescription(v as string);
            }}
            type="string"
            value={description}
          />
          <FormInput
            field="administrative"
            label={t("Administrative")}
            value={administrative}
            type="boolean"
            onChange={(v) => setAdministrative(v as boolean)}
            required
          />
          <ReorderableList
            unique
            items={users}
            onChange={(list) => setUsers(list)}
            renderItem={(value) =>
              userList.find((user) => user._id === value)?.name
            }
            addList={userList
              .filter((user) => !users.includes(user._id as string))
              .map((user) => ({
                name: user.name,
                value: user._id || "",
              }))}
            label={t("Users")}
          />
        </Stack>
      </FormTemplate>
    </Paper>
  );
}
