import { Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import FormInput from "../molecules/FormInput";
import useRefresh from "../../hooks/useRefresh";
import FormTemplate from "../templates/FormTemplate";
import { UserRole } from "../../services/swagger";
import { UserService } from "../../services/api";
import { useTranslation } from "react-i18next";
import useEnums from "../../hooks/useEnums";

interface CreateUserDialogProps {
  open: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
}

export default function CreateUserDialog(props: CreateUserDialogProps) {
  const { t } = useTranslation("CreateUserDialog");
  const { roleDropDownItems } = useEnums();
  const refresh = useRefresh();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState<UserRole>("user");
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [emailConfirmation, setEmailConfirmation] = useState(false);

  useEffect(() => {
    if (!props.open) return;
    setName("");
    setEmail("");
    setCompany("");
    setPhone("");
    setUsername("");
    setPassword("");
    setRole("user");
  }, [props.open]);

  useEffect(() => {
    if (role !== "user") setOtpEnabled(true);
  }, [role]);

  const handleSubmit = async () => {
    const res = await UserService.createUser({
      createUserBody: {
        email,
        name,
        phone,
        company,
        password,
        role,
        username,
        emailConfirmation,
        otpEnabled,
      },
    });
    if (res?.data?.success) {
      refresh();
      props.onClose?.();
    }
  };
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormTemplate
        title={t("CreateUser")}
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      >
        <Stack spacing={2} minWidth={500}>
          <FormInput
            field="role"
            label={t("Role")}
            onChange={(v) => {
              setRole(v as UserRole);
            }}
            type="list"
            required
            value={role}
            list={roleDropDownItems}
          />
          <FormInput
            field="email"
            label={t("Email.Label")}
            onChange={(v) => {
              setEmail(v as string);
            }}
            placeholder={t("Email.Placeholder")}
            type="string"
            inputType="email"
            value={email}
            required
          />
          <FormInput
            field="username"
            label={t("Username")}
            value={username}
            placeholder={t("Username")}
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setUsername(v);
            }}
          />
          <FormInput
            field="company"
            label={t("Company")}
            value={company}
            placeholder={t("Company")}
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setCompany(v);
            }}
          />
          <FormInput
            field="phone"
            label={t("Phone")}
            value={phone}
            placeholder={t("Phone")}
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setPhone(v);
            }}
          />
          <FormInput
            field="name"
            label={t("FullName")}
            value={name}
            placeholder={t("FullName")}
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setName(v);
            }}
          />
          <FormInput
            field="password"
            label={t("Password")}
            value={password}
            placeholder={t("Password")}
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setPassword(v);
            }}
          />
          <FormInput
            field="emailConfirmation"
            label={t("EmailConfirmation")}
            value={emailConfirmation}
            type="switch"
            onChange={(v) => {
              if (typeof v === "boolean") setEmailConfirmation(v);
            }}
          />
          <FormInput
            field="otpEnabled"
            label={t("OTPEnabled")}
            value={otpEnabled}
            type="switch"
            onChange={(v) => {
              if (typeof v === "boolean") setOtpEnabled(v);
            }}
            disabled={role !== "user"}
          />
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}
