import { useTranslation } from "react-i18next";
import { DropDownItem } from "../components/molecules/Dropdown";

export default function useEnums() {
  const { t } = useTranslation("Enums");
  const roleDropDownItems: DropDownItem[] = [
    { name: t("Roles.User"), value: "user" },
    { name: t("Roles.Agent"), value: "agent" },
    { name: t("Roles.Admin"), value: "admin" },
  ];

  const roleObj = {
    user: t("Roles.User"),
    agent: t("Roles.Agent"),
    admin: t("Roles.Admin"),
  };

  const recordTypeDropDownItems: DropDownItem[] = [
    { name: t("RecordTypes.Blacklist"), value: "blacklist" },
    { name: t("RecordTypes.Whitelist"), value: "whitelist" },
  ];

  const recordTypeObj = {
    blacklist: t("RecordTypes.Blacklist"),
    whitelist: t("RecordTypes.Whitelist"),
  };

  const priorityDropDownItems: DropDownItem[] = [
    { name: t("Priorities.Low"), value: "low" },
    { name: t("Priorities.Medium"), value: "medium" },
    { name: t("Priorities.High"), value: "high" },
  ];

  const priorityObj = {
    low: t("Priorities.Low"),
    medium: t("Priorities.Medium"),
    high: t("Priorities.High"),
  };

  const statusDropDownItems: DropDownItem[] = [
    { name: t("Statuses.Not reviewed"), value: "Not reviewed" },
    { name: t("Statuses.Incomplete"), value: "Incomplete" },
    { name: t("Statuses.Pending confirmation"), value: "Pending confirmation" },
    { name: t("Statuses.Complete"), value: "Complete" },
  ];

  const statusObj = {
    "Not reviewed": t("Statuses.Not reviewed"),
    Incomplete: t("Statuses.Incomplete"),
    "Pending confirmation": t("Statuses.Pending confirmation"),
    Complete: t("Statuses.Complete"),
  };

  return {
    roleDropDownItems,
    roleObj,
    recordTypeDropDownItems,
    recordTypeObj,
    priorityDropDownItems,
    priorityObj,
    statusDropDownItems,
    statusObj,
  };
}
