import { Box, Container } from "@mui/material";
import MainTemplate from "../../templates/MainTemplate";
import CreateTicketPage from "./CreateTicket";
import useBreakpoint from "../../../hooks/useBreakpoint";

export default function CreateMyTicketPage() {
  const breakpoint = useBreakpoint();
  return (
    <MainTemplate>
      <Box
        height={1}
        pt={breakpoint.md ? 4 : undefined}
        pb={breakpoint.md ? 4 : undefined}
        bgcolor="background.paper"
      >
        {breakpoint.md ? (
          <Container
            disableGutters
            sx={{
              py: 1,
              px: 1,
            }}
          >
            <CreateTicketPage />
          </Container>
        ) : (
          <Box p={1}>
            <CreateTicketPage />
          </Box>
        )}
      </Box>
    </MainTemplate>
  );
}
