import { Chip, useTheme } from "@mui/material";
import { capitalizeFirstLetter } from "../../helpers/string";
import { TicketPriorityEnum, UserRole } from "../../services/swagger";
import useEnums from "../../hooks/useEnums";

interface UserRoleChipProps {
  role: UserRole;
}

export default function UserRoleChip(props: UserRoleChipProps) {
  const theme = useTheme();
  const { roleObj } = useEnums();
  const role = props.role;
  const colorObj = {
    user: "default",
    agent: "info",
    admin: "success",
  };
  return (
    <Chip
      sx={{ width: 70 }}
      color={colorObj[role] as any}
      label={capitalizeFirstLetter(roleObj[role])}
    />
  );
}
