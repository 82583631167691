import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  PopoverOrigin,
  PopoverVirtualElement,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { useMatch, useNavigate, useResolvedPath } from "react-router";
import { useGlobalContext } from "../../contexts/Global";
import { useTranslation } from "react-i18next";
import useBreakpoint from "../../hooks/useBreakpoint";

interface AccountPopoverProps {
  anchorEl?:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  open: boolean;
  onClose?: (closeSidebar?: boolean) => any;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>;
}

export default function AccountPopover(props: AccountPopoverProps) {
  const { t } = useTranslation("Navigation");
  const { context, setContext } = useGlobalContext();
  const breakpoint = useBreakpoint();
  const theme = useTheme();
  const navigate = useNavigate();
  const match = useMatch("/account/:page/*");
  const isHome = useResolvedPath("").pathname === "/home";

  const page = match?.params.page;

  const getMenuItemColor = (targetPage?: string) => {
    if (targetPage === page) return theme.palette.primary.light;
    else return theme.palette.text.primary;
  };

  const navigateToPage = (
    targetPage: "info" | "my-tickets" | "forgot-password"
  ) => {
    if (targetPage === "my-tickets") navigate(`/${targetPage}`);
    else navigate(`/account/${targetPage}`);
    if (!breakpoint.md) setContext({ ...context, sidebar: false });
    props.onClose?.(true);
  };

  const logout = async () => {
    if (!context.user) return;
    localStorage.removeItem("authToken");
    setContext({
      ...context,
      user: undefined,
      sidebar: breakpoint.md ? context.sidebar : false,
    });
    navigate("/home");
    props.onClose?.(true);
  };

  return (
    <Popover
      open={props.open}
      onClose={() => props.onClose?.()}
      anchorEl={props.anchorEl}
      anchorOrigin={props.anchorOrigin}
      transformOrigin={props.transformOrigin}
      container={document.body}
      sx={{ ...props.sx, zIndex: 9999999 }}
    >
      <MenuList sx={{ minWidth: 150, position: "relative" }}>
        {!isHome && (
          <MenuItem
            onClick={() => {
              navigate("/home");
              props.onClose?.(true);
            }}
          >
            {t("Home")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => navigateToPage("my-tickets")}
          sx={{ color: getMenuItemColor("my-tickets") }}
        >
          {t("MyTickets")}
        </MenuItem>
        <MenuItem
          onClick={() => navigateToPage("info")}
          sx={{ color: getMenuItemColor("info") }}
        >
          {t("AccountInfo")}
        </MenuItem>
        <MenuItem
          onClick={() => navigateToPage("forgot-password")}
          sx={{ color: getMenuItemColor("forgot-password") }}
        >
          {context.user ? t("ChangePassword") : t("ForgotPassword")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}> {t("Logout")}</MenuItem>
      </MenuList>
    </Popover>
  );
}
