import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import ActivateAccountDialog from "./ActivateAccountDialog";
import { useNavigate } from "react-router";
import { FormEvent, useState } from "react";
import { UserService } from "../../services/api";
import FormInput from "../molecules/FormInput";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useTranslation } from "react-i18next";

export default function RegisterForm() {
  const { t } = useTranslation("RegisterForm");
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [activateDialog, setActivateDialog] = useState(false);

  const register = async (e: FormEvent) => {
    e.preventDefault();
    const res = await UserService.createRegisteredUser({
      createRegisteredUserBody: {
        email,
        name,
        phone,
        company,
        password,
        username,
      },
    });
    if (res?.data?.success) {
      setActivateDialog(true);
    }
  };

  const handleCloseActivateDialog = (success: boolean) => {
    setActivateDialog(false);
    if (success) navigate("/login");
  };
  return (
    <Paper
      sx={{
        bgcolor: "background.default",
        pt: 2,
        p: { xs: 4, md: 8 },
        maxWidth: { xs: 350, sm: 500, md: 560 },
        minWidth: { xs: 300, sm: 500, md: 560 },
        borderRadius: { xs: 0, md: 8 },
      }}
      component="form"
      onSubmit={register}
    >
      <ActivateAccountDialog
        open={activateDialog}
        handleClose={handleCloseActivateDialog}
      />
      <Typography color="primary" variant="h2" fontWeight="bold" fontSize={32}>
        {t("Register")}
      </Typography>
      <Typography fontWeight="light" mb={4}>
        {t("ToContactOurSupport")}
      </Typography>
      <Stack spacing={1}>
        <FormInput
          type="string"
          required
          inputType="email"
          field="email"
          label={t("Email")}
          placeholder="example@email.com"
          value={email}
          onChange={(v) => setEmail(v as string)}
        />
        <FormInput
          type="string"
          required
          field="company"
          label={t("Company")}
          placeholder={t("Company")}
          value={company}
          onChange={(v) => setCompany(v as string)}
        />
        <FormInput
          type="string"
          field="phone"
          label={t("Phone.Label")}
          placeholder={t("Phone.Placeholder")}
          value={phone}
          onChange={(v) => setPhone(v as string)}
        />
        <Stack direction={breakpoint.md ? "row" : "column"} spacing={1}>
          <FormInput
            type="string"
            required
            field="name"
            label={t("Name.Label")}
            placeholder={t("Name.Placeholder")}
            value={name}
            onChange={(v) => setName(v as string)}
          />
          <FormInput
            type="string"
            required
            field="username"
            label={t("Username.Label")}
            value={username}
            placeholder={t("Username.Placeholder")}
            onChange={(v) => setUsername(v as string)}
          />
        </Stack>
        <FormInput
          inputType="password"
          type="string"
          required
          field="password"
          label={t("Password")}
          placeholder={t("Password")}
          value={password}
          onChange={(v) => setPassword(v as string)}
        />
      </Stack>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 2, borderRadius: 12, height: 50, mb: 2 }}
      >
        {t("Register")}
      </Button>
      <Box>
        <Typography fontWeight="light">
          {t("AlreadyHaveAccount")}
          <Typography
            onClick={() => navigate("/login")}
            color="primary"
            component="span"
            fontSize={16}
            ml={4}
            sx={{ cursor: "pointer" }}
          >
            <u>{t("Login")}</u>
          </Typography>
        </Typography>
      </Box>
    </Paper>
  );
}
