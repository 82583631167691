import { Box, Divider, Paper, Stack, Tab, Tabs } from "@mui/material";
import TabPanel from "../../atoms/TabPanel";
import { useMatch, useNavigate } from "react-router";
import OpenAISettingsPage from "./OpenAISettings";
import { useEffect } from "react";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";
import SapSettingsPage from "./SapSettings";
import SmtpSettingsPage from "./SmtpSettings";
import MessagesSettingsPage from "./MessageSettings";
import ForwardEmailSettingsPage from "./ForwardEmailSettings";
import FtpSettingsPage from "./FtpSettings";
import { useTranslation } from "react-i18next";
import GeneralSettingsPage from "./GeneralSettings";

const defaultSection = "messages";

export default function SettingsPage() {
  const { t } = useTranslation("Settings");
  const { context, setContext } = useGlobalContext();
  const match = useMatch("/m/settings/:section");
  const navigate = useNavigate();
  const section = match?.params.section;

  const loadSettings = async () => {
    const res = await SettingsService.getSettings();
    if (!res?.data?.data) return;
    const settings = res.data.data;
    setContext({ ...context, settings });
  };

  useEffect(() => {
    loadSettings();
  }, []);

  useRefresh(loadSettings);

  useEffect(() => {
    if (!match?.params.section) navigate(`/m/settings/${defaultSection}`);
  }, [match?.params.section]);

  const handleChangeSection = (e: any, value: string) => {
    navigate(`/m/settings/${value}`);
  };

  return (
    <Paper sx={{ height: 1, bgcolor: "background.default" }}>
      <Stack height={1}>
        <Tabs
          value={section}
          onChange={handleChangeSection}
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons="auto"
        >
          <Tab label={t("Messages")} value="messages" />
          <Tab label={t("Forwards")} value="forwards" />
          <Tab label={t("SMTP")} value="smtp" />
          <Tab label={t("OpenAI")} value="openai" />
          <Tab label={t("FTP")} value="ftp" />
          <Tab label={t("SAP")} value="sap" />
          <Tab label={t("General")} value="general" />
        </Tabs>
        <Divider />
        <Box flexGrow={1} overflow="auto">
          <TabPanel value={section} index="messages">
            <MessagesSettingsPage />
          </TabPanel>
          <TabPanel value={section} index="forwards">
            <ForwardEmailSettingsPage />
          </TabPanel>
          <TabPanel value={section} index="openai">
            <OpenAISettingsPage />
          </TabPanel>
          <TabPanel value={section} index="ftp">
            <FtpSettingsPage />
          </TabPanel>
          <TabPanel value={section} index="sap">
            <SapSettingsPage />
          </TabPanel>
          <TabPanel value={section} index="smtp">
            <SmtpSettingsPage />
          </TabPanel>
          <TabPanel value={section} index="general">
            <GeneralSettingsPage />
          </TabPanel>
        </Box>
      </Stack>
    </Paper>
  );
}
