import QuizIcon from "@mui/icons-material/Quiz";
import IconAvatar from "./IconAvatar";
import { useTranslation } from "react-i18next";

interface AskAiButtonProps {
  aiResponseMode?: boolean;
  onAskAi?: () => any;
}

export default function AskAiButton(props: AskAiButtonProps) {
  const { t } = useTranslation("Atoms");
  return (
    <IconAvatar
      icon={QuizIcon}
      size={30}
      onClick={props.onAskAi}
      tooltipTitle={t("AskAiTooltip")}
    />
  );
}
