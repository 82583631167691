import { createTheme, Theme } from "@mui/material";

const borderRadius = 8;

export const lightTheme: Theme = createTheme({
  typography: {
    fontFamily: "Lexend",
    fontSize: 12,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        sx: { bgcolor: "white" },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: { bgcolor: "#F2F2F2", borderRadius: 1, p: 1.2 },
        inputProps: { style: { padding: 2 } },
      },
    },
  },
  shadows: [
    "none",
    "0px 4px 10px 0px #0000001A",
    "0px 5px 22px 0px #0000001A",
    "0px 6px 24px 0px #0000001A",
    "0px 7px 26px 0px #0000001A",
    "0px 8px 28px 0px #0000001A",
    "0px 9px 30px 0px #0000001A",
    "0px 10px 32px 0px #0000001A",
    "0px 11px 34px 0px #0000001A",
    "0px 12px 36px 0px #0000001A",
    "0px 13px 38px 0px #0000001A",
    "0px 14px 40px 0px #0000001A",
    "0px 15px 42px 0px #0000001A",
    "0px 16px 44px 0px #0000001A",
    "0px 17px 46px 0px #0000001A",
    "0px 18px 48px 0px #0000001A",
    "0px 19px 50px 0px #0000001A",
    "0px 20px 52px 0px #0000001A",
    "0px 21px 54px 0px #0000001A",
    "0px 22px 56px 0px #0000001A",
    "0px 23px 58px 0px #0000001A",
    "0px 24px 60px 0px #0000001A",
    "0px 25px 62px 0px #0000001A",
    "0px 26px 64px 0px #0000001A",
    "0px 27px 66px 0px #0000001A",
  ],
  shape: {
    borderRadius,
  },
  palette: {
    background: {
      paper: "#EEF2F6",
    },
    primary: {
      dark: "#1E88E5",
      main: "#42A5F5",
      light: "#64B5F6",
      contrastText: "white",
    },
    success: {
      main: "#34C759",
      light: "#3EEF64",
      dark: "#2DA34F",
      contrastText: "#FFFFFF",
    },
  },
});
