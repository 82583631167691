import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined";
import IconAvatar from "./IconAvatar";
import { useTranslation } from "react-i18next";

interface TranslateButtonProps {
  aiResponseMode?: boolean;
  onTranslate?: () => any;
}

export default function TranslateButton(props: TranslateButtonProps) {
  const { t } = useTranslation("Atoms");
  return (
    <IconAvatar
      icon={GTranslateOutlinedIcon}
      size={30}
      onClick={props.onTranslate}
      tooltipTitle={t("TranslateTooltip")}
    />
  );
}
