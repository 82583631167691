import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import IconAvatar from "./IconAvatar";
import { useTranslation } from "react-i18next";

interface SummarizeButtonProps {
  aiResponseMode?: boolean;
  onSummarize?: () => any;
}

export default function SummarizeButton(props: SummarizeButtonProps) {
  const { t } = useTranslation("Atoms");
  return (
    <IconAvatar
      icon={TextSnippetIcon}
      size={30}
      onClick={props.onSummarize}
      tooltipTitle={t("SummarizeTooltip")}
    />
  );
}
