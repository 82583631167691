import { Box, Grid, Paper, Typography } from "@mui/material";
import { DashboardData } from "../../../services/swagger";
import { formatMilliseconds } from "../../../helpers/date";
import {
  AvgResolutionTimeSvg,
  AvgResponseTimeSvg,
  SolvedTicketsSvg,
  UnsolvedTicketsSvg,
} from "./DashboardStatsSvg";
import { useTranslation } from "react-i18next";

interface DashboardTicketStatsProps {
  data: DashboardData;
}

interface StatTabProps {
  label: string;
  value: string | number;
  icon?: any;
}

function StatTab(props: StatTabProps) {
  return (
    <Paper
      sx={{
        bgcolor: "primary.main",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        minHeight: { xs: 140, md: 100 },
      }}
    >
      <Box color="primary.contrastText">
        <Typography fontWeight={500}>{props.label}</Typography>
        <Typography fontWeight="light">{props.value || "Not Found"}</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {props.icon}
      </Box>
    </Paper>
  );
}

export default function DashboardTicketStats(props: DashboardTicketStatsProps) {
  const { t } = useTranslation("Dashboard");
  const { data } = props;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <StatTab
            label={t("AvgResponseTime")}
            value={formatMilliseconds(data.averageFirstResponseTime, {
              ignoreMilliseconds: true,
            })}
            icon={<AvgResponseTimeSvg />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatTab
            label={t("AvgResolutionTime")}
            value={formatMilliseconds(data.averageResolutionTime, {
              ignoreMilliseconds: true,
            })}
            icon={<AvgResolutionTimeSvg />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatTab
            label={t("UnsolvedTickets")}
            value={`${data.unsolvedTickets} ${t("Tickets")}`}
            icon={<UnsolvedTicketsSvg />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatTab
            label={t("SolvedTickets")}
            value={`${data.solvedTickets} ${t("Tickets")}`}
            icon={<SolvedTicketsSvg />}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
