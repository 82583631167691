import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MainTemplate from "../templates/MainTemplate";
import { useGlobalContext } from "../../contexts/Global";
import { useNavigate } from "react-router";
import HomeCardModule from "../molecules/HomeCardModule";
import HomeStep from "../molecules/HomeStep";
import useBreakpoint from "../../hooks/useBreakpoint";
import appConfig from "../../config/app-config";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const { t } = useTranslation("HomePage");
  const { context } = useGlobalContext();
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  const handleOpenTicket = () => {
    if (context.user) navigate("/my-tickets/create");
    else navigate("/login");
  };

  return (
    <MainTemplate>
      <Box position="relative">
        <Box
          sx={{
            height: 1100,
            width: 1,
            top: -300,
            position: "absolute",
            display: "flex",
            alignItems: { xs: "center", md: "flex-start" },
            overflowX: "hidden",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Box
            position="absolute"
            sx={{
              position: { xs: "relative", md: "absolute" },
              right: breakpoint.md ? -230 : undefined,
              top: breakpoint.md ? undefined : -250,
            }}
          >
            <img
              src={require("../../assets/grey-circles.png")}
              style={{
                height: breakpoint.md ? 1072 : 700,
              }}
            />
          </Box>
        </Box>
        <Container
          sx={{
            boxSizing: "border-box",
            height: breakpoint.md ? 480 : undefined,
            position: "relative",
          }}
        >
          <Box
            display="flex"
            flexDirection={breakpoint.md ? "row-reverse" : "column"}
            justifyContent="space-between"
          >
            <Box display="flex" justifyContent="center">
              <img
                src={require("../../assets/boy-girl-sitting.png")}
                style={{ height: breakpoint.md ? 483 : 300 }}
              />
            </Box>
            <Box
              position="relative"
              zIndex={2}
              sx={{
                py: { xs: 4, md: 12 },
                boxSizing: "border-box",
                width: { xs: 1, md: 1 / 2 },
              }}
            >
              <Typography
                variant="h3"
                fontWeight="bold"
                color="primary"
                mb={4}
                sx={{
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                {t("MainHeader")}
              </Typography>
              <Typography
                mb={4}
                fontSize={16}
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  textShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
                fontWeight="light"
              >
                {t("SubHeader")}
              </Typography>
              <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                <Button
                  sx={{
                    height: 70,
                    width: 220,
                    fontSize: "large",
                    borderRadius: 8,
                  }}
                  variant="contained"
                  onClick={handleOpenTicket}
                >
                  {t("OpenTicketButton")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container sx={{ mb: 2 }}>
        <Typography
          color="primary"
          fontWeight={600}
          sx={{ fontSize: { xs: 24, md: 32 } }}
          align="center"
          p={4}
        >
          {appConfig.name} {t("CustomerSupport")}
        </Typography>
        <Grid container>
          <Grid container spacing={7} mb={10}>
            <Grid item xs={12} lg={4} position="relative">
              <HomeCardModule title={t("ModuleOne.title")}>
                {t("ModuleOne.body")}
              </HomeCardModule>
            </Grid>
            <Grid item xs={12} lg={4} position="relative">
              <HomeCardModule title={t("ModuleTwo.title")}>
                {t("ModuleTwo.body")}
              </HomeCardModule>
            </Grid>
            <Grid item xs={12} lg={4} position="relative">
              <HomeCardModule title={t("ModuleThree.title")}>
                {t("ModuleThree.body")}
              </HomeCardModule>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: "primary.main" }} />
        <Box sx={{ p: { xs: 0, md: 8 } }}>
          <Stack spacing={4} mb={4}>
            <Typography
              color="primary"
              fontWeight={600}
              sx={{ fontSize: { xs: 24, md: 32 } }}
              align="center"
              p={4}
            >
              {t("HowToCreateTicket")}
            </Typography>
            <HomeStep stepNo={1} align="left">
              {t("StepOne")}
            </HomeStep>
            <HomeStep stepNo={2} align="right">
              {t("StepTwo")}
            </HomeStep>
            <HomeStep stepNo={3} align="left">
              {t("StepThree")}
            </HomeStep>
            <Box textAlign="center">
              <Button
                sx={{
                  height: 70,
                  width: 220,
                  borderRadius: 8,
                  fontSize: "large",
                  borderWidth: 3,
                }}
                variant="outlined"
                onClick={handleOpenTicket}
              >
                {t("GetStarted")}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Container>
    </MainTemplate>
  );
}
