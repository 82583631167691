import { Box, Button, Drawer, Stack, useTheme } from "@mui/material";
import { useMatch, useNavigate } from "react-router";
import AccountIconButton from "../molecules/AccountIconButton";
import Authorized from "../atoms/Authorized";
import Unauthorized from "../atoms/Unauthorized";
import appConfig from "../../config/app-config";
import MenuButton from "../atoms/MenuButton";
import { useRef } from "react";
import { useGlobalContext } from "../../contexts/Global";
import useBreakpoint from "../../hooks/useBreakpoint";
import MainSideBar, { sideBarWidth } from "./MainSideBar";
import LanguageSwitcher from "../molecules/LanguageSwitcher";
import { useTranslation } from "react-i18next";

export default function NavBar() {
  const { t } = useTranslation("Navigation");
  const { context, setContext } = useGlobalContext();
  const breakpoint = useBreakpoint();
  const anchorEl = useRef<any>(null);
  const navigate = useNavigate();
  const match = useMatch("/:page/*");
  const page = match?.params.page;
  const theme = useTheme();

  const getColor = (value: string) => {
    if (page === value) return theme.palette.primary.light;
    else return theme.palette.text.disabled;
  };

  const getBgColor = (value: string) => {
    if (page === value) return theme.palette.background.paper;
    else return undefined;
  };

  return (
    <Box
      sx={{
        pr: 2,
        pl: 2,
        width: 1,
        height: 76,
        boxSizing: "border-box",
        display: "flex",
        bgcolor: "background.default",
        boxShadow: "0px 4px 20px 0px #0000001A",
        justifyContent: !breakpoint.md ? "space-between" : undefined,
        zIndex: context.sidebar && !breakpoint.md ? 9999 : undefined,
      }}
    >
      <img
        src={appConfig.logo.image}
        style={{
          height: "100%",
          width: 110,
          cursor: "pointer",
          objectFit: "cover",
        }}
        onClick={() => window.open(appConfig.logo.link, "_blank")}
      />
      {breakpoint.md ? (
        <Stack
          height={1}
          flexGrow={1}
          spacing={2}
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <LanguageSwitcher />

          <Button
            onClick={() => navigate("/home")}
            sx={{
              p: 2,
              color: getColor("home"),
              paddingX: 4,
              bgcolor: getBgColor("home"),
            }}
          >
            {t("Home")}
          </Button>
          <Authorized role="user">
            <Button
              onClick={() => navigate("/my-tickets")}
              sx={{
                p: 2,
                color: getColor("my-tickets"),
                paddingX: 4,
                bgcolor: getBgColor("my-tickets"),
              }}
            >
              {t("MyTickets")}
            </Button>
          </Authorized>
          <Authorized role="agent">
            <Button
              onClick={() => navigate("/m/dashboard")}
              sx={{
                p: 2,
                color: getColor("m"),
                paddingX: 4,
                bgcolor: getBgColor("m"),
              }}
            >
              {t("Management")}
            </Button>
          </Authorized>
          <Authorized role="user">
            <AccountIconButton />
          </Authorized>
          <Unauthorized>
            <Button
              onClick={() => navigate("/login")}
              sx={{
                p: 2,
                color: getColor("login"),
                paddingX: 4,
                bgcolor: getBgColor("login"),
              }}
            >
              {t("Login")}
            </Button>
            <Button
              onClick={() => navigate("/register")}
              sx={{
                p: 2,
                color: getColor("register"),
                paddingX: 4,
                bgcolor: getBgColor("register"),
              }}
            >
              {t("Register")}
            </Button>
          </Unauthorized>
        </Stack>
      ) : (
        <>
          <Stack direction="row" alignItems="center" spacing={1}>
            <LanguageSwitcher />
            <MenuButton
              ref={anchorEl}
              onClick={() =>
                setContext({ ...context, sidebar: !context.sidebar })
              }
            />
          </Stack>
          <Drawer
            open={context.sidebar}
            onClose={() => setContext({ ...context, sidebar: false })}
            elevation={4}
            anchor="right"
            variant="temporary"
            sx={{
              transitionDuration: "200ms",
              width: context.sidebar ? sideBarWidth : 0,
              flexShrink: 0,
              [`& .MuiDrawer-Paper`]: {
                height: "100%",
                boxSizing: " border-box",
                background: (theme) =>
                  breakpoint.md
                    ? theme.palette.background.paper
                    : "transparent",
                borderRight: "none",
                position: "relative",
                width: sideBarWidth,
              },
            }}
          >
            <MainSideBar />
          </Drawer>
        </>
      )}
    </Box>
  );
}
