import { Box, Container } from "@mui/material";
import CreateTicketPage from "./CreateTicket";
import useBreakpoint from "../../../hooks/useBreakpoint";

export default function CreateManagementTicketPage() {
  const breakpoint = useBreakpoint();
  return (
    <Box>
      {breakpoint.md ? (
        <Container
          disableGutters
          sx={{
            py: 1,
            px: 1,
          }}
        >
          <CreateTicketPage />
        </Container>
      ) : (
        <CreateTicketPage />
      )}
    </Box>
  );
}
