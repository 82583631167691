import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FilterValueType,
  PaginatedListAny,
  QueryFilterTemplate,
  SortDirection,
} from "../../services/swagger";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import SortingArrow from "../atoms/SortingArrow";
import { ReactNode, useMemo } from "react";

export interface DataTableHeader {
  name: string;
  field: string;
  type: FilterValueType | "other";
  renderHeaderCell?: () => ReactNode;
  renderRowCell?: (row: any) => ReactNode;
}

interface DataTableProps {
  headers: DataTableHeader[];
  content: PaginatedListAny;
  pagination?: boolean;
  filterTemplate: QueryFilterTemplate[];
}

export default function DataTable(props: DataTableProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortField = useMemo(
    () => searchParams.get("sortField") || "",
    [searchParams]
  );
  const sortDirection = useMemo(
    () => searchParams.get("sortDirection") || SortDirection.None,
    [searchParams]
  );
  const page = useMemo(
    () => parseInt(searchParams.get("page") || "0"),
    [searchParams]
  );
  const rowsPerPage = useMemo(
    () => parseInt(searchParams.get("rowsPerPage") || "25"),
    [searchParams]
  );
  const { t } = useTranslation("DataTable");

  const handlePageChange = (event: any, page: number) => {
    setSearchParams(
      (sp) => {
        sp.set("page", page.toString());
        return sp;
      },
      { replace: true }
    );
  };

  const handleRowsPerPageChange = (event: any) => {
    setSearchParams(
      (sp) => {
        sp.set("rowsPerPage", event.target.value.toString());
        return sp;
      },
      { replace: true }
    );
  };

  const handleClickSort = (field: string) => {
    setSearchParams(
      (sp) => {
        if (field !== sortField) {
          sp.set("sortDirection", SortDirection.Desc);
          sp.set("sortField", field);
          return sp;
        }
        if (sortDirection === SortDirection.None)
          sp.set("sortDirection", SortDirection.Desc);
        if (sortDirection === SortDirection.Desc)
          sp.set("sortDirection", SortDirection.Asc);
        if (sortDirection === SortDirection.Asc)
          sp.set("sortDirection", SortDirection.Desc);
        return sp;
      },
      { replace: true }
    );
  };

  return (
    <Stack flexGrow={1} overflow="hidden" component={Paper}>
      <TableContainer
        sx={{
          flexGrow: 1,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow sx={{ height: 50 }}>
              {props.headers.map((header) => {
                const sortable = Boolean(
                  props.filterTemplate.find(
                    (filter) => filter.field === header.field
                  )?.sortable
                );
                if (header.renderHeaderCell) return header.renderHeaderCell();
                else
                  return (
                    <TableCell
                      key={header.field}
                      align="left"
                      width={(header.type === "date" && 140) || undefined}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography fontSize="inherit">
                          {header.name}
                        </Typography>
                        {sortable && (
                          <SortingArrow
                            onClick={() => handleClickSort(header.field)}
                            activeField={sortField}
                            field={header.field}
                            sort={sortDirection as SortDirection}
                          />
                        )}
                      </Stack>
                    </TableCell>
                  );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.content.count > 0 ? (
              props.content.docs.map((item, index) => {
                return (
                  <TableRow
                    key={item._id || index}
                    hover
                    sx={{
                      height: 60,
                      td: {
                        fontWeight: item.unRead ? "bold" : undefined,
                      },
                    }}
                  >
                    {props.headers.map((header) => {
                      if (header.renderRowCell)
                        return header.renderRowCell(item);
                      return (
                        <TableCell
                          key={header.field}
                          sx={{ textOverflow: "ellipsis" }}
                        >
                          {(header.type === FilterValueType.Date &&
                            new Date(item[header.field]).toLocaleString(
                              "tr"
                            )) ||
                            (header.type === FilterValueType.Boolean &&
                              item[header.field].toString()) ||
                            item[header.field]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={props.headers.length}
                  align="center"
                  sx={{ borderBottom: "none", p: 2 }}
                >
                  {t("NoResultsFound")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.pagination && (
        <TablePagination
          mt="1px"
          overflow="unset !important"
          component={Box}
          count={props.content.count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[25, 50, 200]}
          page={page}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={t("MaxRows")}
          labelDisplayedRows={(info) => t("DisplayedRows", { info })}
        />
      )}
    </Stack>
  );
}
