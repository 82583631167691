import { Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { DropDownItem } from "../molecules/Dropdown";
import FormInput from "../molecules/FormInput";
import useRefresh from "../../hooks/useRefresh";
import FormTemplate from "../templates/FormTemplate";
import { User } from "../../services/swagger";
import { DepartmentService } from "../../services/api";
import { useTranslation } from "react-i18next";

interface CreateDepartmentDialogProps {
  open: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
}

export default function CreateDepartmentDialog(
  props: CreateDepartmentDialogProps
) {
  const { t } = useTranslation("CreateDepartmentDialog");
  const refresh = useRefresh();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [administrative, setAdministrative] = useState(false);

  useEffect(() => {
    if (!props.open) return;
    setName("");
    setDescription("");
    setAdministrative(false);
  }, [props.open]);

  const handleSubmit = async () => {
    const res = await DepartmentService.createDepartment({
      createDepartmentBody: {
        name,
        description,
        users: users.map((user) => user._id || ""),
        administrative,
      },
    });
    if (res?.data?.success) {
      refresh();
      props.onClose?.();
    }
  };
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormTemplate
        title={t("CreateDepartment")}
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      >
        <Stack spacing={2} minWidth={500}>
          <FormInput
            field="name"
            label={t("Name")}
            onChange={(v) => {
              setName(v as string);
            }}
            type="string"
            value={name}
            required
          />
          <FormInput
            field="description"
            label={t("Description")}
            onChange={(v) => {
              setDescription(v as string);
            }}
            type="string"
            value={description}
          />
          <FormInput
            field="administrative"
            label={t("Administrative")}
            value={administrative}
            type="boolean"
            onChange={(v) => setAdministrative(v as boolean)}
            required
          />
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}
