interface ImageIconProps {
  src: string;
}

export default function ImageIcon(props: ImageIconProps) {
  return (
    <img
      src={props.src}
      loading="lazy"
      style={{
        height: 22.8,
        width: 22.8,
        objectFit: "cover",
      }}
    />
  );
}
