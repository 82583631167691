import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OpenAiService } from "../../services/api";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface AskAiDialogProps {
  openDialog: boolean;
  isDialogLoading: boolean;
  onClose: () => any;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  content: string;
}

export default function AskAiDialog(props: AskAiDialogProps) {
  const { t } = useTranslation("AskAiDialog");

  return (
    <>
      <Dialog
        open={props.openDialog}
        onClose={props.onClose}
        PaperProps={{
          component: "form",
          onSubmit: props.onSubmit,
        }}
      >
        <DialogTitle>{t("AskAI")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>{t("Description")}</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="aiprompt"
            name="aiprompt"
            label={t("PromptLabel")}
            type="text"
            fullWidth
            variant="outlined"
            multiline
            defaultValue={props.content}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>{t("Cancel")}</Button>
          <Button type="submit">{t("AskAI")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
