import { Box, Dialog, Divider, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";
import useDownload from "../../hooks/useDownload";
import Download from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import { ViewableMediaType } from "../../helpers/file";
import useBreakpoint from "../../hooks/useBreakpoint";

interface TransformImageProps {
  src: string;
  fullscreen: boolean;
}

interface MediaDialogProps {
  open: boolean;
  downloadPath?: string;
  src: string;
  filename: string;
  type: ViewableMediaType;
  onClose?: () => void;
}

function TransformImage(props: TransformImageProps) {
  const { centerView } = useControls();
  useEffect(centerView, [props.fullscreen]);
  return (
    <TransformComponent
      wrapperClass="zoomable-image"
      wrapperStyle={{
        height: "100%",
      }}
    >
      <img
        src={props.src}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
      />
    </TransformComponent>
  );
}

export default function MediaDialog(props: MediaDialogProps) {
  const { open, src, downloadPath, filename } = props;
  const breakpoint = useBreakpoint();
  const [fullscreen, setFullscreen] = useState(!breakpoint.md);

  const { download, DownloadLink } = useDownload();

  const handleDownload = () => {
    if (downloadPath) download(downloadPath);
  };

  const handleClose = () => {
    setFullscreen(false);
    props.onClose?.();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullscreen}
      keepMounted={false}
    >
      <DownloadLink />
      <Box
        height={1}
        display="flex"
        flexDirection="column"
        minHeight={400}
        sx={{ minWidth: { xs: undefined, md: 400 } }}
      >
        <Box
          sx={{ p: 2, boxSizing: "border-box" }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontWeight={600}>{filename}</Typography>
          <Box display="flex" alignItems="center">
            {downloadPath && (
              <IconButton onClick={handleDownload} color="primary">
                <Download />
              </IconButton>
            )}
            <IconButton
              onClick={() => setFullscreen((e) => !e)}
              color="warning"
            >
              {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            <IconButton onClick={handleClose} color="error">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        {open && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            flexGrow={1}
          >
            {props.type === "image" && (
              <TransformWrapper disablePadding centerOnInit>
                {({ ...rest }) => (
                  <TransformImage src={src} fullscreen={fullscreen} />
                )}
              </TransformWrapper>
            )}
            {props.type === "audio" && (
              <audio controls autoPlay>
                <source src={props.src} />
              </audio>
            )}
            {props.type === "video" && (
              <video width="100%" height="100%" controls autoPlay>
                <source src={props.src} type="video/mp4" />
              </video>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
