import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  IconProps,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface DeleteButtonProps {
  boxProps?: BoxProps;
  iconButtonProps?: IconButtonProps;
  avatarProps?: AvatarProps;
  iconProps?: IconProps;
  size?: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function DeleteButton(props: DeleteButtonProps) {
  const { t } = useTranslation("Atoms");
  return (
    <Tooltip title={t("DeleteTooltip")}>
      <Box {...props.boxProps}>
        <IconButton
          size={"small"}
          onClick={props.onClick}
          sx={{ p: 0 }}
          {...props.iconButtonProps}
        >
          <Avatar
            color="error"
            variant="rounded"
            sx={{
              bgcolor: (theme) => theme.palette.error.main,
              height: props.size ?? 30,
              width: props.size ?? 30,
            }}
            {...props.avatarProps}
          >
            <DeleteIcon
              fontSize={"small"}
              sx={{
                ...props.iconProps?.sx,
                color: (theme) => theme.palette.error.contrastText,
              }}
            />
          </Avatar>
        </IconButton>
      </Box>
    </Tooltip>
  );
}
