import { Stack, Typography } from "@mui/material";
import { TicketStatusEnum } from "../../services/swagger";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useEnums from "../../hooks/useEnums";

interface TicketStatusProps {
  status: TicketStatusEnum;
}

export default function TicketStatus(props: TicketStatusProps) {
  const { statusObj } = useEnums();
  const statusIcon = {
    "Not reviewed": <ErrorRoundedIcon sx={{ color: "text.secondary" }} />,
    Incomplete: <HourglassBottomRoundedIcon sx={{ color: "#FFCA0E" }} />,
    "Pending confirmation": (
      <WatchLaterRoundedIcon sx={{ color: "warning.main" }} />
    ),
    Complete: <CheckCircleIcon sx={{ color: "success.main" }} />,
  };
  const icon = statusIcon[props.status];

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ fontWeight: "inherit", fontSize: "inherit" }}
    >
      {icon}
      <Typography
        align="left"
        sx={{ fontWeight: "inherit", fontSize: "inherit" }}
      >
        {statusObj[props.status]}
      </Typography>
    </Stack>
  );
}
