import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { DashboardData } from "../../../services/swagger";
import CircleIcon from "@mui/icons-material/Circle";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useBreakpoint from "../../../hooks/useBreakpoint";

interface DashboardTicketResolutionProps {
  data: DashboardData;
}

export default function DashboardTicketResolution(
  props: DashboardTicketResolutionProps
) {
  const { t } = useTranslation("Dashboard");
  const { data } = props;
  const breakpoint = useBreakpoint();
  const unsolvedTickets = data.unsolvedTickets;
  const pendingTickets = data.pendingTickets;
  const solvedTickets = data.solvedTickets;
  const theme = useTheme();

  const empty = useMemo(() => {
    return solvedTickets === 0 && unsolvedTickets === 0;
  }, [unsolvedTickets, solvedTickets]);

  return (
    <Paper sx={{ bgcolor: "background.default" }}>
      <Typography
        color="primary"
        variant="h3"
        fontSize={20}
        fontWeight="bold"
        pt={4}
        pl={4}
      >
        {t("TicketsResolved")}
      </Typography>
      <Box pl={12} display="flex" justifyContent="flex-end">
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value}`,
              // arcLabelRadius: 100,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: {
                innerRadius: 10,
                additionalRadius: -10,
                color: theme.palette.divider,
              },
              data: empty
                ? []
                : [
                    { value: unsolvedTickets, label: t("UnsolvedTickets") },
                    { value: pendingTickets, label: t("PendingTickets") },
                    { value: solvedTickets, label: t("SolvedTickets") },
                  ],
            },
          ]}
          colors={[
            theme.palette.background.paper,
            theme.palette.warning.main,
            theme.palette.primary.main,
          ]}
          legend={{
            hidden: true,
          }}
          width={300}
          height={300}
        />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        mt={-4}
        pb={2}
        minHeight={40}
      >
        {!empty && (
          <>
            <Typography
              fontWeight="light"
              display="flex"
              alignItems="center"
              fontSize={breakpoint.md ? "small" : 10}
            >
              <CircleIcon
                sx={{ color: theme.palette.background.paper, mr: 1 }}
              />
              {t("UnsolvedTickets")}
            </Typography>
            <Typography
              fontWeight="light"
              display="flex"
              alignItems="center"
              fontSize={breakpoint.md ? "small" : 10}
            >
              <CircleIcon sx={{ color: theme.palette.warning.main, mr: 1 }} />
              {t("PendingTickets")}
            </Typography>
            <Typography
              fontWeight="light"
              display="flex"
              alignItems="center"
              fontSize={breakpoint.md ? "small" : 10}
            >
              <CircleIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
              {t("SolvedTickets")}
            </Typography>
          </>
        )}
      </Stack>
    </Paper>
  );
}
