import { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  Tooltip,
  ButtonBase,
  ListItemText,
  ListItemIcon,
  Paper,
  Stack,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../contexts/Global";
import { UserService } from "../../services/api";
import { UpdateUserLanguageLanguageEnum } from "../../services/swagger";

interface LanguageSwitcherProps {
  lang?: "en" | "tr";
}

export default function LanguageSwitcher(props: LanguageSwitcherProps) {
  const { t, i18n } = useTranslation("LanguageSwitcher");
  const { context, setContext } = useGlobalContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (context.language !== i18n.language) {
      i18n.changeLanguage(context.language);
    }
  }, [context]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lang: string) => {
    setContext({ ...context, language: lang });
    localStorage.setItem("language", lang);
    UserService.updateUserLanguage({
      language: lang as UpdateUserLanguageLanguageEnum,
    });
    handleMenuClose();
  };

  const languages = [
    {
      name: t("English"),
      language: "en",
      icon: (
        <ReactCountryFlag
          svg
          countryCode="US"
          style={{ height: 20, width: 20 }}
        />
      ),
    },
    {
      name: t("Turkish"),
      language: "tr",
      icon: (
        <ReactCountryFlag
          svg
          countryCode="TR"
          style={{ height: 20, width: 20 }}
        />
      ),
    },
  ];

  return (
    <>
      <Tooltip title={t("Tooltip")}>
        <Paper>
          <ButtonBase
            onClick={handleMenuOpen}
            sx={{
              height: 1,
              p: 1,
              pr: 0,
              backgroundColor: "background.default",
              borderRadius: "inherit",
            }}
          >
            <Stack direction="row" alignItems="center">
              {languages.find((l) => l.language === context.language)?.icon}

              <ArrowDropDownIcon color="disabled" />
            </Stack>
          </ButtonBase>
        </Paper>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{ sx: { mt: 1 } }}
      >
        {languages.map((l) => (
          <MenuItem
            key={l.language}
            onClick={() => handleLanguageChange(l.language)}
          >
            <ListItemIcon>{l.icon}</ListItemIcon>
            <ListItemText>{l.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
